import React from "react";
import "./index.css";
import $ from "jquery";

import "react-modal-video/scss/modal-video.scss";
import "video.js/dist/video-js.min.css";
import "./index.css";

import poster1 from "./logo/poster1.svg";
import poster2 from './logo/poster2.svg'
import subscribe from './logo/subscribe.svg'
import play from "./play.svg";

export const VideoPlayer = ({ style, playerId, coverId,linkSrc,channelSrc, playButtonId, subsButtonId}) => {
  const clickHandler = (e) => {
    e.preventDefault();
    $(`#${playerId}`)[0].src += "?autoplay=1";
    $(`#${playerId}`).show();
    $(`#${coverId}`).hide();
    $(`#${subsButtonId}`).hide();
    $(`#${playButtonId}`).hide();
  };

  return (
    <div className="embed-responsive embed-responsive-16by9 wrapper video-player">
      <img id={coverId} src={(coverId === 'cover-1')?poster1:poster2} alt="Video title" />
      <iframe
        id={playerId}
        src={linkSrc}
        frameBorder="0"
        //allow="autoplay; encrypted-media"
        allowFullScreen
        style={style}
      ></iframe>
      <a id={subsButtonId} href={channelSrc} target="_blank" rel="noopener referer" className="subscribe-button">
        <img src={subscribe} style={{ width: "100px", height: "100px"}} alt="play" />
      </a>
      <button id={playButtonId} onClick={clickHandler} className="play-btn">
        <img src={play} style={{ width: "80px", height: "80px"}} alt="play" />
      </button>
    </div>
  );
};
