import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Table, Button, Spin, Result, message } from 'antd';
// import { PlusOutlined} from '@ant-design/icons';
import { AuthService } from '../../../Shared/Auth.service';
import { MetamaskService } from '../../../Shared/Metamask.service';
import TransactionModal from '../../../Shared/TransactionModal';
import {TokenConfigurationProcess, SymbolDetailsAndSTData, RegLaunched} from '../../../Shared/interfaces';
import { SecurityTokenRegistryService } from '../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import { SharedService } from '../../../Shared/Shared.service';
// import { useHistory } from 'react-router-dom';
import { TokenConfigurationService } from '../../../TokenConfigurations/TokenConfiguration.service';
import { IssuerComplianceAdminService } from '../../IssuerComplianceAdmin.service';

// import { SecurityTokenService } from '../../../Shared/SecurityToken/SecurityToken.service';
import WhitelistFacet from '../../../Shared/SecurityToken/Facets/WhitelistFacet/index';
import MainFacet from '../../../Shared/SecurityToken/Facets/MainFacet/index';
import WrongMetamaskWalletWarning from "../../../Shared/WrongMetamaskWalletWarning";
import TxFeeDelegationModal from "../../../Shared/TxFeeDelegationModal";


const {Title} = Typography;
// const securityTokenService = new SecurityTokenService();
const whitelistFacet = new WhitelistFacet();
const mainFacet = new MainFacet();

const sharedService = new SharedService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const tokenConfigurationService = new TokenConfigurationService();
const issuerComplianceAdminService = new IssuerComplianceAdminService();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();


export default () => {
  const {userInfo} = useUserContext();
  const {selectedWallet, networkId} = useSelectedWalletContext();
  // const history = useHistory();
  const [whitelistData, setWhitelistData] = useState<any[]>();
  // const [count, setCount] = useState(0);
  const [transactions, setTransactions] = useState<{submitting?: boolean, receipt?: any, details: string}[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [issuerComplianceAdminWallet, setIssuerComplianceAdmin] = useState('');

  const [isDelegationModalVisible, setIsDelegationModalVisible] = useState(false);


  useEffect(() => {
    (async () => {
      if(!userInfo) return;

      const _tokenConfigurationProcess: TokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;

      if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);


     /* const [_issuerComplianceAdminWallet, walletsResponse] = await Promise.all([
        mainFacet.issuerComplianceAdmin(_symbolDetailsAndSTData.securityTokenData.contractAddress),
         issuerComplianceAdminService.getPendingWhitelistedWallets(_symbolDetailsAndSTData.securityTokenData.contractAddress)
       ]);*/

      const [
        _issuerComplianceAdminWallet,
        walletsResponse,
         //_regulationsLaunched,
          whiteListedWallets
      ] = await Promise.all([
        mainFacet.issuerComplianceAdmin(_symbolDetailsAndSTData.securityTokenData.contractAddress),
        issuerComplianceAdminService.getPendingWhitelistedWallets(_symbolDetailsAndSTData.securityTokenData.contractAddress),
        whitelistFacet.getWhitelist(_symbolDetailsAndSTData.securityTokenData.contractAddress),
         //mainFacet.getRegulationsLaunched(_symbolDetailsAndSTData.securityTokenData.contractAddress)
      ]);

      const walletsToRemoveLowercase = whiteListedWallets.map(obj => obj.wallet.toLowerCase());

      let _whitelistData: any[] = walletsResponse.data;
       _whitelistData = _whitelistData.filter(item => !walletsToRemoveLowercase.includes(item.wallet.toLowerCase()));

      for(let index = 0; index < _whitelistData.length; index++) {
        const whitelistedWalletData = _whitelistData[index];

        whitelistedWalletData['key'] = index;
        whitelistedWalletData['wallet'] = whitelistedWalletData.wallet;
        whitelistedWalletData['country'] = whitelistedWalletData.country ?? "USA";
        whitelistedWalletData['countryName'] = whitelistedWalletData.countryObj ? whitelistedWalletData.countryObj.name.en : "USA";
        whitelistedWalletData['isAffiliate'] = !!whitelistedWalletData.user.isAffiliate;
        whitelistedWalletData['firstName'] = whitelistedWalletData.user.firstName;
        whitelistedWalletData['email'] = whitelistedWalletData.user.email;
        whitelistedWalletData['accreditedInvestor'] = !!whitelistedWalletData.user.accreditedInvestor;
        // whitelistedWalletData['assetCreationTS'] = whitelistedWalletData.tokenConfigurationProcess.creationTS;
        // whitelistedWalletData['regulationLaunched'] = _regulationsLaunched
        //   .find(opt => opt.creationTS === whitelistedWalletData['assetCreationTS'].toString());
      }

      setIssuerComplianceAdmin(_issuerComplianceAdminWallet);
      setWhitelistData(_whitelistData);

      setLoading(false);

    })();
  }, [userInfo]);


  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Wallet Address',
      dataIndex: 'wallet',
      render: (value: string) => (
        <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${value}`}>
          {sharedService.minifyAddress(value)}
        </a>
      )
    },
    // {
    //   title: 'Affiliate',
    //   dataIndex: 'isAffiliate',
    //   render: (value: boolean) => value? 'YES' : 'No'

    // },
    {
      title: 'Country',
      dataIndex: 'countryName',
      // render: (value: boolean) => value? 'YES' : 'NO'
    },
    {
      title: 'Accredited Investor',
      dataIndex: 'accreditedInvestor',
      render: (value: boolean) => value ? 'YES' : 'NO'
    },
    // {
    //   title: 'Whitelist for',
    //   dataIndex: 'regulationLaunched',
    //   render: (value: RegLaunched) => sharedService.assetName(value)
    // }
  ];


  const openTxFeeDelegationModal = async() => {
    setIsDelegationModalVisible(true);
  }


  const addWhitelistedWalletMulti = async(prop: {delegate: boolean}) => {
    if(!whitelistData?.length) return;

    if(whitelistData.find(whitelistMember => !whitelistMember.wallet)) return message.error('Enter all the wallets');

    setIsModalVisible(true);
    setTransactions([{details: 'Adding to Whitelist', submitting: true}]);

    console.log(whitelistData);

    const _from: string[] = [];
    const _isAffiliate: boolean[] = [];
    const _isAccredetedInvestor: boolean[] = [];
    const _country: string[] = [];

    whitelistData.forEach(whitelistMember => {
      _from.push(whitelistMember.wallet);
      _isAffiliate.push(whitelistMember.isAffiliate);
      _isAccredetedInvestor.push(whitelistMember.isAccredetedInvestor);
      _country.push(whitelistMember.country);
    });


    try {

      const receipt = await whitelistFacet.addWhitelistedWalletMulti(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
        selectedWallet as string,
        _from,
          _isAffiliate,
        _country,
        _isAccredetedInvestor,
        {delegate: prop.delegate}
      );

      setTransactions(prev => {
        const current = sharedService.clone(prev);
        current[0].receipt = receipt;
       return current;
     });

      if(receipt?.status) setWhitelistData([]);


    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current = sharedService.clone(prev);
      current[0].submitting = false;
      return current;
    });


  }


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {loading &&
            <div style={{textAlign:'center'}}>
              <br/><br/>
              <Spin size='large'/>
            </div>
          }


<>
  {!loading ? (
    userInfo?.role === 'issuer compliance admin' &&
    selectedWallet?.toLowerCase() !== symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() ? (
      <Card>
        <Title level={2} style={{textAlign: 'center'}}>Wrong selected wallet on Metamask</Title>
        <Result
          status="error"
          title={
            <p>
              Select the wallet{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${sharedService.etherscanURL[networkId as string]}/address/${symbolDetailsAndSTData.symbolDetails.owner}`}
              >
                {sharedService.minifyAddress(symbolDetailsAndSTData.symbolDetails.owner.toLowerCase())}
              </a>
              {' '} in order to Proceed

            </p>
          }
        />
      </Card>
    ) : (
      <Card>
        <Title level={1} style={{textAlign: 'center'}}>Pending Whitelisted Wallets</Title>

        {!symbolDetailsAndSTData?.symbolDetails.isDeployed && (
          <Result title="Security Token not deployed" />
        )}

        {symbolDetailsAndSTData?.symbolDetails.isDeployed && selectedWallet?.toLowerCase() === issuerComplianceAdminWallet.toLowerCase() && (
          <>
            <Table
              scroll={{ x: true }}
              columns={columns}
              dataSource={whitelistData}
              pagination={false}
            />
            <div style={{ textAlign: 'right' }}>
              <Button size="large" type="primary" onClick={openTxFeeDelegationModal}>
                ADD TO WHITELIST
              </Button>
            </div>
          </>
        )}
      </Card>
    )
  ) : null}
</>

        </Col>
      </Row>

      <TxFeeDelegationModal
        isVisible={isDelegationModalVisible}
        onSubmit={({delegate}) => {
          setIsDelegationModalVisible(false);
          addWhitelistedWalletMulti({delegate}).then()
        }}
        onCancel={() => setIsDelegationModalVisible(false)}
      />

      <TransactionModal
        title = 'Pending Whitelisted Wallets'
        transactions = {transactions}
        isModalVisible = {isModalVisible}
        closeModal = {() => setIsModalVisible(false)}
      />
    </>
  );
}
