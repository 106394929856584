import React, { useState, useEffect } from 'react';
import CardHOC from "../../CardHOC";
import {AuthService} from "../../Shared/Auth.service";
import {Button, Spin, Table} from "antd";
import { useHistory } from 'react-router-dom';
import moment from "moment";
import {AmendmentsService} from "../Amendments.service";



const amendmentsService = new AmendmentsService();

const useUserContext = () => new AuthService().useUserContext();


export default function AmendmentsList() {
  const {userInfo} = useUserContext();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);
  const [amendments, setAmendments] = useState();
  const [smartDeployed, setSmartDeployed] = useState(false);

  useEffect(() => {
    (async () => {
      if(!userInfo) return;

      const response = await amendmentsService.getAmendments();

      setAmendments(response.data);

      const res = await amendmentsService.getSmart();
      if(res.data === 'true'){
        setSmartDeployed(true);
      }
      setLoading(false);

    })();
  }, [userInfo]);

  const columns = [
    {
      title: 'AMENDMENT NUMBER',
      dataIndex: 'number'
    },
    {
      title: 'AMENDMENT',
      dataIndex: 'text',
    },
    {
      title: 'COMMENTS',
      dataIndex: 'comments',
    },
    {
      title: 'CREATED ON',
      dataIndex: 'creationTS',
      render: (value: number) => moment(value).format('lll')
    },
    // {
    //   title: 'ACTION',
    //   dataIndex: 'action',
    //   render: (value, record) => <>
    //     <Button size='small' style={{marginLeft: '5px'}} type='primary'>
    //       INVITE SERIES MANAGER
    //     </Button>
    //
    //   </>
    // }
  ];



  return (
    <CardHOC
      heading={'Amendments'}
      component={<>

        {loading &&
        <div style={{textAlign:'center'}}>
          <br/><br/>
          <Spin size='large'/>
        </div>
        }

        {!loading &&
        <>
          <div style={{textAlign:'right', marginBottom: '10px'}}>
            {smartDeployed && (
              <Button type="primary" size='large' onClick={() => history.push(`/amendments/new`)}>
                NEW AMENDMENT
              </Button>
            )}
          </div>

          <Table
            columns={columns}
            dataSource={amendments}
            rowKey='_id'
            pagination={false}
          />
        </>
        }




      </>}
    />
  );
}