import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Card, Row, Col, Modal, Alert, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useHistory, useParams } from 'react-router-dom';
// import * as classNames from 'classnames';
import { SharedService } from '../../Shared/Shared.service';
import { AccountService } from '../Account.service';
import { AuthService } from '../../Shared/Auth.service';
import { environment } from '../../../environments/environment';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 11 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const accountService = new AccountService();
const sharedService = new SharedService();

const useUserContext = () => new AuthService().useUserContext();
export default () => {
    const history = useHistory();
    const { resetToken } = useParams()

    const [reqErr, setReqErr] = useState<string>();
    const [submitting, setSubmitting] = useState(false);
    const [expired, setExpired] = useState(true);
    const [registered, setRegistered] = useState(false);
    const [openRegisterModal, setOpenRegisterModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [form] = Form.useForm();

    const { userInfo, setUserInfo } = useUserContext();

    const handleSubmit = async (req) => {
        setSubmitting(true);
        const response = await accountService.resetPassword(req, resetToken);
        if (response.success) {
            message.success("Password changed successfully", 7)
            history.push('/account/login')
        } else {
            message.error(response.error.message, 5)
        }
        setSubmitting(false);
    }

    return (
        <Row justify="center" style={{ marginTop: "60px" }}>
            <Col span={23} style={{ textAlign: 'center' }}>
                <Card style={{
                    // margin: '5% 0 0 15%',
                    // width: '70%'
                }}>
                    <h2 style={{ textAlign: 'center' }}>Reset Password</h2>
                    <Form
                        // form = {form}
                        // size='middle'
                        onFinish={handleSubmit}
                        className='login-form'
                    // style={{margin: 'auto', marginTop: '3%', width: '60%',  textAlign: 'center'}}
                    >

                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                            validateStatus={reqErr && 'error'}
                            help={reqErr}
                            style={{ width: '300px', margin: 'auto', marginBottom: '10px' }}>
                            <Input.Password placeholder="Password" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} />
                        </Form.Item>

                        <Form.Item
                            name="confirmPassword"
                            rules={[{ required: true, message: 'Please confirm your password!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),]}
                            validateStatus={reqErr && 'error'}
                            help={reqErr}
                            style={{ width: '300px', margin: 'auto', marginBottom: '10px', marginTop: '20px' }}>
                            <Input.Password placeholder="Confirm Password" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                style={{ marginTop: '20px' }}
                                type='primary'
                                htmlType='submit'
                                className='login-form-button'
                                loading={submitting}
                            >
                                Reset Password
                            </Button>
                            <br />
                            <a className='login-form-forgot' href="/account/login">
                                Login
                            </a>
                            <br />
                            <br />
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    )
}