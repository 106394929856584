import React,{useState,useEffect, useRef} from 'react'
import ReactDOM from 'react'
import { SharedService } from '../../Shared/Shared.service';
import {AuthService} from '../../Shared/Auth.service'
import {IssuerComplianceAdminService} from '../IssuerComplianceAdmin.service'
import {Row,Col,Typography,Card,Descriptions,Button, message,Table,Badge,Tabs,Radio,Input} from 'antd'
import { PlaySquareOutlined, ProjectOutlined } from '@ant-design/icons';
import moment from 'moment'
const {TextArea}=Input;
const {TabPane}=Tabs
const issuerComplianceAdminService=new IssuerComplianceAdminService();
const authService=new AuthService();
const useUserContext = () => authService.useUserContext();
const sharedService=new SharedService();
const {Title}=Typography;
const displayOptions={
  AfterRejectDivision:'2',
  MainDivision:'1'
}
const subDisplayOptions={
  RejectView:'1',
  ResubmitView:'2'
}

export default ()=>{
  const {userInfo, setUserInfo} = useUserContext();
  const [displayOption,setDisplayOption]=useState(displayOptions.MainDivision);
  const [subDisplayOption,setSubDisplayOption]=useState(subDisplayOptions.ResubmitView);
  const [detailsView,setDetailsView]=useState(false);
  const resubmitRadioRef=useRef(null);
  const rejectRadioRef=useRef(null);
  const textBoxRef=useRef(null);
  const [rejectReason, setRejectReason] = useState('');
  const [selectedTokenSymbol, setSelectedTokenSymbol] = useState();
  const columns=[
    {
      title:'FIRST NAME',
      dataIndex:'firstName',
      key:'firstName'
    },
    {
      title:'LAST NAME',
      dataIndex:'lastName',
      key:'lastName'
    },
    {
      title:'EMAIL',
      dataIndex:'email',
      key:'email'
    },
    {
      title:'PHONE',
      dataIndex:'contactNumber',
      key:'contactNumber'
    },
    {
      title: 'TOKEN SYMBOL',
      dataIndex: 'selectedTokenSymbol',
      key: 'selectedTokenSymbol'
    },
    {
      title:'CREATED ON',
      dataIndex:'dateOfSubmission',
      key:'creationTS',
      render:text=>moment(text).format('LLL')
    },
    {
      title:'ACTION',
      dataIndex:'action',
      key:'action',
      render:(text,investor)=><Button type='primary' onClick={()=>{setSelectedTokenSymbol(investor.selectedTokenSymbol); showDetails(investor);}}>DETAILS</Button>
    }
  
  ]
  const columns1=[
    {
      title:'FIRST NAME',
      dataIndex:'firstName',
      key:'firstName'
    },
    {
      title:'LAST NAME',
      dataIndex:'lastName',
      key:'lastName'
    },
    {
      title:'EMAIL',
      dataIndex:'email',
      key:'email'
    },
    {
      title:'PHONE',
      dataIndex:'contactNumber',
      key:'contactNumber'
    },
    {
      title: 'TOKEN SYMBOL',
      dataIndex: 'selectedTokenSymbol',
      key: 'selectedTokenSymbol'
    },
    {
      title:'CREATED ON',
      dataIndex:'dateOfSubmission',
      key:'creationTS',
      render:text=>moment(text).format('LLL')
    },
    {
      title:'Status',
      dataIndex:'action',
      key:'action',
      render:(text,investor)=>{
        return(
          <>
          {investor.verified && <span>Approved</span>}
          {investor.rejected && <span>Rejected</span>}
          </>
        )
      }
    }
  
  ]
  const [investorDetails,setCurrentInvestor]=useState()
  const [investors,setInvestors]=useState(null)  
  const [approvedAndRejectedInvestors,setInvestorsList]=useState([])
  const [submitting,setSubmitting]=useState(false)
  
  useEffect(
    ()=>{ (async ()=>{
      const response=await issuerComplianceAdminService.getAIVerificationRequest({company:userInfo.companyName});
      if(response.success && response.data)
      {
        setInvestors(response.data);
      }
     })();
    },[detailsView]);

    useEffect(
      ()=>{ (async ()=>{
        const response=await issuerComplianceAdminService.getAIInvestorsList({company:userInfo.companyName});
        if(response.success && response.data)
        {
          setInvestorsList(response.data);
        }
       })();
      },[detailsView]);
   
  const showDetails=(investor)=>{
    setCurrentInvestor(investor)
    setDetailsView(true)
  }
  const acceptAI=async (data)=>
  {
    setSubmitting(true);
    data.tokenSymbol = selectedTokenSymbol;
    const response=await issuerComplianceAdminService.acceptAI(data);
    if(response.success && response.data)
    {
      setDetailsView(false)
    }
    else
    {
      message.error("Some error occured!");
    }
    setSubmitting(false);
  }
  const rejectAI=async (data)=>
  {
    data.tokenSymbol = selectedTokenSymbol;
    setSubmitting(true);
    const response=await issuerComplianceAdminService.rejectAI(data);
    if(response.success && response.data)
    {
      setDetailsView(false)
    }
    else
    {
      message.error("Some error occured!");
    }
    setSubmitting(false);
  }

  const changeView=(e)=>{
    if(e.target.value===1)setSubDisplayOption(subDisplayOptions.ResubmitView);
    else setSubDisplayOption(subDisplayOptions.RejectView);
  }

  const resubmitAI=async ()=>{
    investorDetails.resubmit=true;
    investorDetails.tokenSymbol = selectedTokenSymbol;
    const response=await issuerComplianceAdminService.resubmitAI(investorDetails);
    if(response.success)
    {
      setDetailsView(false);
    }
    else
    {
      message.error(response.error);
      return;
    }
  }

  const proceedToRejectWithCheck=()=>{
    console.log(investorDetails);
    if(resubmitRadioRef.current.state.checked){
      resubmitAI();  
      return;
    }
    else{
      if(rejectReason?.length < 1){
        message.error("Please provide the reason for rejection.");
        return;
      }
      investorDetails.reasonToReject=rejectReason.trim();
      rejectAI(investorDetails);
    }
  }
  return(
    <>
    <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            Accredited Investor Verification 
            </Title>
            <Tabs defaultActiveKey="request" centered>
              <TabPane tab={<span><PlaySquareOutlined/>Pending Accredited Investor Verification Requests </span>}>
            <div hidden={detailsView} >
            <Table dataSource={investors} columns={columns} />
            </div>
            <div hidden={!detailsView}>
            <Descriptions title='Investor Details' 
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            title="Investor Details"
            bordered>
        
              <Descriptions.Item label="First Name">{investorDetails?.firstName}</Descriptions.Item>
              <Descriptions.Item label="Last Name">{investorDetails?.lastName}</Descriptions.Item>
              <Descriptions.Item label="Date of Accredited Investor verification Request">{moment(investorDetails?.dateOfSubmission).format('LLL')}</Descriptions.Item>
              <Descriptions.Item label="Contact Number">{investorDetails?.contactNumber}</Descriptions.Item>
              <Descriptions.Item label="Token Symbol">{investorDetails?.selectedTokenSymbol}</Descriptions.Item>
              <Descriptions.Item label="Accredited Investor verification done on ">{investorDetails?.aiVerificationDate}</Descriptions.Item>
              <Descriptions.Item label="Accredited Investor Verification Documents">{investorDetails?.aiDocument.map((document,index)=>{
                return (
                  <div>
                    {index+1}) <a href={document.aiDocument.URL} target="_blank">{document.aiDocument.name}</a>
                    <br/>
                  </div>
                )
              })}</Descriptions.Item>
              <Descriptions.Item label="Previously Verified By">{investorDetails?.aiVerifiedCompany?.length>0?investorDetails?.aiVerifiedCompany?.map((company)=>`${company} `):'None'}</Descriptions.Item>
              <Descriptions.Item label="Status"><Badge color='yellow' status="processing" text={`Waiting For Approval`}></Badge></Descriptions.Item>
            </Descriptions>
            <br/>
            <div hidden={displayOption!==displayOptions.MainDivision} >
            <Button onClick={()=>acceptAI(investorDetails)} type='primary' loading={submitting} >Approve</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button onClick={()=>setDisplayOption(displayOptions.AfterRejectDivision)} disabled={submitting}>Reject</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button onClick={()=>setDetailsView(false)} disabled={submitting}>Back</Button>
            </div>

            <div hidden={displayOption!==displayOptions.AfterRejectDivision} id="radioDiv">
              <Radio.Group onChange={changeView}  >
                <Radio value={1} ref={resubmitRadioRef} >Ask To Resubmit Details</Radio>
                <Radio value={2} ref={rejectRadioRef}>Reject</Radio>
              </Radio.Group>
              {(subDisplayOption===subDisplayOptions.RejectView) && <><center><table><tr><td>Reason:<sup style={{color:'red'}} >*</sup></td><td><TextArea style={{width:650}} onChange={(e)=>setRejectReason(e.target.value)} value={rejectReason} rows={8}></TextArea></td></tr></table></center> </> }

              <Button type="primary" htmlType="button" onClick={proceedToRejectWithCheck} >Submit</Button>
            </div>
            
            </div>
            </TabPane>
            <TabPane tab={<span><ProjectOutlined/>Approved/Rejected Investors</span>} key="2">
              <Table columns={columns1} dataSource={approvedAndRejectedInvestors}></Table>
            </TabPane>
            </Tabs>

        </Card>
      </Col>
    </Row>
    
    </>
  )
}