import React, { useEffect, useState } from "react";
import "../InvestorRegistration/investerregistration.scss"
import { AccountService } from "../../Account.service";
import {
  Form,
  Input,
  Button,
  Card,
  Select,
  InputNumber,
  Col,
  Row,
  message,
  Alert,
  Result,
  DatePicker,
  Checkbox,
} from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SharedService } from "../../../Shared/Shared.service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import ConnectToMetamask from "../../../ConnectToMetamask";
import { MetamaskService } from "../../../Shared/Metamask.service";
import { AuthService } from "../../../Shared/Auth.service";
import queryString from "query-string";
import left_logo_img from "../../../../assets/Group 2917.png"
import subtract from "../../../../assets/Subtract.png"


const { Option } = Select;

const sharedService = new SharedService();
const accountService = new AccountService();


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default () => {
  const history = useHistory();
  const [registered, setRegistered] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [countries, setCountries] = useState();
  const [usaStates, setUSAStates] = useState();
  const [form] = Form.useForm();
  // const [userCountry, setUserCountry] = useState();
  const [cCountry, setCCountry] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);
  const [registrationMode, setRegistrationMode] = useState("NORMAL");
  const [issuerEmail, setIssuerEmail] = useState();


  useEffect(() => {
    (async () => {
      const response = await sharedService.getCountries();
      setCountries(response.data);
      const responsee = await sharedService.getUSAStates();
      setUSAStates(responsee.data);
    })();

    // (async () => {
    //   await axios
    //     .get(
    //       "https://geolocation-db.com/json/697de680-a737-11ea-9820-af05f4014d91"
    //     )
    //     .then((response) => {
    //       //console.log(response.country_name);
    //       setUserCountry(response.country_name);
    //     })
    //     .catch((response) => console.log(response));
    // })();
  }, []);

  const location = useLocation();
  let { code, email, seriesId } = queryString.parse(location.search);
  if (email?.length > 0 && email.indexOf(" ") > -1) {
    email = email.replaceAll(" ", "+");
  }

  const forwardToLogin = () => {
    history.push("/account/login");
  };

  const currentCountry = (value) => {
    if (value == "United States of America") setCCountry(true);
    else setCCountry(false);
  };

  const calculateAge = (dateOfBirth) => {
    var dob = new Date(dateOfBirth);

    var month_diff = Date.now() - dob.getTime();

    var age_dt = new Date(month_diff);

    var year = age_dt.getUTCFullYear();

    var age = Math.abs(year - 1970);

    return age;
  };

  const register = async (req) => {
    console.log("lateef ");
    // if (!req.country?.toUpperCase().startsWith(userCountry?.toUpperCase())) {
    //   setCountryError(true);
    //   return;
    // }
    setCountryError(false);
    setSubmitting(true);
    const age = calculateAge(req.dateOfBirth);
    if (age < 18) {
      message.error("Investor age should be greater than or eqauls to 18!");
      setSubmitting(false);
      return;
    }

    if (!seriesId || !email || !code) {
      registerInvestorAsPublic(req);
      return;
    }

    try {
      let res = await accountService.getSeriesCompanyDetails(seriesId);
      let seriesDetails = res.data;
      if (seriesId && seriesDetails?.companyId) {
        req.seriesId = seriesId;
        req.code = code;
        req.companyObj = { id: seriesDetails.companyId, name: seriesDetails.name };
        req.companyId = seriesDetails.companyId;
      } else {
        return message.error("Issuer company not found, please try after some time!");
      }

      const response = await accountService.registerInvestor(req);
      if (response.success) {
        message.success("Successfully registered.");
        setRegistered(true);
        setIssuerEmail(req.email);
        form.resetFields();
      } else {
        message.error(response.error.message);
      }
      setSubmitting(false);
    } catch (error) {
      console.error(error);
      message.error("Something went wrong while registering!");
      console.log("not submitted");
    }

    setSubmitting(false);
  };
  async function registerInvestorAsPublic(req) {
    setRegistrationMode("PUBLIC");

    const response = await accountService.registerInvestorAsPublic(req);
    if (response.success) {
      message.success("Successfully registered.");
      setRegistered(true);
      form.resetFields();
      setIssuerEmail(req.email);
    } else {
      message.error(response.error.message);
    }
  }


  useEffect(() => {
  });
  if (email) {
    form.setFieldsValue({ email: email });
  }
  return (
    <div className="investorregistration-parent-container">
      <div className="investorregistration-left">
                <div className="nested-left-div1">
                  <div className="nested-left-div2">
                    <div>
                      <img src={left_logo_img} alt="logo"/>
                    </div>
                    <div className="inside-nested-left-div2">
                    <h5>Discover Exciting </h5>
                    <h6>Real Estate</h6>
                    <h4>Investment Opportunities</h4>
                    </div>
                  </div>
                  <div className="nested-left-div3">
                  <h3>Experience Seamless Commercial and Residential Real Estate Investment through VeepCapital</h3>
                  <div className="nested-left-div4">
                  <img style={{paddingTop:"5px"}} src={subtract} alt="logo" />
                  <p>Unlock Opportunities.</p>
                  </div>
                  <div className="nested-left-div4">
                  <img style={{paddingTop:"5px"}} src={subtract} alt="logo" />
                  <p>Eliminate Hassles</p>
                  </div>
                  <div className="nested-left-div4">
                  <img style={{paddingTop:"5px"}} src={subtract} alt="logo" />
                  <p>Invest with Confidence</p>
                  </div>
                 
                  
                  <Link to="/account/login">
                  <h4 className="left-div-button"><span>Already An Investor? </span>Login Now</h4>
                </Link>
                </div>

                </div>
               
      </div>
      <div className="investorregistration-right">
        <Row style={{width:"1000px", marginTop:"30%" ,marginLeft:"5%"}}>
          <Col span={16} style={{ textAlign: "center" }}>
            <Card>
              <div hidden={registered}>
                {registered && (
                  <Alert
                    message="We have received your registration request. Please self verify your email address to proceed."
                    type="success"
                  ></Alert>
                )}
               
                <h3 style={{ textAlign: "start", fontSize:"40px", color:"white" }}>Investor Registration</h3>
                <Form
                  {...formItemLayout}
                  form={form}
                  onFinish={register}
                  labelAlign="left"
                >
                  <Form.Item
                    label="First name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your First Name!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Last name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Last Name!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ]}
                  >
                    {email ? (<Input disabled />) : (<Input />)}
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      {
                        validator: (rule, value) => {
                          const strongRegex =
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&])[A-Za-z\d@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&]{8,}$/;
                          if (strongRegex.test(value))
                            return Promise.resolve();
                          return Promise.reject(
                            "The password must contains at least 1 number, 1 lowercase, 1 uppercase and 1 special character and 8 characters long"
                          );
                        },
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    label="Confirm Password"
                    name="confirm"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("password") === value)
                            return Promise.resolve();
                          return Promise.reject(
                            "The two passwords that you entered do not match!"
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    label="Date of birth"
                    name="dateOfBirth"
                    className="custom-form-item custom-date-picker"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your date of birth",
                      },
                    ]}
                  >
                    <DatePicker format={'MM/DD/YYYY'} style={{ width: '100%' }} placeholder="MM/DD/YYYY" />
                  </Form.Item>



                  <Form.Item
                    label="Country"
                    name="country"
                    validateStatus={countries ? "" : "validating"}
                    //hasFeedback
                    help={countries ? "" : "Loading Countries..."}

                    rules={[
                      {
                        required: true,
                        message: "Select Country",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Country"
                      onChange={currentCountry}
                      autoComplete="new-password"
                    >
                      {countries?.map((country) => (
                        <Option key={country._id} value={country.name["en"]}>
                          {country.name["en"]}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {countryError && (
                    <Alert
                      message="The country you select and the country you are operating are different you can only register from the country you select"
                      type="error"
                    ></Alert>
                  )}
                  <Form.Item
                    label="Contact Number"
                    name="contactNumber"
                    rules={[
                      {
                        required: true,
                        message: "Enter number here",
                        whitespace: true,
                      },
                    ]}
                  >
                    <PhoneInput country={"us"} />
                  </Form.Item>
                  {cCountry && (
                    <Form.Item
                      label="State"
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "Please input state!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select state"
                        autoComplete="new-password"
                      >
                        {usaStates?.map((state) => (
                          <Option
                            key={state?.state_id}
                            value={state.state_name}
                          >
                            {state.state_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {!cCountry && (
                    <Form.Item
                      label="State"
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "Please input state!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[{ required: true, message: "City is required" }]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                  <Form.Item
                    label="Street Address 1"
                    name="streetAddress1"
                    rules={[
                      {
                        required: true,
                        message: "Street address 1 is required",
                      },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                  <Form.Item label="Street Address 2" name="streetAddress2">
                    <Input placeholder="" />
                  </Form.Item>

                  <Form.Item
                    label="Zip code"
                    name="zipCode"
                    rules={[
                      {
                        required: true,
                        message: "Please input zip code!",
                      },
                    ]}
                  >
                    <Input min={0} style={{ width: "100%" }} />
                  </Form.Item>

                  <Checkbox checked={termsCheck} onChange={() => setTermsCheck(!termsCheck)} style={{ width: '100%', marginBottom: '20px' }}>I hereby accept all <Link to="/" style={{color:"#00152A"}}>terms and conditions</Link> of the platform</Checkbox>

                  <Form.Item {...tailFormItemLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitting}
                      disabled={!termsCheck}
                    >
                      Register Now
                    </Button>
                    {/* <br />
                    <>Or </>
                    <Link to="/account/login">login now!</Link> */}
                  </Form.Item>
                </Form>
              </div>
              <div hidden={!registered}>
                <Card>
                  <h2 style={{ textAlign: 'center' }}>Check your Email</h2>
                  <Result
                    title={`We sent a verification email to ${issuerEmail}.`}
                    subTitle="Click on the link to get started!"
                    extra={
                      <Button
                        type="primary"
                        htmlType="button"
                        onClick={forwardToLogin}
                      >
                        Login
                      </Button>
                    } />
                </Card>

              </div>
            </Card>
          </Col>
        </Row>
      </div>


    </div>
  );
};
