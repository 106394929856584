import React, { useEffect, useState } from 'react';
import {InvestorService} from '../Investor.service';
import {Spin,Space,Avatar,Typography,Empty,Card, Carousel,Layout,Button,Menu,Row,Col,message} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {IssuerSuperAdminService} from '../../IssuerSuperAdmin/IssuerSuperAdmin.service';
import moment from 'moment';
const {SubMenu}=Menu;
const {Header,Footer,Sider,Content}=Layout;
const investorService=new InvestorService();
const {Title}=Typography;
const contentStyle = {
    height: '350px',
    width:'900px',
    color: '#fff',
    //lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };
const commonStyle={
    background:"white",
}
const socialMediaContentStyle={
    //left:0,
}
const leftMenu=['about','offering overview','business plan','token economics','use of proceeds','token benefits','soft cap','hard cap','financial data','platform highlights','team','press mentions','key facts','advisors','due diligence'];
const subCardSpan=40;
const colorConfiguration={
    //textAlign: 'left',
    color: '#1890ff',
    // fontSize: '30px',
    fontWeight: 'bold',
}


const issuerSuperAdminService = new IssuerSuperAdminService();


export default (props)=>{
    const {companyId} = props;
    const [currentCardData,setCurrentCardData]=useState(<Spin size="large"></Spin>);
    const [issuerWebpageData,setIssuerWebpageData]=useState(null);
    const [heading,setHeading]=useState('ABOUT');
    const [primeTrustEntity, setPrimeTrustEntity] = useState();
    const [issuerDueDiligence, setIssuerDueDiligence] = useState();
    const [tokenData,setTokenData] = useState();



    const menuEvent=(e)=>{
        setHeading(e.key.toUpperCase());
        console.log(e.key);

        if(e.key==='offering overview')
        {
          const component=`<a href=${issuerWebpageData[e.key].URL}>${issuerWebpageData[e.key].name}</a>`;
          setCurrentCardData(component);
        }else if(e.key==='soft cap')
        {
          let data='Not provided by issuer.';
            if(primeTrustEntity?.offeringDetail)
            {
              data=`$${primeTrustEntity?.offeringDetail?.minAmount}`;
            }
            setCurrentCardData(data);
        }
        else if(e.key==='hard cap')
        {
          let data='Not provided by issuer.';
            if(primeTrustEntity?.offeringDetail)
            {
              data=`$${primeTrustEntity?.offeringDetail?.maxAmount}`;
            }
            setCurrentCardData(data);
        }
        else if(issuerWebpageData[e.key])
        {

            setCurrentCardData(issuerWebpageData[e.key]);
        } else if(e.key === 'due diligence') {

          const dueDiligenceData = issuerDueDiligence?.map((val, index) => {

            return (
              `
                <h3>
                  ${val.question}
                </h3>
                <p>
                  ${val.answer}
                </p>
                <hr/>
              `
            );
          });
          setCurrentCardData(dueDiligenceData);
        }
        else
        {
            setCurrentCardData('Not provided by issuer.');
        }
    }
    
    
    useEffect(()=>{
        (async ()=>{ 
        const result=await investorService.getIssuerWebpageConfig({company:props.company.toLowerCase()});
        if(result.success && result.data)
        {
            await setIssuerWebpageData(result.data);
            setCurrentCardData(result.data?.about);
        }
        else
        {
            message.error("Some error occured while loading the page!");
            setCurrentCardData("Some error occured while loading the page!");
        }
        if(!props.companyId)return;
        const tokenResult=await investorService.getIssuerTokenInfo({companyId:props.companyId});
        if(tokenResult.success)
        {
            await setTokenData(tokenResult.data[0]);
            console.log(tokenResult.data[0])
        }
    })()
    },[]);
  

    useEffect(()=>{
      (async ()=>{ 

        const responses = await Promise.all([
        investorService.getPrimeTrustEntity({companyId}),
        investorService.getIssuerDueDiligence({companyId}),
        ]);

        setPrimeTrustEntity(responses[0].data[0]);
        setIssuerDueDiligence(responses[1].data);
        console.log({response:responses[0]});
      })();

    },[companyId]);

    return(
    <>
    <center>
    <b>*Disclaimer - This website is created solely for the demo purpose. No Real Information or Data is added to this website</b>
    </center>
    <br/>
    {!issuerWebpageData && <Spin size="large" ></Spin>}
    {issuerWebpageData && <Space direction="vertical">
        <Layout>
            <Header style={commonStyle}>
            <Space>
            {!(issuerWebpageData && issuerWebpageData['company logo']) && <Avatar shape="square" size={64} icon={<UserOutlined></UserOutlined>}></Avatar>}
            {(issuerWebpageData && issuerWebpageData['company logo']) && <Avatar shape="square" style={{height:70,width:170}} src={issuerWebpageData['company logo']?.URL}></Avatar>}
            <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            {props.company.toUpperCase()}
          </Title>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
          </Space>
          
          {/* <Sider style={commonStyle}> */}
          <Space style={socialMediaContentStyle} >
              {(issuerWebpageData && issuerWebpageData['facebook url']) && <a href={issuerWebpageData['facebook url']} target="_blank" ><Avatar  src="https://img.icons8.com/ios-glyphs/48/000000/facebook-new.png" ></Avatar></a>}
              {(issuerWebpageData && issuerWebpageData['twitter account url']) && <a href={issuerWebpageData['twitter account url']} target="_blank"><Avatar  src="https://img.icons8.com/ios-filled/48/000000/twitter.png" ></Avatar></a>}
              {(issuerWebpageData && issuerWebpageData['linkedin url']) && <a href={issuerWebpageData['linkedin url']} target="_blank"><Avatar  src="https://img.icons8.com/metro/48/000000/linkedin.png" ></Avatar></a>}
              {(issuerWebpageData && issuerWebpageData['youtube channel url'] ) && <a href={issuerWebpageData['youtube channel url']} target="_blank"><Avatar  src="https://img.icons8.com/carbon-copy/48/000000/youtube.png" ></Avatar></a>}
              <Button type="primary" htmlType="button" onClick={props.investButtonCallback} >Invest</Button>
          </Space>
          {/* </Sider> */}
          </Header>
        {/* </Layout> */}
        <Content  style={{ padding: '0 200px' }}>
        {/* <Space> */}
        {/* <div > */}
          {/* <Row> */}
            {/* <Col justify='center' > */}
            <br/>
            <Carousel>
                {issuerWebpageData?.videoURL &&<div>
                    <iframe style={contentStyle} src={issuerWebpageData?.videoURL} allow="fullscreen" ></iframe>
                </div>} 
            </Carousel>
            {/* </Col> */}
          {/* </Row> */}
        {/* </div> */}
        
        {/* </Space> */}
        </Content>
      </Layout>
        <Layout>
            <Sider width={200} className="site-layout-background" >
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['about']}
                    onClick={menuEvent}
                    //style={{ height: '100%', borderRight: 0 }}
                >
                    {leftMenu.map((item,index)=>{return(<Menu.Item key={item} >{item.toUpperCase()}</Menu.Item>);})}
                </Menu>
            </Sider>
            <Content style={{backgroundColor:'white'}} >
                <Row>
                <Col span={17}>
                <Card style={{height:810}}>
                    <Title
                    level={3}
                    style={colorConfiguration}
                    >
                    {heading}
                    </Title>
                    <div  dangerouslySetInnerHTML={{ __html:currentCardData }}  >
                    {/* {currentCardData} */}
                    </div>
                </Card>
                </Col>
                &nbsp;
                <Col>
                <Row>
                <Col span={subCardSpan}>
                <Card>
                <Title
                    level={3}
                    style={colorConfiguration}
                    >
                    {tokenData &&  tokenData.regulation.toUpperCase()}
                    </Title>
                    {tokenData===null && <Empty>Token is not deployed</Empty>}
                    { tokenData && <Space
                    direction="vertical"
                    
                    >
                     <div style={colorConfiguration} >Type Of Security</div>
                     <div><b>{tokenData.typeOfSecurity}</b></div>
                     <div style={colorConfiguration} >Target Raise</div>
                     <div><b>{primeTrustEntity?.offeringDetail?.amount}</b></div>
                     {/* <div style={colorConfiguration} >Amount Raised</div> */}
                     <div style={colorConfiguration}>Raise Start Date</div>
                     <div><b>{moment(tokenData?.tokenPhase[0]?.startDate).format('LLL')}</b></div>
                     <div style={colorConfiguration}>Raise End Date</div>
                     <div><b>{moment(tokenData?.tokenPhase[0]?.endDate).format('LLL')}</b></div>
                    </Space>}
                </Card>
                </Col>
                </Row>
                <br/>
                <br/>                
                <Row>
                    <Col span={subCardSpan}>
                        <Card>
                        <Title
                            level={3}
                            style={colorConfiguration}
                        >
                        Documents
                        </Title>

                        {primeTrustEntity?.offeringDetail && 
                          <div style={{fontWeight: 'bold', fontSize: 'larger', textDecoration: 'underline'}}>
                            {primeTrustEntity.offeringDetail.offeringFile && 
                              <a href={primeTrustEntity.offeringDetail.offeringFile?.URL} target="_blank" rel="noopener noreferrer">
                                {primeTrustEntity.offeringDetail.offeringFile?.name}
                              </a>
                            }

                            <br/>
                            
                            {primeTrustEntity.offeringDetail.subscriptionAgreementFile && 
                              <a href={primeTrustEntity.offeringDetail.subscriptionAgreementFile?.URL} target="_blank" rel="noopener noreferrer">
                                {primeTrustEntity.offeringDetail.subscriptionAgreementFile?.name}
                              </a>
                            }
                          </div>
                        }

                        </Card>
                    </Col>
                </Row>
                </Col>
                </Row>
            </Content>

        </Layout>
    </Space>}
            
</>
)}