import React from 'react'
// import { lazy } from '@loadable/component';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import IssuerRegistrationRequests from './IssuerRegistrationRequests';
import IssuerBasicInfoVerifRequests from './IssuerBasicInfoVerifRequests';
import IssuerSuperAdmins from './IssuerSuperAdmins';
import CreateInformationQuestionsForIssuer from './CreateInformationQuestionsForIssuer';
import ViewAndEditQuestion from './ViewAndEditQuestion'
import InvestorRegistrationRequests from './InvestorRegistrationRequests'
import MasterCompanyFields from "./MasterCompanyFields";
import SeriesFields from "./SeriesFields";
import IssuerPropertyVerificationRequests from './IssuerPropertyVerificationRequests/IssuerPropertyVerificationRequests';

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/issuer-reg-reqs`}>
        <IssuerRegistrationRequests/>
      </Route>
      <Route path={`${path}/investor-registration-requests`}>
        <InvestorRegistrationRequests/>
      </Route>
      <Route path={`${path}/issuer-basic-info-verif-reqs`}>
        <IssuerBasicInfoVerifRequests/>
      </Route>
      <Route path={`${path}/issuer-super-admins`}>
        <IssuerSuperAdmins/>
      </Route>
      <Route path={`${path}/create-information-questions`}>
        <CreateInformationQuestionsForIssuer/>
      </Route>
      <Route path={`${path}/view-and-edit-questions`}>
        <ViewAndEditQuestion/>
      </Route>
      <Route path={`${path}/master-company-fields`}>
        <MasterCompanyFields/>
      </Route>
      <Route path={`${path}/series-fields`}>
        <SeriesFields/>
      </Route>
      <Route path={`${path}/issuer-property-verif-reqs`}>
        <IssuerPropertyVerificationRequests/>
      </Route>
    </Switch>
  )
}