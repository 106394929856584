import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, message, Typography, Tabs,Row,Col } from "antd";
import BankInfo from "./PaymentHandlers/BankInfo";
import CardHOC from "../../CardHOC";
import { AuthService } from "../../Shared/Auth.service";
import { IssuerSuperAdminService } from "../IssuerSuperAdmin.service";
import {SecurityTokenRegistryService } from "../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";
import {TokenConfigurationService} from "../../TokenConfigurations/TokenConfiguration.service";
import EscrowCrypto from "./PaymentHandlers/EscrowCrypto/EscrowCrypto";
import MailCheque from "./PaymentHandlers/MailCheque/MailCheque";
import AchWireUsa from "./PaymentHandlers/AchUSA/AchWireUsa";
import { SymbolDetailsAndSTData, TokenConfigurationProcess } from '../../Shared/interfaces';
const {TabPane}=Tabs;

const issuerSuperAdminService = new IssuerSuperAdminService();
const authService = new AuthService();
const { Text } = Typography;
const useUserContext = () => authService.useUserContext();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const tokenConfigurationService = new TokenConfigurationService();

const ManagePaymentMethod = () => {
  const { userInfo } = useUserContext();
  const [coinbaseSelected, setCoinbaseSelected] = useState(false);
  const [primeTrustSelected, setPrimeTrustSelected] = useState(false);
  const [othersSelected, setOthersSelected] = useState(false);
  const [northCapitalSelected, setNorthCapitalSelected] = useState(false);
  const [skrillSelected, setSkrillSelected] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const coinbaseRef = useRef(null);
  const [disableApprovalBtn, setDisableApprovalBtn] = useState(true)
  const [disabledButton, setDisabledButton] = useState(true)
  const [escrowCrypto, setEscrowCrypto] = useState(false)
  const [mailCheque, setMailCheque] = useState(false)
  const [achWireUS, setAchWireUs] = useState(false);
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState();



  useEffect(() => {
    (async () => {
      const response = await issuerSuperAdminService.getSavedPaymentMethod({
        companyId: userInfo?.company?.id,
      });
      if (response.success && response.data) {
        console.log(response.data?.coinbaseSelected);
        console.log(response.data?.primeTrustSelected);
        console.log(response.data?.skrillSelected);
        setCoinbaseSelected(response.data?.coinbaseSelected);
        setPrimeTrustSelected(response.data?.primeTrustSelected);
        setNorthCapitalSelected(response.data?.northCapitalSelected);
        setOthersSelected(response.data?.othersSelected);
        setSkrillSelected(response.data?.skrillSelected);
        setEscrowCrypto(response.data?.escrowCrypto);
        setMailCheque(response.data?.mailCheque);
        setAchWireUs(response.data?.achWireUS);
        setDisableApprovalBtn(false)
      }
    })();
  }, []);

  const onSave = async () => {
    setSubmitting(true);
    if (!coinbaseSelected && !primeTrustSelected && !othersSelected && !skrillSelected && !northCapitalSelected &
        !escrowCrypto && !mailCheque & !achWireUS
    ) {
      setSubmitting(false);
      message.error("Please select atleast one payment method");
      return;
    }
    const dataToSend = {
      coinbaseSelected,
      primeTrustSelected,
      northCapitalSelected,
      othersSelected,
      skrillSelected,
      escrowCrypto,
      mailCheque,
      achWireUS,
      companyName: userInfo?.company?.name,
      companyId: userInfo?.company?.id,
    };
    const response = await issuerSuperAdminService.savePaymentMethod(
      dataToSend
    );
    if (response.success && response.data) {
      console.log(response.data?.coinbaseSelected);
      console.log(response.data?.primeTrustSelected);
      setCoinbaseSelected(response.data?.coinbaseSelected);
      setPrimeTrustSelected(response.data?.primeTrustSelected);
      setNorthCapitalSelected(response.data?.northCapitalSelected);
      setOthersSelected(response.data?.othersSelected);
      setEscrowCrypto(response.data?.escrowCrypto);
      setMailCheque(response.data?.mailCheque);
      setAchWireUs(response.data?.achWireUS);
      message.success("Succesfully saved");
    }
    setSubmitting(false);
  };

  const setButtonProperty = () =>{
    setDisabledButton(false)
  }

  const checkTokenDeployment = async () => {
    try {
      const _tokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;
  
      // Check if tokenSymbol exists before proceeding
      if (!_tokenConfigurationProcess || !_tokenConfigurationProcess?.tokenSymbol) {
        return false;
      }
  
      const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess?.tokenSymbol);
  
      if (!_symbolDetailsAndSTData || !_symbolDetailsAndSTData.symbolDetails) {
        return false
      }
  
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);
      return _symbolDetailsAndSTData.symbolDetails.isDeployed; // Check deployment status
    } catch (error) {
      console.error("Error in checkTokenDeployment:", error);
      // message.error('An error occurred while checking token deployment.');
      return false; // Return false if something goes wrong
    }
  };
  
  const handleEscrowCryptoChange = async (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const isDeployed = await checkTokenDeployment();
      if (!isDeployed) {
        message.error("Token is not deployed. Please deploy the token before selecting this option.");
        return; // Prevent the checkbox from being selected
      }
    }
    setEscrowCrypto(isChecked);
  };
  
  
  

  return (
    <>
      <div style={{ textAlign: "left" }}>
        <Text>Please select the payment methods you want to use:</Text>
        <br />
        <br />
        {/* <Checkbox
          checked={coinbaseSelected}
          onChange={(e) => setCoinbaseSelected(e.target.checked)}
          disabled={submitting}
        >
          CryptoCurrency (Provider: Coinbase)
        </Checkbox>
        <br />
        <Checkbox
          checked={primeTrustSelected}
          onChange={(e) => setPrimeTrustSelected(e.target.checked)}
          disabled={submitting}
        >
          ACH/Wire-Transfer/Mail a check/Credit/Debit (Provider: Prime Trust)
        </Checkbox>
        <br /> */}

        {/* commenting north capital for now we donot have valid credintials  */}
        {/* <Checkbox
          checked={northCapitalSelected}
          onChange={(e) => {
            setNorthCapitalSelected(e.target.checked)
            setPrimeTrustSelected(!e.target.checked)
          }}
          disabled={submitting}
        >
          ACH/Wire-Transfer/Credit/Debit (Provider: North Capital)
        </Checkbox>
        <br /> */}
        <Checkbox
          checked={achWireUS}
          onChange={(e) => {
            setAchWireUs(e.target.checked)
          }}
          disabled={submitting}
        >
          ACH/Wire transfer- Bank Account
        </Checkbox>
        <br />
        <Checkbox
          checked={escrowCrypto}
          onChange={handleEscrowCryptoChange}
          disabled={submitting}
        >
          Escrow Crypto
        </Checkbox>
        <br />
        {/* <Checkbox
          checked={mailCheque}
          onChange={(e) => setMailCheque(e.target.checked)}
          disabled={submitting}
        >
          Mail a Cheque
        </Checkbox> */}
        <br />
        {/* <br />
        <Checkbox
          checked={othersSelected}
          onChange={(e) => setOthersSelected(e.target.checked)}
          disabled={submitting}
        >
          Others (Direct wire transfer)
        </Checkbox>
        <br />
        <Checkbox
          checked={skrillSelected}
          onChange={(e) => setSkrillSelected(e.target.checked)}
          disabled={submitting}
        >
          Skrill Checkout
        </Checkbox>
        <br /> */}
      </div>
      <Tabs defaultActiveKey="bank" >
      {(othersSelected || northCapitalSelected) && (<TabPane tab={<span>Bank</span>} key="bank">
          <BankInfo savePaymentMethod={onSave} bankInfoEvent={setButtonProperty} disabled={disableApprovalBtn}/>
        </TabPane>)}
        {achWireUS && <TabPane tab={<span>ACH / Wire Bank</span>} key="achusa">
          <AchWireUsa/>
        </TabPane>}
        {escrowCrypto && symbolDetailsAndSTData && <TabPane tab={<span>Escrow</span>} key="escrow" >
          <EscrowCrypto/>
        </TabPane>}
        {/* {mailCheque && <TabPane tab={<span>Mail Cheque</span>} key="mail">
          <MailCheque/>
        </TabPane>} */}
       
      </Tabs>
      
      <Row justify="end" className="mt-3">
        <Col span={24}>
          {(othersSelected || achWireUS ||
              escrowCrypto ||
              mailCheque || 
              northCapitalSelected ) && (<Button
            type="primary"
            htmlType="button"
            onClick={onSave}
            loading={submitting}
          >
            Save
          </Button>)}
        </Col>
      </Row>
    </>
  );
};
export default () => {
  return (
    <CardHOC
      heading="Select Payment Method"
      component={<ManagePaymentMethod></ManagePaymentMethod>}
    ></CardHOC>
  );
};
