import React from 'react'
import { Form, Input, Select } from 'antd'
import mpStyle from '../../layout/marketplaceHeader/MarketplaceHeader.module.scss'
import "../../layout/marketplaceHeader/MarketPlaceHomeHeader.scss"
import searchicon from "../../assets/images/marketplace/Search@2x.png"
import Arrow from  '../../assets/images/marketplace/Expand_down@2x.png'
import { Divider } from 'antd';

const { Option } = Select

const MarketplaceHomeHeader = ({ 
  searchTerm, setSearchTerm, 
  minInvestment, setMinInvestment, 
  propertyType, setPropertyType 
}) => {
  return (
    
    <div className={`MarketPlaceHomeHeader-container ${mpStyle.uMpHeader}`}>
      {/* <div className={mpStyle.marketplaceIntro}>
        <h1>Your Gateway to Premium Properties</h1>
        <h3>Simplify Real Estate Investments!</h3>
      </div> */}
      <div className={mpStyle.filterdiv}>
        {/* Search Bar */}
        <Form.Item className={`MarketPlaceHomeHeader-filter1 ${mpStyle.filterGroup} `} >
          <div style={{ margin: "auto" }}>
            <img src={searchicon} style={{ height: "25px",marginLeft:"5px" }} alt="search-icon" />
          </div>
          <Input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Location, Name, Sponsors"
            className={mpStyle.inputborder}
          />
        </Form.Item>
        <Divider type='vertical' />

        {/* Minimum Investment */}
        <Form.Item className={`MarketPlaceHomeHeader-filter2 ${mpStyle.filterGroup}`} >
          <Select
            value={minInvestment || undefined} // Ensure it's undefined if no value is selected
            onChange={(value) => setMinInvestment(value)}
            placeholder="Minimum Investment"
            className={mpStyle.inputborder}
            suffixIcon={<img src={Arrow} alt="down-arrow" className={mpStyle.downarrow} />}
          >
            <Option value="1000">1,000</Option>
            <Option value="3000">1,000 - 3,000</Option>
            <Option value="5000">3,000 - 5,000</Option>
            <Option value="10000">5,000 - 10,000</Option>
            <Option value="100000">10,000 above</Option>
          </Select>
        </Form.Item>

        <Divider type='vertical' />

        {/* Property Type */}
        <Form.Item className={`MarketPlaceHomeHeader-filter3 ${mpStyle.filterGroup} `} 
        
        
>
          <Select
            value={propertyType || undefined} // Ensure it's undefined if no value is selected
            onChange={(value) => setPropertyType(value)}
            placeholder="Property Type"
            className={mpStyle.inputborder}
            suffixIcon={<img src={Arrow} alt="down-arrow" className={mpStyle.downarrow} />}
          >
            <Option value="Residential">Residential</Option>
            <Option value="commercial property">Commercial</Option>
          </Select>
        </Form.Item>
      </div>
      {/* <div className={mpStyle.bottom}></div> */}
    </div>
    
  )
}

export default MarketplaceHomeHeader
