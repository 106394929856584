import React, { useContext, useEffect, useState } from 'react';
import { message, Card, Row, Button, Col, Spin, notification,Pagination} from 'antd';
import { AuthService } from '../../Shared/Auth.service'
import { InvestorService } from '../Investor.service'
import { Link,useHistory } from 'react-router-dom';
import { IssuerSuperAdminService } from '../../IssuerSuperAdmin/IssuerSuperAdmin.service';
import { EnvironmentOutlined,StarFilled } from '@ant-design/icons';
// import eversign from "./Eversign";
import InvestModel from '../../Shared/InvestModel/InvestModel';
import eversign from "../Home/Eversign";
import Payment from "../Home/Payment";
import KYCUniqueURLCard from "../KYCUniqueURLCard/KYCUniqueURLCard";
import {SecurityTokenRegistryService} from "../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";
import "./Dashboard.css";

const authService = new AuthService();
const useUserContext = () => authService.useUserContext()
const issuerSuperAdminService = new IssuerSuperAdminService();
const investorService = new InvestorService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();

export default (props) => {
    const history = useHistory();
    const { userInfo, setUserInfo } = useUserContext();
    const [totalInvestor, setTotalInvestor] = useState();
    const [sharePercentage, setSharePercentage] = useState();
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [lastUpdatedDate, setLastUpdatedDate] = useState('');
    const [displayInvestModel, setDisplayInvestModel] = useState(false);
    const [investedTokens, setInvestedTokens] = useState([]);
    const [isLoadingIT, setIsLoadingIT] = useState(false);
    const transactionDataSource = [];
    const netEstimatedDataSource = [];
    const [investmentCompanyId, setInvestmentCompanyId] = useState('');
    const [selectedCompanyData, setSelectedCompanyData] =useState('');
    const [walletAddressPresent] = useState(userInfo && userInfo.walletAddress ? userInfo.walletAddress : '');
    const [partnershipAgreementSigned, setPartnershipAgreementSigned] = useState(false);
    const [openEversign, setOpenEversign] = useState(false);
    const [signURL, setSignURL] = useState(null);
    const [PartnershipAgreementSignURL, setPartnershipAgreementSignURL] = useState(null);
    const [tokenDataSource, setTokenDataSource] = useState([]);
    const [selectedTokenSymbol, setSelectedTokenSymbol] = useState();
    const [tAndCView, setTAndCView] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [paymentModel, showPaymentModel] = useState(false);
    const [KYCLink, setKYCLink] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    // const [disablePagination] = useState(props.disablePagination || false);
    // const [filteredTokenDataSource, setFilteredTokenDataSource] = useState([]);

  // Function to filter data by both search and price range

  useEffect(() => {
    console.log(props,"props");
      const fetchInvestedTokens = async () => {
        try {
          const investorId = userInfo._id;
          const result =   await investorService.getAllInvestedTokenDetailsByInvestorId(investorId);
          if (result.success && result.data) {
              const tokensData =  await Promise.all(
                  result.data.map(async tokenDetails => {
                      /*if (!tokenDetails.tokenAddress && !!tokenDetails.tokenSymbol) {
                          const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(tokenDetails.tokenSymbol);
                          tokenDetails.tokenAddress = _symbolDetailsAndSTData?.securityTokenData?.contractAddress;
                      }*/
                      return {
                          key: tokenDetails._id,
                          issuer: tokenDetails.companyDetails?.name,
                          tokenCount: tokenDetails.tokenCount,
                          totalInvestors: tokenDetails.totalInvestors,
                          paymentMode: tokenDetails.paymentMode,
                          lockPeriod: tokenDetails.lockPeriod,
                          status: tokenDetails.status,
                          pricePerToken: tokenDetails.pricePerToken,
                          typeOfSecurity: tokenDetails.typeOfSecurity,
                          tokenAddress: tokenDetails.tokenAddress ? tokenDetails.tokenAddress : '',
                          creationTS: tokenDetails.creationTS
                      };
                  })
          );
              tokensData.sort((a, b) => new Date(b.creationTS) - new Date(a.creationTS));
              setInvestedTokens(tokensData);
            }
        } catch (error) {
          console.error('Error fetching invested tokens:', error);
        }

        let dataToSend = {
          _id: userInfo._id,
          email: userInfo.email,
          role: userInfo.role,
          company:'a',
        };

        // if user has not kyc done or applied for
        if(!userInfo.status.hasOwnProperty('kyc')){
          const response = await investorService.generateLinkForKYC(dataToSend);
            if (response.success && response.data) {
              setKYCLink(response.data);
        }

    }
      };

      const getAllAvailableTokensForInvestor = async (page, size, searchValue, minPrice, maxPrice) => {
        setIsLoadingIT(true);
        try {
          const results = await investorService.getAllAvailableTokensForInvestor(
            page,
            size,
            searchValue,
            minPrice,
            maxPrice,
            userInfo?._id,
            props?.showOnlyFavorites
          );
          if (results?.data?.availableTokenConfigurationProcess?.length > 0) {
            setTotalCount(results?.data?.totalCount);
            let tempTokenDataSource = await Promise.all(
              results?.data?.availableTokenConfigurationProcess.map(async (token) => {
                /*if (!token?.tokenAddress && !!token?.tokenSymbol) {
                  const _symbolDetailsAndSTData = await securityTokenRegisteryService?.getSymbolDetailsAndSTData(token?.tokenSymbol);
                  token.tokenAddress = _symbolDetailsAndSTData?.securityTokenData?.contractAddress;
                }*/
                return {
                  key: token._id,
                  companyname: token?.companyDetails?.name || 'NA',
                  companyId: token?.companyId,
                  tokenid: token?._id,
                  propertyId: token?.propertyDetails?._id,
                  tokendate: new Date(token.creationTS).toLocaleString(),
                  status: token.status || '',
                  isTokenSaleClosed: token?.hasOwnProperty('isTokenSaleClosed') && token?.isTokenSaleClosed ? true : false,
                  pricePerToken: token?.pricePerToken,
                  bankData: token?.bankData,
                  projectedFundingDeadline: new Date(token?.propertyDetails?.projectedFundingDeadline).toLocaleString(),
                  bankData: token?.bankInfo,
                  data: token,
                  tokenAddress: token?.tokenAddress,
                  creationTS: token?.creationTS,
                  isFavorite: token?.isFavorite || false,
                };
              })
            );
            tempTokenDataSource.sort((a, b) => new Date(b?.creationTS) - new Date(a?.creationTS));
            tempTokenDataSource = tempTokenDataSource.filter((dt) => dt?.key);
            tempTokenDataSource = tempTokenDataSource.filter(
              (item) => item?.tokenAddress !== '0x0000000000000000000000000000000000000000'
            );
            await setCompanyDataBasedOnInvestorCountryType(tempTokenDataSource);
          } else {
            setTokenDataSource([]); // Handle no data case
          }
        } catch (error) {
          console.error("Error fetching available tokens:", error);
        } finally {
          setIsLoadingIT(false); // Ensure loading is stopped
        }
      };

      if (userInfo) {
        fetchInvestedTokens();
        getAllAvailableTokensForInvestor(currentPage, pageSize, props?.searchValue, props?.minPrice, props?.maxPrice);
      }

      return () => {
        setTokenDataSource([]);
      };
    }, [userInfo, currentPage, pageSize, props?.searchValue, props?.maxPrice, props?.minPrice, props?.showOnlyFavorites]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  /*
    filter data based on Investor-country-type by comparing the company's regularization.
    •	US issuer can sell tokens to US investors under Reg D and Reg A+
    •	Non- US issuer can sell tokens to NON US investors under Reg S
  */
  const setCompanyDataBasedOnInvestorCountryType = async (tempTokenDataSource)=>{
    let data;
    if(userInfo.country === 'United States of America'){
      data = tempTokenDataSource.filter(inv => ['regulation-a-plus','regulation-d','regulation-d-b'].includes(inv.data?.regulation))
    }else{
      data = tempTokenDataSource.filter(inv => inv.data?.regulation === 'regulation-s')
    }
    setTokenDataSource(data);
    return
  }


      const clickedInvestButton = async (record, data) => {
        const isValid = await validateForInvestorType(record);
        if(!isValid) return

        console.log('333333 ',record,data)
        setSelectedRecord(record)
        setSelectedTokenSymbol(record.data.tokenSymbol)
        eversignurl(record)
        setInvestmentCompanyId(record.companyId);
        setSelectedCompanyData(data);

        //if investor is of type PUBLIC, then assign assing company and redirct to kyc
        if(userInfo?.investorType?.toLocaleLowerCase() ==='public' && userInfo?.status?.kyc !== 'approved'){
          if(userInfo?.company?.id){
            window.location.href='/investor/kyc-status';
            return;
          }
          console.log('public user assingin company')
          assignCompanyToInvestor(record);
          return
        }
        //here he is public user and company not assigned then first call the company assign api and then redirect to kyc page and dont let the below agreement open for him.

        let investorsCount = await getInvestorsCount(
          record.companyname?.toLowerCase()
        );

        if (investorsCount >= record.data.totalInvestors) {
          message.info(
            "You can not invest in the token as total number of investors set by issuers is fullfield"
          );
          return;
        }
        if (userInfo?.tokens[record.data.tokenSymbol]?.tAndCSigned !== true) {
          // switchToTandCView();
          console.log('setting tc true')
          setTAndCView(true)
          // return;
        }else{
          showPaymentModel(true)
        }

        // now opening the partnership agreement agreement to sign it, once it is signed succesfully we will open the investment modal.
        // try {
        //   const response = await investorService.getPartnershipAgreementURL(userInfo);
        //   if(!response.success) {
        //     notification.open({
        //       message: 'Error',
        //       description: response.error.code,
        //       duration: 0,
        //     });
        //     setIsLoading(false);
        //     return false;
        //   }

        //   setIsLoading(false);
        //   setPartnershipAgreementSignURL(response.data);
        //   setOpenEversign(true);
        // } catch (error) {
        //   notification.open({
        //     message: 'Error',
        //     description: error,
        //     duration: 0,
        //   });
        // }
      }

      const validateForInvestorType = async(record)=>{

        switch (record.data.regulation) {
          //if user is non-accredited then stop form investing
          case 'regulation-d':
            if(userInfo?.accreditedInvestor == 'false'){
              notification.info({message:'Not Allowed',description:'Ony accredited investors are allowed to invest in this property'})
              return false;
            }
            return true;
          case 'regulation-d-b':
            //if investor is non-accredited then check if they are > 35 and stop from investing
            if(userInfo?.accreditedInvestor === 'false'){
              const investedCount = await getTotalNumberOfNonAccreditedInvestedInvestors(record.companyId);
              if(!isNaN(investedCount) && investedCount > 35){
                notification.info({message:'Not Allowed',description:'This property allows only 35 non-accredited investors to invest'})
                return false;
              }else if (investedCount === -1){
                notification.error({message:'Error',description:'Something went wrong!'})
                return false;
              }
              return true;
            }
            return true;

          default:
            return true
        }
      }

      const getTotalNumberOfNonAccreditedInvestedInvestors = async(companyId)=>{
        try {
          const response = await investorService.getTotalInvestedInvestors(companyId);
          if(response.success){
            return response.data?.nonAccreditedCount;
          }
          return -1
        } catch (error) {
          console.error(error)
          return -1
        }
      }

      const getInvestorsCount = async (company) => {
        try {
          const response = await investorService.getInvestorsCount({ company });
          if (response.success && response.data) {
            return response.data.length;
          }
        } catch (e) {
          console.log(e);
          message.error("can't fetch details of token");
          return -1;
        }
        return -1;
      };

    async function assignCompanyToInvestor(record) {
      try {
        const response = await investorService.updatePublicInvestorWithCompanyDetails(record.companyId,{investorId:userInfo?._id});
          if(!response.success) {
            notification.open({
              message: 'Error',
              description: response.error.code,
              duration: 0,
            });
            return;
          }
          notification.open({
            message: 'Success',
            description: 'Success',
            duration: 0,
          });

          setTimeout(() => {
            window.location.href='/investor/kyc-status';
          }, 500);
      } catch (error) {
        notification.open({
          message: 'Error',
          description: error,
          duration: 0,
        });
      }
    }


    useEffect(() => {
        (async () => {
            const result = await new InvestorService().getPayments({ investorId: userInfo._id });
            if (result.success && result.data) {
                setPaymentDetails(result.data.filter(t => t.accept === true));
            }else{
            }
        })();

    }, [userInfo._id]);

    const cancelInvestModel = ()=>{
        setDisplayInvestModel(false);
      }

    const onSignPartnershipAgreement = async () => {
      setPartnershipAgreementSigned(true);
      const response = await investorService.setPartnershipAgreementSigned({
        investorId: userInfo?._id,
        investmentCompanyId: investmentCompanyId,
        signedDocumentUrl: signURL,
      });
      if (response.success && response.data) {
        setPartnershipAgreementSigned(true);
        setDisplayInvestModel(true);
        setOpenEversign(false);
      }
    };

    const eversignurl = async (record) => {
      console.log('everysing',record)
      if(record.data.tokenSymbol){
        if (userInfo?.tokens && userInfo?.tokens[record.data.tokenSymbol]?.tAndCSigned === true){
          setOpenEversign(true);
          return;
        }

        if(record.data){
          const data = Object.assign({tokenCompanyId: record.companyId},userInfo);
          const response = await investorService.getSignURL(data);
          await setSignURL(response.data);
          setOpenEversign(true);
        }
      }
    }

    const onSignTC = async() => {
      setTAndCView(false);
      const response = await investorService.acceptTandC({
        email: userInfo.email,
        tokenSymbol: selectedTokenSymbol
      });
      if (response.success && response.data) {
        // window.location.reload();
        // setOpenEversign(false)
        showPaymentModel(true)
      }
    };

    const handleFavoriteSelection = async (record) => {
      try {
        const newFavoriteStatus = !record.isFavorite; // Toggle the current status
        const favoriteData = {
          userId: userInfo._id,
          companyId: record.companyId,
          isFavorite: newFavoriteStatus
        };

        const response = await investorService.saveFavouritePropertiesByCompanyId(record.companyId,favoriteData);

        if (response.success) {
          // Update the local state to reflect the change
          setTokenDataSource(prevState =>
            prevState.map(item =>
              item.key === record.key
                ? { ...item, isFavorite: newFavoriteStatus }
                : item
            )
          );
          if (props.onFavoriteChange) {
            props.onFavoriteChange(record.companyId, newFavoriteStatus);
          }

          if (newFavoriteStatus) {
            message.success("Property added to favorites");
          } else {
            message.success("Property removed from favorites");
          }
        } else {
          message.error("Failed to update favorite status");
        }
      } catch (error) {
        console.error("Error updating favorite:", error);
        message.error("An error occurred while updating favorites");
      }
    };



    return (
        <>
        <Row justify="center">
          <Col span={24}>
          {isLoadingIT &&
              <div style={{ textAlign: 'center' }}>
                <br />
                <Spin size='large' />
              </div>
            }
          {!isLoadingIT && (<>

            {
              (userInfo?.status?.kyc !== 'approved')  && (
                <div>
                  {/* You can add any additional content here for users with approved KYC */}
                  {(KYCLink || userInfo?.status?.kyc !== 'approved') && <KYCUniqueURLCard uniqueURL={KYCLink} />}
                </div>
              )
            }


            {!openEversign && displayInvestModel && <InvestModel displayInvestModel={displayInvestModel} handleCancel={cancelInvestModel} companyData={selectedCompanyData} investmentCompanyId={investmentCompanyId}></InvestModel>}
            {paymentModel && (
                  <Payment
                    user={userInfo}
                    onBack={()=>{setOpenEversign(false);showPaymentModel(false)}}
                    currentToken={{issuerInfo:[selectedRecord]}}
                    selectedTokenSymbol={selectedRecord.data.tokenSymbol}
                    issuerCompanyId={selectedRecord.companyId}
                  />
            )}
            {!openEversign
            && userInfo?.status?.kyc === 'approved'
            && (
             <>
      <Card>
        {tokenDataSource.length > 0 ? ( // Check if tokenDataSource has data
          <>
            {tokenDataSource.map((record) => (
              <table
                dataSource={tokenDataSource}
                className="card investor_dashboard_card"
                pagination={false}
              >
                <tr key={record?.key} className="flex-details">
                  <div style={{ margin: "16px" }}>
                    <td className="property_image">
                      <Link to={`/property-details?propertyId=${record?.propertyId}`}>
                        <img
                          src={
                            record?.data?.propertyDetails
                              ?.propertyImages?.[0]?.URL || "https://images.unsplash.com/photo-1451976426598-a7593bd6d0b2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="Property Images"
                        />
                      </Link>
                    </td>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div className="property">
                      <td className="property-details">
                        <h3 className="property__name">
                          {record?.data?.propertyDetails?.propertyTitle?.toUpperCase() ||
                            "Property Name"}
                        </h3>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            width: "80%",
                          }}
                        >
                          <EnvironmentOutlined
                            style={{ color: "#175673" }}
                          />
                          <p className="location">
                            {`${record?.data?.propertyDetails?.propertyCity},${record?.data?.propertyDetails?.state}`}
                          </p>
                        </div>
                      </td>
                      <div style={{ marginRight: "70px" }}>
                        <button
                          style={{
                            borderRadius: "0 0 35px 35px",
                            background: record.isFavorite ? "#ffa940" : "#d9d9d9",
                            marginTop: "-24px",
                            position: "absolute",
                            padding: "11px 15px",
                            transition: "background-color 0.2s ease",
                          }}
                          onClick={() => handleFavoriteSelection(record)}
                        >
                          <StarFilled />
                        </button>
                      </div>
                    </div>

                    <div className="property">
                      <td>
                        <p className="table_heading">
                          Minimum Investment
                        </p>
                        <p>
                          ${record?.data?.propertyDetails?.minimumInvestment}
                        </p>
                      </td>
                      <td className="price-token">
                        <p className="table_heading">
                          Price per token
                        </p>
                        <p>${record.pricePerToken}</p>
                      </td>
                      <td className="expected-return">
                        <p className="table_heading">
                          Preferred Return
                        </p>
                        <p>
                          {record?.data?.propertyDetails?.preferredReturn}%
                        </p>
                      </td>
                      <td className="funding-deadline">
                        <p className="table_heading">
                          Funding Deadline
                        </p>
                        <p>
                          {record.projectedFundingDeadline.slice(
                            0,
                            record.projectedFundingDeadline.indexOf(",")
                          )}
                        </p>
                      </td>
                      <td className="status">
                        <p className="table_heading" style={{ textAlign: "center" }}>
                          Status
                        </p>
                        <button
                          className="status-btn"
                          style={{
                            padding: "0 22px",
                            color:
                              record.status === "verified" ? "#52c41a" : "#ff4d4f",
                          }}
                          disabled
                        >
                          {record.status === "verified" ? "Verified" : "Unverified"}
                        </button>
                      </td>
                      <td className="invest-button">
                        <button
                          className="invest-now-btn"
                          onClick={() => clickedInvestButton(record, record.data)}
                        >
                          Invest Now
                        </button>
                      </td>
                    </div>
                  </div>
                </tr>
              </table>
            ))}

            <div style={{ display: "flex", justifyContent: "end" }}>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalCount}
                onChange={handlePageChange}
                className="custom-pagination-container"
                showSizeChanger
                pageSizeOptions={[10, 20, 30, 50]}
              />
            </div>
          </>
        ) : (
          // Show No data found when tokenDataSource is empty
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <h3>No Data Found</h3>
          </div>
        )}
      </Card>
    </>
  )
}


            {/* Partnership Agreement View Starts Here */}
            <div className="col-12 d-flex justify-content-center">
            <div hidden={partnershipAgreementSigned } id="embedSignDivisionpa" >
              {/* <h1>Partnership Agreement</h1> */}
              {(openEversign && !partnershipAgreementSigned && PartnershipAgreementSignURL ) &&
                eversign.open({
                  url: PartnershipAgreementSignURL,
                  containerID: "embedSignDivisionpa",
                  width: 750,
                  height: 800,
                  events: {
                    loaded: () => console.log("loading successful"),
                    signed: onSignPartnershipAgreement,
                    error: () => console.log("EVERSIGN ERROR"),
                    declined: () => console.log("declined"),
                  },
                })}
            </div>
            </div>
            {/* Partnership Agreement View ends here */}

            {/* Terms and Conditions View Starts Here */}
            <div className='col-12 d-flex justify-content-center'>
            <div hidden={!tAndCView} id="embedSignDivisiontc" >
              {/* <h1>Terms and Conditions</h1> */}

                  {(openEversign && tAndCView) &&
                    eversign.open({
                      url: signURL,
                      containerID: "embedSignDivisiontc",
                      width: 750,
                      height: 800,
                      events: {
                        loaded: () => console.log("loading successful"),
                        signed: onSignTC,
                        error: () => console.log("EVERSIGN ERROR"),
                        declined: () => console.log("declined"),
                      },
                    })}
              </div>
              </div>
              {/* Terms and Conditions View ends here */}

          </>)}

          </Col>
        </Row>
        </>
    )
}
