import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Avatar,
  Typography,
  Modal,
  Button,
  Tooltip,
  notification,
  Table,
  Upload,
  Form,
} from "antd";
import {
  WalletOutlined,
  MailOutlined,
  CopyOutlined,
  UploadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import investorBKImage from "../../../assets/images/investors/Investorbk.png";
import "./MyAccountInfo.css";
import { AuthService } from "../../Shared/Auth.service";
import { InvestorService } from "../Investor.service";
import { SecurityTokenRegistryService } from "../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";
import { SharedService } from "../../Shared/Shared.service";
import { environment } from "../../../environments/environment";
import AvatarPng from "../../../assets/images/investors/vecteezy_waitress-3d-profession-avatars-illustrations_28203021.png";
import Dashboard from "../Dashboard/Dashboard";
import guidelineDoc from "../../../assets/Documents/Metamaskguidelines.docx";

const { Title, Text } = Typography;

const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const investorService = new InvestorService();
const sharedService = new SharedService();

export default () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { userInfo, setUserInfo } = useUserContext();
  const [investedTokens, setInvestedTokens] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tokenDataSource, setTokenDataSource] = useState([]);
  const [KYCLink, setKYCLink] = useState(null);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [approvedPropertiesCount, setApprovedPropertiesCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [keyFiles, setKeyFiles] = useState("");
  const [form, setForm] = Form.useForm();
  const [favoritePropertiesKey, setFavoritePropertiesKey] = useState(0);
  const [avatarUrl, setAvatarUrl] = useState(AvatarPng);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [value, setValue] = useState(false);

  useEffect(() => {
    const fetchInvestedTokens = async () => {
      try {
        const investorId = userInfo._id;
        const result =
          await investorService.getAllInvestedTokenDetailsByInvestorId(
            investorId
          );
        if (result.success && result.data) {
          let tokensData = await Promise.all(
            result.data.map(async (tokenDetails) => {
             /* if (!tokenDetails.tokenAddress && !!tokenDetails.tokenSymbol) {
                const _symbolDetailsAndSTData =
                  await securityTokenRegisteryService.getSymbolDetailsAndSTData(
                    tokenDetails.tokenSymbol
                  );
                tokenDetails.tokenAddress =
                  _symbolDetailsAndSTData?.securityTokenData?.contractAddress;
              }*/
              return {
                key: tokenDetails._id,
                issuer: tokenDetails.companyDetails?.name,
                tokenCount: tokenDetails.tokenCount,
                totalInvestors: tokenDetails.totalInvestors,
                description: tokenDetails.description,
                lockPeriod: tokenDetails.lockPeriod,
                tokenSymbol: tokenDetails.tokenSymbol,
                status: tokenDetails.status,
                pricePerToken: tokenDetails.pricePerToken,
                typeOfSecurity: tokenDetails.typeOfSecurity,
                tokenAddress: tokenDetails.tokenAddress
                  ? tokenDetails.tokenAddress
                  : "",
                creationTS: tokenDetails.creationTS,
                amountToInvest: tokenDetails.amountToInvest || 0,
              };
            })
          );
          tokensData.sort(
            (a, b) => new Date(b.creationTS) - new Date(a.creationTS)
          );
          tokensData = tokensData.filter(
              (item) => item?.tokenAddress !== '0x0000000000000000000000000000000000000000'
          )
          const totalInvestment = tokensData
            .filter((token) => token.status === "approved")
            .reduce((sum, token) => {
              const amountAsNumber = Number(
                token.amountToInvest.replace(/,/g, "")
              );
              return sum + amountAsNumber;
            }, 0);

          console.log(totalInvestment, "totalInvest");
          const approvedPropertiesCount = tokensData.filter(
            (token) => token.status === "approved"
          ).length;
          setInvestedTokens(tokensData);
          setTotalInvestment(totalInvestment); // Add this state to display total investment
          setApprovedPropertiesCount(approvedPropertiesCount);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching invested tokens:", error);
      }

      let dataToSend = {
        _id: userInfo._id,
        email: userInfo.email,
        role: userInfo.role,
        company: "a",
      };

      // if user has not kyc done or applied for
      if (!userInfo.status.hasOwnProperty("kyc")) {
        const response = await investorService.generateLinkForKYC(dataToSend);
        if (response.success && response.data) {
          setKYCLink(response.data);
        }
      }
    };

    // Fetch invested tokens when userInfo changes
    if (userInfo) {
      fetchInvestedTokens();
    }

    return () => {
      setTokenDataSource([]);
    };
  }, []);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFavoriteChange = (companyId, isFavorite) => {
    // Update the favoritePropertiesKey to force a re-render of the Dashboard component
    setFavoritePropertiesKey((prevKey) => prevKey + 1);
  };

  //Avatar Change handeler
  const handleAvatarUpload = async (info) => {
    const { file } = info;

    if (file.status === "uploading") {
      setUploadLoading(true); // Show loader when uploading starts
      return;
    }

    if (file.status === "done") {
      try {
        // Upload the file to IPFS
        const { hash } = await sharedService.uploadDocumentToIPFS({
          file: file.originFileObj,
        });

        // Once upload is successful, update avatar URL and hide the loader
        const newAvatarUrl = `${environment.ipfsDomain}/ipfs/${hash}`;
        setAvatarUrl(newAvatarUrl);
        const data = {
          profileImageURL: newAvatarUrl,
          _id: userInfo._id,
        };

        if (newAvatarUrl) {
          const response = await investorService.UploadUserImageURL(data);
          if (response.success && response.data) {
            userInfo.userProfile = newAvatarUrl;
            notification.success({
              message: "Avatar updated successfully",
            });
          }
        }
      } catch (error) {
        // If upload fails, display error notification
        console.error("Error uploading avatar:", error);
        notification.error({
          message: "Failed to update avatar",
        });
      } finally {
        // Hide loader once upload is done (either success or failure)
        setUploadLoading(false);
      }
    }
  };

  const columns = [
    {
      title: "Type of Security",
      dataIndex: "typeOfSecurity",
      key: "typeOfSecurity",
    },
    {
      title: "Issuer Company",
      dataIndex: "issuer",
      key: "issuer",
    },
    {
      title: "Token Address",
      dataIndex: "tokenAddress",
      key: "tokenAddress",
      render: (text) => text || "N/A",
    },
    {
      title: "Token Count",
      dataIndex: "tokenCount",
      key: "tokenCount",
    },
    {
        title: "Token Symbol",
        dataIndex: "tokenSymbol",
        key: "tokenSymbol",
      },
    // {
    //   title: "Total Investors",
    //   dataIndex: "totalInvestors",
    //   key: "totalInvestors",
    // },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    // },
    {
      title: "Lock Period",
      dataIndex: "lockPeriod",
      key: "lockPeriod",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <button
          style={{
            background: "transparent",
            border:
              status === "approved" ? "green 1px solid" : "blue 1px solid",
            color: status === "approved" ? "green" : "blue",
            borderRadius: "44px",
            height: "1px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {status}
        </button>
      ),
    },
    {
      title: "Price per Token",
      dataIndex: "pricePerToken",
      key: "pricePerToken",
      render: (price) => `$${price}`,
    },
  ];

  return (
    <>
      <div style={{ padding: "20px" }}>
        {value ? null : (
          <>
            <Title level={2} style={{ fontWeight: 800 }}>
              My Account Info
            </Title>
            <Card
              style={{
                borderRadius: "10px",
                padding: "20px",
                position: "relative",
                overflow: "hidden",
              }}
            >
              {/* Background Image */}
              <div
                style={{
                  position: "absolute",
                  inset: 0,
                  backgroundImage: `url(${investorBKImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  filter: "brightness(40%)",
                }}
              ></div>

              {/* Content Overlay */}
              <div style={{ position: "relative" }}>
                {/* User Info Section */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div>
                    <Avatar
                      size={100}
                      src={userInfo.userProfile ? userInfo.userProfile : avatarUrl}
                      alt="Profile Picture"
                    />
                    <div className="avatar_upload">
                      <Upload
                        accept="image/*"
                        showUploadList={false}
                        customRequest={({ file, onSuccess }) => {
                          setTimeout(() => {
                            onSuccess("ok");
                          }, 0);
                        }}
                        onChange={handleAvatarUpload}
                      >
                        <Button className="avatar_upload_btn">
                          {uploadLoading ? (
                            <LoadingOutlined />
                          ) : (
                            <UploadOutlined />
                          )}
                          {uploadLoading ? "Uploading" : "Upload"}
                        </Button>
                      </Upload>
                    </div>
                  </div>
                  <div
                    style={{
                      marginLeft: "138px",
                      position: "absolute",
                      marginBottom: "35px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "baseline",
                      }}
                    >
                      <Title level={4} style={{ color: "#fff" }}>
                        {userInfo.firstName} {userInfo.lastName}
                      </Title>
                    </div>

                    <Text style={{ color: "#fff" }}>
                      <MailOutlined /> {userInfo.email}
                    </Text>
                    <br />
                    <Text style={{ color: "#fff" }}>
                      <WalletOutlined /> {userInfo.wallet ? userInfo.wallet : <a href={guidelineDoc} target="_blank" download>No Wallet</a> }
                    </Text>
                  </div>
                </div>

                {/* Investment Info Section */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "20px",
                    color: "#fff",
                    marginLeft: "48px",
                  }}
                >
                  <div className="investment_data">
                    <h3
                      style={{
                        margin: 0,
                        fontSize: "30px",
                        color: "#1d7cdc",
                        fontWeight: 800,
                        textAlign: "center",
                      }}
                    >
                      ${totalInvestment.toLocaleString()}
                    </h3>
                    <p style={{ margin: 0, textAlign: "center" }}>
                      Total Investment
                    </p>
                  </div>
                  <div className="investment_data">
                    <h3
                      style={{
                        margin: 0,
                        fontSize: "30px",
                        color: "#1d7cdc",
                        fontWeight: 800,
                        textAlign: "center",
                      }}
                    >
                      {approvedPropertiesCount}
                    </h3>
                    <p style={{ margin: 0, textAlign: "center" }}>
                      No. of Property Invested Token
                    </p>
                  </div>
                </div>
              </div>
            </Card>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 style={{ color: "#000", marginTop: "30px" }}>
                Invested Token
              </h3>
              <button
                className="investedToken_list_buttonn"
                onClick={showModal}
              >
                View All
              </button>
            </div>
            <Card>
              <div className="security-table">
                <table>
                  <thead>
                    <tr>
                      <th style={{width:"17%"}}>Type of Security</th>
                      <th>Issuer Company</th>
                      <th>Token</th>
                      <th>Token Count</th>
                      <th>Token Symbol</th>
                      <th>Lock Period</th>
                      <th>Status</th>
                      <th>Price per Token</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      // Show loader while loading
                      <tr>
                        <td colSpan="8">
                          <LoadingOutlined
                            style={{
                              fontSize: "48px",
                              color: "#1890ff",
                              fontWeight: "bold",
                            }}
                            spin
                          />
                        </td>
                      </tr>
                    ) : investedTokens.length > 0 ? (
                      // Show table rows when data is available
                      investedTokens.map((token, index) => (
                        <tr key={index}>
                          <td style={{width:"20%"}}>{token.typeOfSecurity}</td>
                          <td>{token.issuer}</td>
                          <td>
                            {token.tokenAddress ? (
                              <div className="d-flex align-items-center gap-2">
                                <Tooltip title={token.tokenAddress}>
                                  {/* Truncate the token address to show first 10 characters */}
                                  <span>
                                    {token.tokenAddress.length > 10
                                      ? `${token.tokenAddress.slice(0, 10)}...`
                                      : token.tokenAddress}
                                  </span>
                                </Tooltip>
                                <Button
                                  className="tokenAddressCopyIcon"
                                  icon={<CopyOutlined />}
                                  size="middle"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      token.tokenAddress
                                    );
                                    notification.success({
                                      message: "Token address copied!",
                                    });
                                  }}
                                />
                              </div>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>{token.tokenCount}</td>
                          <td>{token.tokenSymbol}</td>
                          {/* <td>{token.totalInvestors}</td> */}
                          <td>{token.lockPeriod}</td>
                          <td>
                            <button
                              style={{
                                background: "transparent",
                                border:
                                  token.status === "approved"
                                    ? "green 1px solid"
                                    : "blue 1px solid",
                                color:
                                  token.status === "approved"
                                    ? "green"
                                    : "blue",
                                borderRadius: "44px",
                                height: "1px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {token.status}
                            </button>
                          </td>
                          <td>${token.pricePerToken}</td>
                        </tr>
                      ))
                    ) : (
                      // Show message if no data is available
                      <tr>
                        <td
                          colSpan="8"
                          style={{ textAlign: "center", padding: "20px" }}
                        >
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Card>

            <Modal
              // style={{ width: "110%" }}
              title={<Title level={4}>All Invested Tokens</Title>}
              visible={isModalVisible}
              onCancel={handleCancel}
              footer={[
                <Button key="close" onClick={handleCancel}>
                  Close
                </Button>,
              ]}
              width="90%"
            >
              <Table
                columns={columns}
                // style={{width:"110%"}}
                dataSource={investedTokens}
                pagination={false}
                rowKey={(record) => record.key} // Ensure each row has a unique key
                scroll={{ x: true }} // Allow horizontal scrolling if necessary
              />
            </Modal>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 style={{ color: "#000", marginTop: "30px" }}>
                Favourite Properties
              </h3>
              {/* <button className="investedToken_list_buttonn">View All</button> */}
            </div>
          </>
        )}
      </div>
      <Dashboard
        showOnlyFavorites={true}
        setValue={setValue}
        key={favoritePropertiesKey}
        onFavoriteChange={handleFavoriteChange}
      />
    </>
  );
};
