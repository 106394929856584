import React, { useEffect, useState } from 'react'
import { Table, Card, Col, Row, Typography, Descriptions, Button, Tabs } from 'antd'
import { PlaySquareOutlined, ProjectOutlined } from '@ant-design/icons';
import moment from 'moment'
import { AuthService } from '../Shared/Auth.service'
import { AuthorizationService } from './AuthorizationRequest.service'
const { Title } = Typography
const { TabPane } = Tabs;
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const authorizationService = new AuthorizationService();
export default () => {
  const { userInfo } = useUserContext();
  const [authorizers, setAuthorizers] = useState()
  const [detailsDivision, setDetailsDivision] = useState(true)
  const [authorizerDetails, setAuthorizerDetails] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [approvedAndRejectedAuthorizers, setAuthorizersList] = useState([])

  const columns = [
    {
      title: 'FIRST NAME',
      dataIndex: 'firstName',
      key: 'firstName'
    },
    {
      title: 'LAST NAME',
      dataIndex: 'lastName',
      key: 'lastName'
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'CREATED ON',
      dataIndex: 'creationTS',
      key: 'creationTS',
      render: text => moment(text).format('LLL')
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      key: 'action',
      render: (text, authorizer) => <Button type='primary' onClick={() => showDetails(authorizer)}>DETAILS</Button>
    }
  ]

  const columns1 = [
    {
      title: 'FIRST NAME',
      dataIndex: 'firstName',
      key: 'firstName'
    },
    {
      title: 'LAST NAME',
      dataIndex: 'lastName',
      key: 'lastName'
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'CREATED ON',
      dataIndex: 'creationTS',
      key: 'creationTS',
      render: text => moment(text).format('LLL')
    },
    {
      title: 'STATUS',
      dataIndex: 'action',
      key: 'action',
      render: (text, authorizer) => {
        let status = ( userInfo?.role === "issuer company officer" && authorizer.isRejectedByIssuerCompanyOfficer ) ?  "Rejected" : "Approved"; 
        status = ( userInfo?.role === "issuer counsel" && authorizer.isRejectedByIssuerCounsel ) ?  "Rejected" : "Approved"; 
        status = ( userInfo?.corporateRoles?.includes("director") && authorizer.isRejectedByDirector ) ?  "Rejected" : "Approved"; 
        return (
          <div>
              { status }
          </div>
        )
      }
    }
  ]

  const showDetails = authorizer => {
    setDetailsDivision(false)
    setAuthorizerDetails(authorizer)
  }

  const approve = (details) => {
    (async () => {
      let roles = userInfo?.roles;
      if(userInfo?.corporateRoles && userInfo?.corporateRoles?.includes('director'))
      {
        roles.push('director');
      }
      console.log(roles);
      setSubmitting(true)
      authorizationService.reviewAuthorization({ roles: roles, company: userInfo?.company?.id, _id: details?._id, result: true });
      setDetailsDivision(true)
      setSubmitting(false)
    })();
  }

  const reject = (details) => {
    (async () => {
      let role = '';
      if(userInfo?.role === 'issuer company officer') {
        role = "issuer company officer"
      }
      else if(userInfo?.role === 'issuer counsel') {
        role ="issuer counsel"
      }
      else {
        role = 'director'
      }
      setSubmitting(true)
      authorizationService.reviewAuthorization({ role: role, company: userInfo?.company?.id, _id: details?._id, result: false });
      setDetailsDivision(true)
      setSubmitting(false)
    })();
  }

  useEffect(
    () => {
      (async () => {
        let role='';
        if(userInfo?.role === 'issuer company officer') {
          role = "issuer company officer"
        }
        else if(userInfo?.role === 'issuer counsel') {
          role ="issuer counsel"
        }
        else {
          role = 'director'
        }  
        const response = await authorizationService.getInReviewAuthorizers({ role: role, company: userInfo?.company?.id });
        setAuthorizers(response.data)
      }
      )();
    }, [detailsDivision, userInfo.company.id, userInfo.role]
  )

  useEffect(
    () => {
      (async () => {
        let role = '';
        if(userInfo?.role === 'issuer company officer') {
          role = "issuer company officer"
        }
        else if(userInfo?.role === 'issuer counsel') {
          role ="issuer counsel"
        }
        else {
          role = 'director'
        }  
        const response = await authorizationService.getReviewedAuthorizers({ role: role, company: userInfo?.company?.id });
        setAuthorizersList(response.data)
      }
      )();
    }, [userInfo.company.id, userInfo.role]
  )

  return (
    <>
      <br />
      <Row justify="center">
        <Col span={22} style={{ textAlign: 'center' }}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: 'left',
                color: '#1890ff',
                fontWeight: 'bold'
              }}
            >
              Authorization Request
          </Title>
            <Tabs defaultActiveKey="request" centered>
              <TabPane tab={<span><PlaySquareOutlined />Pending Authorization Requests</span>} key="request">
                <div hidden={!detailsDivision}>
                  <Table dataSource={authorizers} columns={columns} />
                </div>
                <div hidden={detailsDivision}>
                  <Descriptions title='Authorizer Details' bordered>
                    <Descriptions.Item label="First Name">{authorizerDetails?.firstName}</Descriptions.Item>
                    <Descriptions.Item label="Last Name">{authorizerDetails?.lastName}</Descriptions.Item>
                    <Descriptions.Item label="Email">{authorizerDetails?.email}</Descriptions.Item>
                    <Descriptions.Item label="Wallet Address">{authorizerDetails?.wallet}</Descriptions.Item>
                  </Descriptions>
                  <br />
                  <Button onClick={() => approve(authorizerDetails)} type='primary' loading={submitting} >Approve</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;

            <Button onClick={() => reject(authorizerDetails)} disabled={submitting}>Reject</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;

            <Button onClick={() => setDetailsDivision(true)} disabled={submitting}>Back</Button>

                </div>
              </TabPane>
              <TabPane tab={<span><ProjectOutlined /> Approved/Rejected Request</span>}>
                <Table columns={columns1} dataSource={approvedAndRejectedAuthorizers} ></Table>
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </>
  )
}