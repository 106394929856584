import { Button, Card, Col, Form, Input, Modal, Row,  message, notification } from 'antd';
import React, {  useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { AuthService } from '../Auth.service';
import { InvestorService } from '../../Investor/Investor.service';
import { IssuerSuperAdminService } from '../../IssuerSuperAdmin/IssuerSuperAdmin.service';
import { SharedService } from '../Shared.service';
import BigNumber from "bignumber.js";
import {MetamaskService} from "../../Shared/Metamask.service";


const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const investorService = new InvestorService();
const issuerSuperAdminService = new IssuerSuperAdminService();
const sharedService = new SharedService()
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const InvestModel = (props) => {
  const history = useHistory();
  const {displayInvestModel, 
    handleCancel,companyData, 
    investmentCompanyId = "",
    offeringDetails,token,
    remainingShareCount} = props
  const { userInfo } = useUserContext();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [bankInfo, setBankInfo] = useState<any>();
  const [tokenPhaseDetail, setTokenPhaseDetail] = useState<any>([]);
  const [paymentForm] = Form.useForm();
  const [paymentDone, setPaymentDone] = useState(false);
  const [amountStatus, setAmountStatus] = useState(false);
  const [totalToken, setTotalToken] = useState<any>();
  const companyId = investmentCompanyId !== '' ? investmentCompanyId : token.issuerInfo[0].companyId
  const tokenSymbol = token?.issuerInfo[0]?.data?.tokenSymbol
  let tokens = 0;
  const { selectedWallet } = useSelectedWalletContext();

  console.log('remainingShareCount',remainingShareCount)

  useEffect(() => {
    (async () => {
      const tokenResponse = await investorService.getTokenPhaseDetail({
        companyId: token?.issuerInfo[0].companyId,
      });
      if (tokenResponse.success && tokenResponse.data) {
        setTokenPhaseDetail(tokenResponse.data.bonusOffers);
      } else setTokenPhaseDetail([]);
    })();
  }, []);

    // useEffect(()=>{
    //   (async () => {
    //     setIsLoading(true);
  
    //     const _bankInfo = await issuerSuperAdminService.getBankInfoByCompanyId(companyId)
    //     console.log('bank info ',_bankInfo)
    //     if(_bankInfo.success && _bankInfo.data?._id){
    //       setBankInfo(_bankInfo.data);
    //       // setIsEditionMode(true);
    //     }else{
    //       setBankInfo({})
    //     }
  
    //     setIsLoading(false);
    //   })();
    // },[investmentCompanyId])
    
    const submitForm = async(form) => {
      try {
        setIsLoading(true);
        let formData = {...form, investorId:userInfo?._id}
        formData.wallet = selectedWallet
        formData.tokenSymbol = tokenSymbol
        formData.paymentMode = "ACH/Wire transfer"
        formData.tokenCount = formData.no_of_tokens
        delete formData.no_of_tokens
        if(formData.amountInvested?.toString()?.length > 1 && formData.amountInvested?.toString()?.indexOf(",") > -1) {
          formData.amountInvested = parseFloat(formData.amountInvested.toString().replace(/,/g, ''));
        }

        if(isNaN(formData.tokenCount)){
          console.log(formData)
          notification.error({message: 'Amount Invested should be a number only! Type the correct amount again'});
          setIsLoading(false);
          return false;
        }
        
        console.log('formData', formData);
        const response = await investorService.buyTokensWithPaymentDetailsAndCompanyId(companyId, formData)
          if (response.success) {
            notification.success({ message: 'Success' });
            setIsLoading(false)
            handleCancel();
            if(response.data.isAlreadyWhitelisted === false){
              history.push(`/whitelisted-wallet-requests?tokenSymbol=${tokenSymbol}`);
            }
          }else{
            setIsLoading(false)
            notification.success({ message: response.error.message });
          }
          
      } catch (error) {
        console.log(error);
        setIsLoading(false)
        notification.success({ message: error });
      }
    };  

    const populateAmountInvestInput = (e) => {
      let intialValue = e.target.value;
      console.log('companyData.pricePerToken:', companyData.pricePerToken);

      const amt = Number((e.target.value).replaceAll(',',''));
      const tokenCount = Number(amt) % Number(companyData.pricePerToken) !== 0 ? (Number(amt)/ Number(companyData.pricePerToken)).toFixed(2) : Number(amt)/ Number(companyData.pricePerToken);
      form.setFieldsValue({ tokenCount: tokenCount });

      const formattedValue = sharedService.formatIndianCurrency(e.target.value);
      console.log(`formattedValue: ${formattedValue}`);
      form.setFieldsValue({ amountInvested: formattedValue });
    }


    const handleTokenCount = async(event) => {
      console.log('handle token count', token,token.pricePerToken)
      let amount = Number(event.target.value.trim().replace(/,/g, ""));
      console.log("Amount || token", event.target.value.trim(), amount, 0);
      let bonus = 0;
      const pricePerToken = token?.pricePerToken ? token?.pricePerToken : token.issuerInfo[0].pricePerToken
      tokens = Number(
        (amount / pricePerToken).toFixed(2)
      );
  
      for (const data of tokenPhaseDetail) {
        if (
          data.minInvestmentAmount.min < amount &&
          amount < data.minInvestmentAmount.max
        ) {
          bonus = data.bonusPercent;
          if (bonus > 0) tokens = tokens + tokens * (bonus / 100);
        }
      }
      tokens = Math.round(tokens);
      let value = String(tokens);
      console.log("Amount || token", amount, value);
      
      const hasError = await paymentForm.validateFields(['amountToInvest'])
      if(hasError.errorFields?.length || tokens > Number(remainingShareCount)){
        if(tokens > Number(remainingShareCount)) message.info('Not enough shares available');
        paymentForm.setFieldsValue({ no_of_tokens: null });
        setTotalToken(null)
        return;
      }
      
      paymentForm.setFieldsValue({ no_of_tokens: tokens });
      setTotalToken(tokens)
  
    };
  

  return (
    <Modal
      title="Invest Now"
      visible={displayInvestModel}
      width="30%"
      onCancel={()=>handleCancel(false)}
      footer={null}
    >
      <Row justify="center">
        <Col span={23} style={{ textAlign: "center" }}>
          <Card>

            <div className="model-wrapper">
              
              <div hidden={false}>
                <Form onFinish={submitForm}
                labelCol={{ span: 23 }}
                wrapperCol={{ span: 23 }}
                layout="vertical"
                form={paymentForm}
                >
                               
              <Form.Item
                label="Amount to Invest"
                name="amountToInvest"
          
                normalize={(value, prevValue, allValues) => {
                  const _value = value.replace(/,/g, "");
                  console.log(value,prevValue,allValues)
                  console.log(offeringDetails)
                  
                  if (new BigNumber(_value).isGreaterThan(0))
                    return new BigNumber(_value).toFormat(0);
                  if (!_value) return "";
                  return prevValue || "";
                }}
                rules={[
                  {
                    required: true,
                    message: "Please input amount to raise!",
                  },
                  {
                    validator: (_, value) => {
                      const minAmount = Number(offeringDetails.minInvestmentAmount);
                      const maxAmount = Number(offeringDetails.maxInvestmentAmount);
                      const incrementAmount = Number(offeringDetails.investmentIncrementAmount);
                      const numericValue = Number(value.replace(/,/g, ''));

                      if(!numericValue || isNaN(numericValue)) return

                      if (numericValue < minAmount || numericValue > maxAmount) {
                        return Promise.reject(new Error(`The min and max investment amount is ${minAmount} and ${maxAmount}`));
                      }
                
                      console.error(numericValue, incrementAmount)
                      console.error(numericValue % incrementAmount)
                      if (numericValue % incrementAmount !== 0) {
                        if(numericValue % incrementAmount !== minAmount){
                          return Promise.reject(new Error(`Investment amount should be an increment of ${incrementAmount}`));
                        }
                      }
                
                      return Promise.resolve();
                    },
                  },
                ]}
                
              >
                <Input
                  disabled={amountStatus}
                  onChange={handleTokenCount}
                  addonAfter="USD"
                />
              </Form.Item>
              
              <Form.Item
                label="# of tokens"
                name="no_of_tokens"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Enter the number of shares",
                  },
                ]}
              >
                <Input type="number" style={{ color: "#3d61ff" }} disabled />
              </Form.Item>
              
                  <Form.Item
                  style={{ marginBottom: "10px" }}
                    label="Transaction ID"
                    name="txnId"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the transaction ID",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item  wrapperCol={{ offset: 12, span: 12 }} style={{ textAlign: "right", marginTop:'12px' }}>
                      <Button size='large' type='primary' htmlType="submit" loading={isLoading} >Submit</Button>
                  </Form.Item>
                </Form>
              </div>

            </div>

          </Card>
        </Col>
      </Row>
    </Modal>
  );
}

export default InvestModel