import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Card, Row, Col, Modal, Alert, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useHistory } from 'react-router-dom';
// import * as classNames from 'classnames';
import { SharedService } from '../../Shared/Shared.service';
import { AccountService } from '../Account.service';
import { AuthService } from '../../Shared/Auth.service';
import { environment } from '../../../environments/environment';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 11 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const accountService = new AccountService();
const sharedService = new SharedService();

const useUserContext = () => new AuthService().useUserContext();
export default () => {
    const history = useHistory();

    const [reqErr, setReqErr] = useState<string>();
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();

    const { userInfo, setUserInfo } = useUserContext();

    const handleSubmit = async (req) => {
        setSubmitting(true);
        const response = await accountService.forgotPassword(req);
        if (response.success) {
            message.success("Please check, you have received reset password link on your registered email address.", 7)
            form.resetFields();
        } else {
            message.error(response.data, 5)
        }
        setSubmitting(false);
    }

    return (
        <Row justify="center" style={{ marginTop: "60px" }}>
            <Col span={23} style={{ textAlign: 'center' }}>
                <Card style={{
                    // margin: '5% 0 0 15%',
                    // width: '70%'
                }}>
                    <h2 style={{ textAlign: 'center' }}>Forgot Password</h2>
                    <Form
                        // form = {form}
                        // size='middle'
                        onFinish={handleSubmit}
                        className='login-form'
                    // style={{margin: 'auto', marginTop: '3%', width: '60%',  textAlign: 'center'}}
                    >

                        <Form.Item
                            // label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                            validateStatus={reqErr && 'error'}
                            style={{ width: '300px', margin: 'auto', marginBottom: '10px' }}
                        >
                            <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Email' />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                style={{ marginTop: '20px' }}
                                type='primary'
                                htmlType='submit'
                                className='login-form-button'
                                loading={submitting}
                            >
                                Send Email
                            </Button>
                            <br />
                            <a className='login-form-forgot' href="/account/login">
                                Login
                            </a>
                            <br />
                            <br />
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    )
}