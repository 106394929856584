import { Button, Card, Col, Row, Spin, Table, Typography,Result } from 'antd';
import React, { useEffect, useState } from 'react'
import { RecoveryRequest,TokenConfigurationProcess, SymbolDetailsAndSTData } from '../../Shared/interfaces';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { RecoveryRequestsService } from '../RecoveryRequests.service';
import { SharedService } from '../../Shared/Shared.service';
import { MetamaskService } from '../../Shared/Metamask.service';
import { AuthService } from '../../Shared/Auth.service';
import { TokenConfigurationService } from '../../TokenConfigurations/TokenConfiguration.service';
import { SecurityTokenRegistryService } from '../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';


const {Title} = Typography;
const recoveryRequestsService = new RecoveryRequestsService();
const sharedService = new SharedService();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();
const useUserContext = () => new AuthService().useUserContext();
const tokenConfigurationService = new TokenConfigurationService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();


export default function RecoveryRequestsList() {
  const {userInfo} = useUserContext();
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
const {selectedWallet,networkId} = useSelectedWalletContext();

  const [loading, setLoading] = useState<boolean>(true);
  const [recoveryProcessReqs, setRecoveryProcessReqs] = useState<RecoveryRequest[]>();

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const _recoveryProcessReqs = (await recoveryRequestsService.getRecoveryProcessRequests()).data as RecoveryRequest[];

        setRecoveryProcessReqs(_recoveryProcessReqs);

        const _tokenConfigurationProcess: TokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;
        const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
        setSymbolDetailsAndSTData(_symbolDetailsAndSTData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userInfo]);


  const columns = [
    {
      title: 'CREATED ON',
      dataIndex: 'creationTS',
      render: (value: number) => moment(value).format('lll')
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      render: (value: string, record) => `${record.firstName} ${record.lastName}`
    },
    {
      title: 'EMAIL ADDRESS',
      dataIndex: 'email'
    },
    {
      title: 'Old Wallet',
      dataIndex: 'oldWallet',
      render: (value: string) => {
        return (
          <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${value}`}>
            {sharedService.minifyAddress(value)}
          </a> 
        );
      }
    },
    {
      title: 'New Wallet',
      dataIndex: 'newWallet',
      render: (value: string) => {
        return (
          <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${value}`}>
            {sharedService.minifyAddress(value)}
          </a> 
        );
      }
    },
    {
      title: 'ACTION',
      render: (value, record) => {
        return (
          <>
            <Button type="primary" onClick={() => history.push(`/recovery-requests/${record._id}`)}>
              View
            </Button>
          </>
        );
      }
    }
  ];


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {loading && 
            <div style={{textAlign:'center'}}>
              <br/><br/>
              <Spin size='large'/>
            </div>
          }
{!loading ? (
    userInfo?.role === 'issuer token admin' &&
    selectedWallet?.toLowerCase() !== symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() ? 
      <Card>
        <Title level={2} style={{textAlign: 'center'}}>Wrong selected wallet on Metamask</Title>
        <Result
          status="error"
          title={
            <p>
              Select the wallet{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${sharedService.etherscanURL[networkId as string]}/address/${symbolDetailsAndSTData.symbolDetails.owner}`}
              >
                {sharedService.minifyAddress(symbolDetailsAndSTData.symbolDetails.owner.toLowerCase())}
              </a>
              {' '} in order to Proceed

            </p>
          }
        />
      </Card>
     : (
              <Card>
                <Title level={3} style={{ textAlign: 'center' }}>Securities Recovery Processes</Title>
                <Table
                  columns={columns}
                  dataSource={recoveryProcessReqs}
                  rowKey='_id'
                  pagination={false}
                />
              </Card>
            ) ): null}
        </Col>
      </Row>
    </>
  );
}