import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import UploadDocuments from './UploadDocuments';

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/upload-documents`}>
        <UploadDocuments/>
      </Route>
    </Switch>
  )
}
