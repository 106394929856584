import React, { useState, useEffect } from 'react';
import {AuthService} from "../../Shared/Auth.service";
import MasterCompanyService from "../../MasterCompany/MasterCompany.service";
import {SCField} from "../../Shared/interfaces";
import CardHOC from "../../CardHOC";
import {Button, Checkbox, Form, Input, Select, Spin, Table, Modal, Popconfirm} from "antd";
import moment from "moment";
import {environment} from "../../../environments/environment";
import {SharedService} from "../../Shared/Shared.service";
const { Option } = Select;


const masterCompanyService = new MasterCompanyService();
const sharedService = new SharedService();

const useUserContext = () => new AuthService().useUserContext();

export default function SeriesFields() {
  const {userInfo} = useUserContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState(false);
  const [scFields, setScFields] = useState<SCField[]>();
  const [selectedScFields, setSelectedScFields] = useState<SCField | null>();
  const [isFieldModalVisible, setIsFieldModalVisible] = useState(false);
  const [modalForm] = Form.useForm();

useEffect(() => {
  (async () => {
    if(!userInfo) return;

    const _scFields = (await masterCompanyService.getSeriesFields()).data;
    setScFields(_scFields);

    setLoading(false);
  })();
}, [userInfo]);

const columns = [
  {
    title: 'CREATED ON',
    dataIndex: 'creationTS',
    render: (value: number) => moment(value).format('lll')
  },
  {
    title: 'LABEL',
    dataIndex: 'label'
  },
  {
    title: 'TYPE',
    dataIndex: 'type',
    render: (value: string) => masterCompanyService.fieldTypes.find(type => type.value === value)?.name
  },
  {
    title: 'REQUIRED',
    dataIndex: 'isRequired',
    render: (value: boolean) => value? 'YES' : 'NO'
  },
  {
    title: 'SAVE IN CONTRACT',
    dataIndex: 'saveInContract',
    render: (value: boolean) => value? 'YES' : 'NO'
  },
  {
    title: 'ACTION',
    dataIndex: 'action',
    render: (value, record: SCField) => <>
      <Button size='small' style={{marginLeft: '5px'}} type='primary' onClick={() => openFieldModal({selectedScFields: record})}>
        EDIT
      </Button>
      <Popconfirm
        title="Sure to delete?"
        onConfirm={() => deleteField({selectedScFields: record})}
      >
        <Button size='small' style={{marginLeft: '5px'}} danger >
          DELETE
        </Button>
      </Popconfirm>
    </>
  }
];


const openFieldModal = (prop: {selectedScFields: SCField | null}) => {
  setSelectedScFields(prop.selectedScFields);
  modalForm.setFieldsValue(prop.selectedScFields);
  setIsFieldModalVisible(true);
}

const saveField = async(formValue) => {
  console.log(formValue);

  setSubmitting(true);

  const response = await masterCompanyService.saveSeriesField({
    ...formValue, _id: selectedScFields?._id
  });

  if(response.success) {
    setScFields(prev => {
      const current: SCField[] = sharedService.clone(prev);

      if(selectedScFields) {
        const found = current.find(field => field._id === selectedScFields._id) as SCField;

        found.label = formValue.label;
        found.saveInContract = !!formValue.saveInContract;
        found.isRequired = !!formValue.isRequired;

      } else {
        current.push({
          _id: response.data._id,
          creationTS: response.data.creationTS,
          apiKey: environment.apiKey,
          // companyId: userInfo?.company?.id,
          label: formValue.label,
          type: formValue.type,
          saveInContract: !!formValue.saveInContract,
          isRequired: !!formValue.isRequired,
        });
      }

      return current;
    });

    setIsFieldModalVisible(false);
    modalForm.resetFields();
  }

  setSubmitting(false);
}

const deleteField = async(prop: {selectedScFields: SCField}) => {
  const response = await masterCompanyService.deleteSeriesField({_id: prop.selectedScFields._id});

  if(response.success) {
    setScFields(prev => {
      const current: SCField[] = sharedService.clone(prev);
      current.splice(current.findIndex(field => field._id === prop.selectedScFields._id), 1);

      return current;
    });
  }

}



return(<CardHOC
  heading={'Series Fields'}
  component={<>

    {loading &&
    <div style={{textAlign:'center'}}>
      <br/><br/>
      <Spin size='large'/>
    </div>
    }

    {!loading &&
    <>
      <div style={{textAlign:'right', marginBottom: '10px'}}>

        <Button type="primary" size='large' onClick={() => openFieldModal({selectedScFields: null})}>
          NEW FIELD
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={scFields}
        rowKey='_id'
        pagination={false}
      />

    </>
    }


    <Modal
      title={'Save field'}
      okText='SUBMIT'
      cancelText='CANCEL'
      visible={isFieldModalVisible}
      onOk={()=> modalForm.submit()}
      onCancel={()=>{
        setIsFieldModalVisible(false);
        modalForm.resetFields();
      }}
      okButtonProps={{
        loading: submitting,
      }}
    >

      <Form
        form={modalForm}
        autoComplete={'off'}
        onFinish={saveField}
      >

        <Form.Item
          name={'label'}
          label={'Label'}
          wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Input placeholder={'Label'}/>
        </Form.Item>

        <Form.Item
          name={'type'}
          label={'Type'}
          wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            placeholder="Select Field Type"
            disabled={!!selectedScFields}
          >
            {masterCompanyService.fieldTypes?.map(fieldType =>
              <Option key={fieldType.value} value={fieldType.value}>{fieldType.name}</Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item
          name={'isRequired'}
          valuePropName="checked"
          wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
          style={{marginBottom: '0px'}}
        >
          <Checkbox>Required</Checkbox>
        </Form.Item>

        <Form.Item
          name={'saveInContract'}
          valuePropName="checked"
          wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
          style={{marginBottom: '0px'}}
        >
          <Checkbox>Save In Contract</Checkbox>
        </Form.Item>


      </Form>

    </Modal>


  </>}
/>);
}