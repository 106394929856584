import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AmendmentsList from "./AmendmentsList";
import AmendmentNew from "./AmendmentsNew";


export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <AmendmentsList/>
      </Route>

      <Route path={`${path}/new`}>
        <AmendmentNew/>
      </Route>
    </Switch>
  );
}