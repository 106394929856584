import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Card,
  Form,
  Typography,
  message,
  Space,
  List,
  Radio,
  Empty,
  Spin,
  Table,
  Progress,
  Popconfirm,
} from "antd";
import { IssuerSuperAdminService } from "../IssuerSuperAdmin.service";
import { AuthService } from "../../Shared/Auth.service";
import { SharedService } from "../../Shared/Shared.service";
import { type } from "os";
const { Title } = Typography;
const { TextArea } = Input;
const auth = new AuthService();
const useUserContext = () => auth.useUserContext();
const sharedService = new SharedService();
const issuerSuperAdminService = new IssuerSuperAdminService();

export default () => {
  const { userInfo } = useUserContext();
  const [dueDiligenceList, setDueDiligenceList] = useState(null);
  const [dueDiligenceListView, setDueDiligenceListView] = useState(true);
  const [dueDiligenceForm] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [answeredDueDiligenceList, setAnsweredDueDligenceList] = useState(null);
  const [currentQuestionToAnswer, setCurrentQuestionToAnswer] = useState(null);
  const [heading, setHeading] = useState("Answer Due Diligence");
  const [answerView, setAnswerView] = useState(true);
  const [documentsToSubmit, setDocumentsToSubmit] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [aiDocument, setAiDocument] = useState({
    answer: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
  });

  const initialFileObj = {
    answer: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
  };

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      render: (answer, record) => {
        return (
          <>
            {record.answerType === "attachment" && (
              <ol>
                {answer.map((a) => (
                  <>
                    <li>
                      <a href={a.URL} rel="noopener norefferer" target="_blank">
                        {a.name}
                      </a>
                    </li>
                  </>
                ))}
              </ol>
            )}
            {record.answerType !== "attachment" && answer}
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, dueDiligenceQuestion) => (
        <>
          {dueDiligenceQuestion.isReviewed && (
            <span style={{ color: "green" }}>Approved</span>
          )}
          {dueDiligenceQuestion?.isRejected && (
            <span style={{ color: "red" }}>Rejected</span>
          )}
          {!dueDiligenceQuestion.isReviewed &&
            !dueDiligenceQuestion?.isRejected && (
              <span style={{ color: "yellow" }}>Under Review</span>
            )}
        </>
      ),
    },
  ];
  useEffect(() => {
    (async () => {
      const getDueDiligenceListResult =
        await issuerSuperAdminService.getDueDiligenceList({
          companyId: userInfo.company.id,
        });
      if (getDueDiligenceListResult.success) {
        if (getDueDiligenceListResult.data.length > 0) {
          await setDueDiligenceList(getDueDiligenceListResult.data);
        } else {
          await setDueDiligenceList([]);
        }
      } else {
        await setDueDiligenceList([]);
        message.error("Error in loading due diligence.");
      }
      const getAnsweredDueDligenceListResult =
        await issuerSuperAdminService.getAnsweredDueDligenceList({
          companyId: userInfo.company.id,
        });
      if (getAnsweredDueDligenceListResult.success) {
        if (getAnsweredDueDligenceListResult.data.length > 0) {
          await setAnsweredDueDligenceList(
            getAnsweredDueDligenceListResult.data
          );
        } else {
          await setAnsweredDueDligenceList([]);
        }
      }
    })();
  }, [dueDiligenceListView, answerView]);

  const answerDueDiligence = (data) => {
    setCurrentQuestionToAnswer(data);
    setDueDiligenceListView(false);
  };

  const submitAnswerForDueDiligence = async (data) => {
    await setSubmitting(true);
    if (currentQuestionToAnswer.answerType === "attachment") {
      data.answer = documentsToSubmit;
    }
    let dataToSend = await { ...currentQuestionToAnswer, ...data };
    dataToSend.companyId = userInfo.company.id;
    let response = await issuerSuperAdminService.setDueDiligenceAnswer(
      dataToSend
    );
    if (response.success && response.data) {
      message.success("Answered.");
    } else {
      message.error("Error while saving data!!");
    }
    if (currentQuestionToAnswer.answerType === "attachment") {
      setAiDocument(initialFileObj);
      dueDiligenceForm.resetFields();
      setDocumentsToSubmit([]);
      setDocuments([]);
    }
    await setDueDiligenceListView(true);
    await setSubmitting(false);
  };

  const uploadFile = (e, key, setFilesObj, form) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (!file) return;

    const fileType = sharedService.getFileType(file.name);
    setFilesObj((prev) => {
      const current = sharedService.clone(prev);
      current[key].URL = null;
      return current;
    });

    issuerSuperAdminService.uploadFile(
      file,
      (uploadFilePercent) => {
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = true;
          current[key].uploadFilePercentage = uploadFilePercent;
          return current;
        });
      },
      (err) => {
        console.error(err);
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = false;
          return current;
        });
      },
      async (response) => {
        if (response.success) {
          const URL = response.data.url;
          setFilesObj((prev) => {
            const current = sharedService.clone(prev);
            current[key].URL = URL;
            current[key].uploading = false;
            current[key].name = file.name;
            current[key].type = fileType.toLowerCase();
            return current;
          });
          let fileDetails = { [key]: { URL, name: file.name } };
          form.setFieldsValue(fileDetails);
          setDocuments((prev) => prev.concat(file.name));
          setDocumentsToSubmit((prev) => prev.concat({ URL, name: file.name }));
        } else {
          console.error(response.error);
          setFilesObj((prev) => {
            const current = sharedService.clone(prev);
            current[key].uploading = false;
            return current;
          });
        }
      }
    );
  };

  const deleteItem = (index) => {
    setDocuments(documents.filter((document) => document !== documents[index]));
    setDocumentsToSubmit(
      documentsToSubmit.filter(
        (document) => document !== documentsToSubmit[index]
      )
    );
  };

  return (
    <>
      <br />
      <Row justify="center">
        <Col span={22} style={{ textAlign: "center" }}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: "left",
                color: "#1890ff",
                // fontSize: '30px',
                fontWeight: "bold",
              }}
            >
              {heading}
            </Title>
            <div hidden={!answerView}>
              <div style={{display:'flex',justifyContent:'end'}}>
                <button
                 type="primary"
                  onClick={() => {
                    setHeading("View Answer Status");
                    setAnswerView(false);
                  }}
                >
                  View Answer Status
                </button>
              </div>
              <br />
              <div hidden={!dueDiligenceListView}>
                {dueDiligenceList ? (
                  <List
                    style={{ textAlign: "left" }}
                    loading={!dueDiligenceList}
                    key="dueDiligenceList"
                  >
                    {dueDiligenceList?.map((data, index) => (
                      <List.Item
                        key={index}
                        style={{ cursor: "pointer" }}
                        actions={[
                          <Button
                            type="primary"
                            onClick={() => answerDueDiligence(data)}
                          >
                            Answer
                          </Button>,
                        ]}
                      >
                        <List.Item.Meta title={data.question}></List.Item.Meta>
                      </List.Item>
                    ))}
                  </List>
                ) : (
                  <Spin />
                )}
                {dueDiligenceList && dueDiligenceList?.length === 0 && (
                  <Empty />
                )}
              </div>

              <div hidden={dueDiligenceListView}>
                <Form
                  key="dueDiligenceAnswerForm"
                  form={dueDiligenceForm}
                  onFinish={submitAnswerForDueDiligence}
                >
                  {/* <Form.Item
                    label={currentQuestionToAnswer?.question}
                    key={currentQuestionToAnswer?.question}
                  ></Form.Item> */}
                  <span style={{textAlign:'left',display:'block',fontSize:'19px', margin:'25px 0px'}} key={currentQuestionToAnswer?.question}>{currentQuestionToAnswer?.question}</span>

                  {currentQuestionToAnswer &&
                    currentQuestionToAnswer?.answerType == "option" && (
                      <Form.Item
                        key="optionAnswer"
                        label="Answer:"
                        name="answer"
                        rules={[
                          {
                            required: true,
                            message: "Please select a option",
                          },
                        ]}
                      >
                        <Radio.Group disabled={submitting}>
                          <Radio value="Yes">Yes</Radio>
                          <Radio value="No">No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    )}

                  {currentQuestionToAnswer &&
                    currentQuestionToAnswer?.answerType === "textArea" && (
                      <Form.Item
                        key="optionAnswer"
                        label="Answer:"
                        name="answer"
                        rules={[
                          {
                            required: true,
                            message: "Please select a option",
                          },
                        ]}
                      >
                        <TextArea disabled={submitting}></TextArea>
                      </Form.Item>
                    )}

                  {currentQuestionToAnswer &&
                    currentQuestionToAnswer?.answerType === "attachment" && (
                      <Form.Item
                        key="attachmentAnswer"
                        label="Answer:"
                        name="answer"
                        rules={[
                          {
                            required: true,
                            message: "Please select a option",
                          },
                        ]}
                      >
                        <Input
                          type="file"
                          onChange={(e) => {
                            uploadFile(
                              e,
                              "answer",
                              setAiDocument,
                              dueDiligenceForm
                            );
                          }}
                          disabled={submitting}
                        />
                        {aiDocument.answer.uploadFilePercentage > 0 && (
                          <>
                            <Progress
                              percent={aiDocument.answer.uploadFilePercentage}
                            />
                            <br />
                            <div align="left">
                              Files Uploaded:
                              <List
                                itemLayout="horizontal"
                                style={{ textAlign: "left" }}
                              >
                                {documents?.map((obj, index) => (
                                  <List.Item
                                    textalign="left"
                                    style={{ cursor: "pointer" }}
                                    key={index}
                                    actions={[
                                      <Popconfirm
                                        title="This item will be deleted!"
                                        onConfirm={() => deleteItem(index)}
                                      >
                                        <Button type="primary" danger>
                                          X
                                        </Button>
                                      </Popconfirm>,
                                    ]}
                                  >
                                    <List.Item.Meta title={obj} />
                                  </List.Item>
                                ))}
                              </List>
                            </div>
                          </>
                        )}
                      </Form.Item>
                    )}

                  <Form.Item>
                    <Space>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={submitting}
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={() => {
                          setCurrentQuestionToAnswer(null);
                          setDueDiligenceListView(true);
                        }}
                        loading={submitting}
                      >
                        Cancel
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </div>
            </div>
            <div hidden={answerView}>
              <div style={{display:'flex',justifyContent:'end'}}>
                <button
                 type="primary"
                  onClick={() => {
                    setHeading("Answer due diligence");
                    setAnswerView(true);
                  }}
                >
                  Answer Due Diligence
                </button>
              </div>
              <br />
              {answeredDueDiligenceList == null && <Spin />}
              {answeredDueDiligenceList &&
                answeredDueDiligenceList?.length === 0 && <Empty />}
              {answeredDueDiligenceList &&
                answeredDueDiligenceList?.length > 0 && (
                  <Table
                    dataSource={answeredDueDiligenceList}
                    columns={columns}
                  />
                )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
