import React, { useState, useEffect } from 'react';
import CardHOC from "../../CardHOC";
import {AuthService} from "../../Shared/Auth.service";
import {SeriesService} from "../Series.service";
import {Button, Form, Input, message, Modal, notification, Spin, Table,Space, Tooltip} from "antd";
import { useHistory } from 'react-router-dom';
import moment from "moment";
import {SeriesDetails} from "../../Shared/interfaces";



const seriesService = new SeriesService();

const useUserContext = () => new AuthService().useUserContext();


export default function SeriesList({newSeries = true}) {
  const {userInfo} = useUserContext();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);
  const [series, setSeries] = useState<SeriesDetails[]>();
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [modalForm] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState<SeriesDetails>();
  const [smartDeployed, setSmartDeployed] = useState(false);

  useEffect(() => {
    (async () => {
      if (!userInfo) return;

      const response = await seriesService.getSeries();
      const sortedSeries = response.data.sort((a: SeriesDetails, b: SeriesDetails) => b.creationTS - a.creationTS);
      const seriesWithSerialNumbers = sortedSeries.map((item, index) => ({
        ...item,
        serialNumber: index + 1,
      }));
      setSeries(seriesWithSerialNumbers);

      const res = await seriesService.getSmart();
      if (res.data === 'true') {
        setSmartDeployed(true);
      }

      setLoading(false);
    })();
  }, [userInfo]);

  const openInviteSeriesModal = (prop: {selectedSeries: SeriesDetails}) => {
    modalForm.resetFields();
    setSelectedSeries(prop.selectedSeries);
    setIsInviteModalVisible(true);
  }

  const inviteSeriesManager = async(formValue) => {
    setSubmitting(true);

    const response = await seriesService.inviteSeriesManager({
      email: formValue.email,
      seriesId: selectedSeries?._id as string
    });

    if(response.success) {
      notification.success({
        message: 'Success',
        description: 'Invite sent to Property Super Admin Successfully'
      });

      setIsInviteModalVisible(false);

    } else {
      console.error(response.error);
      message.error(response.error.message);
    }

    setSubmitting(false);
  }

  // const   seriesName = (value) => `Crypto Asset Rating ${value}`;

  const columns = [
    {
      title: 'SERIES NUMBER',
      dataIndex: 'serialNumber'
    },
    {
      title: 'SERIES NAME',
      dataIndex: 'name',
      // render: (value: string) => seriesName(value)
    },
    {
      title: 'Property Super Admin',
      dataIndex: 'seriesManagerName',
      render: (value, record: SeriesDetails) => (
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'inline-block', maxWidth: '150px' }}>
            {record.seriesManagerId ? `${record['seriesManagerFirstName']} ${record['seriesManagerLastName']}` : '-'}
          </span>
      )
    },
    {
      title: ' Property Super Admin Email Address',
      dataIndex: 'seriesManagerEmail',
      render: (value: string, record: SeriesDetails) => (
        <Tooltip title={record.seriesManagerEmail}>
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'inline-block', maxWidth: '150px' }}>
            {record.seriesManagerId ? record['seriesManagerEmail'] : '-'}
          </span>
        </Tooltip>
      )
    },
    {
      title: 'CREATED ON',
      dataIndex: 'creationTS',
      render: (value: number) => moment(value).format('lll')
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (value, record: SeriesDetails) => (
        <Space>
          {record.seriesManagerId ?(
            <span>Invited</span>
          ) : (
            <Button
              size='small'
              type='primary'
             onClick={() => openInviteSeriesModal({selectedSeries: record})}
            >
             INVITE Property Super Admin
            </Button>
          )}
        </Space>
      )
    }
  ];


  return (
    <CardHOC
      heading={'Series List'}
      component={(
        <>
          {loading ? (
            <div style={{ textAlign: 'center' }}>
              <br /><br />
              <Spin size='large' />
            </div>
          ) : (
            <>
              {newSeries && (
                <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                  {smartDeployed && (
                    <Button type="primary" size='large' onClick={() => history.push(`/series/new`)}>
                      NEW SERIES
                    </Button>
                  )}
                </div>
              )}

              <Table
                columns={columns}
                dataSource={series}
                rowKey='_id'
              />
            </>
          )}

          <Modal
            title={<span style={{ padding: '3px' }}>Invite Property Super Admin to {selectedSeries?.name}</span>}   
            okText='SUBMIT'
            cancelText='CANCEL'
            visible={isInviteModalVisible}
            onOk={() => modalForm.submit()}
            onCancel={() => setIsInviteModalVisible(false)}
            okButtonProps={{
              loading: submitting,
            }}
          >

            <Form
              form={modalForm}
              autoComplete={'off'}
              onFinish={inviteSeriesManager}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >

              <Form.Item
                name={'email'}
                label={'Email'}
                labelAlign='left'
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input placeholder={'Email'} />
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    />
  );
}
