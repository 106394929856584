import { Button, Card, Col, Form, Modal, notification, Radio, Row, Spin, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { SymbolDetailsAndSTData, TokenConfigurationProcess,IssuerRegistrationRequest } from '../../Shared/interfaces';
import { useHistory } from 'react-router-dom';
import { TokenConfigurationService } from '../TokenConfiguration.service';
import { SharedService } from '../../Shared/Shared.service';
import moment from 'moment';
import { AuthService } from '../../Shared/Auth.service';
import { MetamaskService } from '../../Shared/Metamask.service';
import { SecurityTokenRegistryService } from '../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import MainFacet from '../../Shared/SecurityToken/Facets/MainFacet';
import WrongMetamaskWalletWarning from '../../Shared/WrongMetamaskWalletWarning';
import { promises } from 'fs';
import { IssuerSuperAdminService } from '../../IssuerSuperAdmin/IssuerSuperAdmin.service';
import { MasterCompanySuperAdminSer } from '../../masterCompanySuperAdmin/masterCompanySuperAdmin.service';
import { IssuerTokenAdminService } from "../../IssuerTokenAdmin/IssuerTokenAdmin.service";
import TxFeeDelegationModal from "../../Shared/TxFeeDelegationModal";
import TransactionModal from "../../Shared/TransactionModal";

import BigNumber from "bignumber.js";
const {Title} = Typography;

const regulationRadioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const tokenConfigurationService = new TokenConfigurationService();
const sharedService = new SharedService();
const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const mainFacet = new MainFacet();
const issuerSuperAdminService = new IssuerSuperAdminService();
const masterCompanySuperAdminService = new MasterCompanySuperAdminSer();
const issuerTokenAdminService = new IssuerTokenAdminService();
export default function TokenConfigurationList() {

  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [isRelaunchTokenModalVisible, setIsRelaunchTokenModalVisible] = useState<boolean>();
  const [relaunchTokenForm] = Form.useForm();
  // const [relaunchRegulation, setRelaunchRegulation] = useState('');
  const {selectedWallet, networkId} = useSelectedWalletContext();
  const [issuerTokenAdminWallet, setIssuerTokenAdminWallet] = useState('');
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();

  const [tokenConfigurationProcesses, setTokenConfigurationProcesses] = useState<TokenConfigurationProcess[]>();
  const [
    selectedTokenConfigurationProcess,
    setSelectedTokenConfigurationProcess,
  ] = useState<
      TokenConfigurationProcess & {
    symbolDetailsAndSTData?: SymbolDetailsAndSTData;
    isLastPartition?: boolean;
  }>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDelegationModalVisible, setIsDelegationModalVisible] =
      useState(false);

  const [tokenConfigurationProcessesArr, setTokenConfigurationProcessesArr] =
      useState<TokenConfigurationProcess[][]>();
  const [isCloseSaleModalVisible, setIsCloseSaleModalVisible] = useState(false);
  const [transactions, setTransactions] = useState<
      { submitting?: boolean; receipt?: any; details: string }[]
      >([]);

  const {userInfo} = useUserContext();

  console.info(tokenConfigurationProcesses)
  console.log(tokenConfigurationProcesses)
  console.log(tokenConfigurationProcesses?.find(process => !process.isTokenSaleClosed));



  useEffect(() => {
    (async () => {
      const _tokenConfigurationProcesses = (await tokenConfigurationService.getTokenConfigurationProcesses()).data as TokenConfigurationProcess[];

      setTokenConfigurationProcesses(_tokenConfigurationProcesses);
      await getTokenDetails()
      setLoading(false);

    })();
  }, []);




  const columns = [
    {
      title: 'REGULATION',
      dataIndex: 'regulation',
      render: (value: TokenConfigurationProcess['regulation']) => sharedService.regOptions.find(opt => opt.value === value)?.name
    },
    {
      title: 'CREATED ON',
      dataIndex: 'creationTS',
      render: (value: number) => moment(value).format('lll')

    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: (value: TokenConfigurationProcess['status']) => value
    },
    {
      title: 'TOKEN SALE ENDED ON',
      dataIndex: 'dateTokenSaleClosed',
      render: (value: number) => value? moment(value).format('lll') : '-'

    },
    {
      title: 'ACTION',
      render: (value, record: TokenConfigurationProcess) => {
        return (
          <>
  {/* View Button */}
  <Button
    size="small"
    type="primary"
    onClick={() => history.push(`/token-configurations/${record._id}/create`)}
  >
    View
  </Button>
  {/* Create/View Legend Button */}
  {userInfo?.role === 'issuer counsel' && (
  <Button
    size="small"
    style={{ marginLeft: '5px' }}
    type="primary"
    onClick={() => {
      history.push(`/issuer-counsel/create-legend/${record._id}`);
    }}
  >
    {record.isLegendAdded ? 'View Legend' : 'Create Legend'}
  </Button>
  )}

  {/* Remove Legend Button */}
  {userInfo?.role === 'issuer counsel' && (
  <Button
    size="small"
    style={{ marginLeft: '5px' }}
    type="primary"
    onClick={() => history.push(`/issuer-counsel/remove-legend/${record._id}`)}
  >
    Remove Legend
  </Button>

  )}

  {userInfo?.role === 'issuer token admin' && (
  <Button
    size="small"
    style={{ marginLeft: '5px' }}
    type="primary"
    onClick={() => history.push(`/issuer-token-admin/remove-legend/${record._id}`)}
  >
    Remove Legend
  </Button>
  )}
            {userInfo?.role === "issuer token admin" &&
                symbolDetailsAndSTData?.symbolDetails.isDeployed
                    &&
                !record.isTokenSaleClosed && (
                    <>
                      <Button
                          size="small"
                          style={{ marginLeft: "5px" }}
                          type="primary"
                          onClick={() => {
                           /* if (
                                selectedWallet.toLowerCase() !==
                                symbolDetailsAndSTData.symbolDetails.owner.toLowerCase()
                            ) {
                              return notification.error({
                                message: "ERROR",
                                description: "Wrong selected Wallet on Metamask",
                              });
                            }*/

                            setSelectedTokenConfigurationProcess(record);
                            setIsCloseSaleModalVisible(true);
                          }}
                      >
                        Close Token Sale
                      </Button>
                    </>
                )}

</>

        );
      }
    }
  ];

  // const availableRegs = sharedService.regOptions.filter(option => !tokenConfigurationProcesses?.find(process => process.regulation === option.value));

  const getTokenDetails = async()=> {
    if(!userInfo) return;

        const _tokenConfigurationProcess: TokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;

        if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

        const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
        setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

        if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);

        const _issuerTokenAdminWallet = await mainFacet.issuerTokenAdmin(_symbolDetailsAndSTData.securityTokenData.contractAddress)

        setIssuerTokenAdminWallet(_issuerTokenAdminWallet);
        return
  }
  const closeSale = async (prop: { delegate: boolean }) => {
    setIsModalVisible(true);
    setTransactions([{ details: "Closing Token sale", submitting: true }]);

    try {
      const symbolDetailsAndSTData: SymbolDetailsAndSTData =
          selectedTokenConfigurationProcess["symbolDetailsAndSTData"];
     const _symbolDetailsAndSTData =
          await securityTokenRegisteryService.getSymbolDetailsAndSTData(
              selectedTokenConfigurationProcess.tokenSymbol
          );
      const [totalRaisedRes, registrationRequestRes] = await Promise.all([
        issuerTokenAdminService.getTotalRaised({
          tokenConfigurationProcessId: selectedTokenConfigurationProcess._id,
        }),
        issuerSuperAdminService.getRegistrationRequest(),
      ]);

      const { totalRaised,amountToRaise } = totalRaisedRes.data as {
        totalRaised: number;
        totalTransactions: number;
        amountToRaise: number;
      };

      const _isGoalReached = new BigNumber(totalRaised).gte(amountToRaise);

      console.log({
        amountToRaise,
        totalRaised,
        _isGoalReached,
      });

      const receipt = await mainFacet.closeTokenSale(
          _symbolDetailsAndSTData.securityTokenData.contractAddress,
          selectedWallet,
          _isGoalReached,
          { delegate: prop.delegate }
      );
      console.log(receipt);

      setTransactions((prev) => {
        const current = sharedService.clone(prev);
        current[0].receipt = receipt;
        return current;
      });

      if (receipt?.status) {
        await tokenConfigurationService.closeSale({
          tokenConfigurationProcessId: selectedTokenConfigurationProcess._id,
        });

        setSelectedTokenConfigurationProcess((prev) => {
          const current = sharedService.clone(
              prev
          ) as TokenConfigurationProcess & {
            symbolDetailsAndSTData: SymbolDetailsAndSTData;
          };

          current.isTokenSaleClosed = true;
          current.dateTokenSaleClosed = Date.now();

          return current;
        });

        setTokenConfigurationProcessesArr((prev) => {
          const current = sharedService.clone(
              prev
          ) as TokenConfigurationProcess[][];

          current?.forEach((tokenConfigurationProcesses) => {
            tokenConfigurationProcesses?.forEach((tokenConfigurationProcess) => {
              if (
                  tokenConfigurationProcess._id ===
                  selectedTokenConfigurationProcess._id
              ) {
                tokenConfigurationProcess.isTokenSaleClosed = true;
                tokenConfigurationProcess.dateTokenSaleClosed = Date.now();
              }
            });
          });

          return current;
        });
      }
    } catch (err) {
      console.error(err);
    }

    setTransactions((prev) => {
      const current = sharedService.clone(prev);
      current[0].submitting = false;
      return current;
    });
  };

  const openRelaunchTokenModal = async() => {

    const [valid,msg] = await validateForPropertyOfferingAndPaymentMethod();
    if(!valid){
      notification.info({
        message: msg,
      });
      return
    }
    if(!tokenConfigurationProcesses?.length) return history.push(`/token-configurations/new-config/type-and-details`);

    if(tokenConfigurationProcesses?.some(process => !process.isTokenSaleClosed)) {
      return notification.error({message: 'ERROR', description: 'You have to close the last Token Sale in order to relaunch the Token'});
    }
    setIsRelaunchTokenModalVisible(true);
  }

  const validateForPropertyOfferingAndPaymentMethod = async()=>{
    const[propertyDetails,offering,paymentMethodStatus,dueDiligence] = await Promise.all([
      issuerSuperAdminService.getPropertyDetails(userInfo?.company?.id),
      masterCompanySuperAdminService.currentOfferingStatus({ companyId: userInfo?.company?.id }),
      issuerSuperAdminService.getPaymentMethodStatus(userInfo?.company?.id),
      sharedService.getIssuerDueDiligenceByCompanyId(userInfo?.company?.id)
    ])
    console.log("dueDiligence",dueDiligence);

    if(!propertyDetails.data.length){
      return [false,'Please add property before launching token!']
    }else if(propertyDetails.data[0]?.status !== 'approved'){
      return [false,'Property has not been approved yet!']
    }
    else if(!offering.data){
      return [false,'Please create offering before launching token'!]
    }else if(!paymentMethodStatus.data){
      return [false,'Please create at least one payment method with approval!']
    }else if (typeof(paymentMethodStatus.data) === 'string' && paymentMethodStatus.data.toLowerCase() === 'notapproved'){
      return [false,'Payment method is not approved!']
    }else if (!dueDiligence.data.length){
      return [false,'Please add due diligence details to continue.']
    }
    else{
      return [true,'VALID']
    }
  }

  const refreshList = async () => {
    setLoading(true);
    const _tokenConfigurationProcesses = (await tokenConfigurationService.getTokenConfigurationProcesses()).data;
    setTokenConfigurationProcesses(_tokenConfigurationProcesses);
    await getTokenDetails();
    setLoading(false);
  };

  return (

  <>
    <br/><br/>

    <Row justify="center">

      <Col span={23}>
        {loading &&
          <div style={{textAlign:'center'}}>
            <br/>
            <Spin size='large'/>
          </div>
        }
        {!loading &&
          <>
          {symbolDetailsAndSTData?.symbolDetails.isDeployed && selectedWallet?.toLowerCase() !== issuerTokenAdminWallet.toLowerCase() ?
            <Card><WrongMetamaskWalletWarning address={issuerTokenAdminWallet}/></Card>
          :
          <Card>
            <Title level={1} style={{textAlign:'center'}}>Token Configurations</Title>

            {userInfo?.role === 'issuer token admin' &&
              <div style={{textAlign:'right', marginBottom: '10px'}}>
                {/* <Button style={{marginRight:'5px'}} size='large' onClick={()=> setCryptoAccountModalVisible(true)}>
                  ADD CRYPTO ACCOUNTS
                </Button> */}
                <Button type="primary" size='large' onClick={openRelaunchTokenModal}>
                  {tokenConfigurationProcesses?.length? 'RELAUNCH TOKEN': 'LAUNCH TOKEN'}
                </Button>
              </div>
            }

            <Table
              columns={columns}
              dataSource={tokenConfigurationProcesses}
              rowKey='_id'
              pagination={false}
            />
          </Card>
          }
          </>
        }
      </Col>

    </Row>


    <Modal
      title={'Relanch token'}
      okText='Confirm'
      cancelText='Cancel'
      visible={isRelaunchTokenModalVisible}
      onOk={()=>relaunchTokenForm.submit()}
      onCancel={()=>{setIsRelaunchTokenModalVisible(false); relaunchTokenForm.resetFields()}}
    >


      {!tokenConfigurationProcesses?.find(process => !process.isTokenSaleClosed)?

        <>
          {/* {availableRegs.length &&  */}
            {/* <> */}
              <p>
                Select the new Regulation for your Token
              </p>

              <Form form={relaunchTokenForm} labelAlign='right' onFinish={(formValue)=>{history.push(`/token-configurations/new-config/type-and-details?relaunchRegulation=${formValue.relaunchRegulation}`)}}>
                <Form.Item
                  name="relaunchRegulation"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required'
                    }
                  ]}
                >
                  <Radio.Group>
                    {sharedService.regOptions.map(option => (
                      <React.Fragment key={option.value}>
                        <Radio value={option.value} style={regulationRadioStyle}>{option.name}</Radio>
                      </React.Fragment>
                    ))}
                  </Radio.Group>
                </Form.Item>

              </Form>
            {/* </> */}
          {/* } */}

          {/* {!availableRegs.length &&
            <h3>No available Regulations</h3>
          } */}

        </>

        :

        <p>
          You have to close the Token Sale first
        </p>


      }
    </Modal>
    <Modal
        title="Close Token Sale"
        visible={!!isCloseSaleModalVisible}
        okText="Yes"
        cancelText="No"
        onCancel={() => {
          setIsCloseSaleModalVisible(false);
          setSelectedTokenConfigurationProcess(undefined);
        }}
        onOk={() => {
          setIsCloseSaleModalVisible(false);
          setIsDelegationModalVisible(true);
        }}
    >
      <p>Are you sure you want to close the Token Sale?</p>
    </Modal>
    <TxFeeDelegationModal
        isVisible={isDelegationModalVisible}
        onSubmit={({ delegate }) => {
          setIsDelegationModalVisible(false);
          closeSale({ delegate }).then();
        }}
        onCancel={() => setIsDelegationModalVisible(false)}
    />

    <TransactionModal
        title={"Token Sale"}
        transactions={transactions}
        isModalVisible={isModalVisible}
        closeModal={() => {
          setIsModalVisible(false);
          refreshList();
        }}
    />

  </>
  );

}
