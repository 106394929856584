import { APIResponse, User} from '../../Shared/interfaces';
import { environment } from '../../../environments/environment';
import axios from 'axios';
import { SharedService } from '../../Shared/Shared.service';



const sharedService = new SharedService();


export class ApproversAndAccountsService {
  private APIURL = environment.APIURL;
  

  async addApprovers(req: {approversIds: string[]}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/masterCompanySuperAdmin/approversAndAccounts/addApprovers`, req, {headers: await sharedService.getAuthHeader()});
  }

  async getApprovers() {
    return axios.get<any, APIResponse>(`${this.APIURL}/masterCompanySuperAdmin/approversAndAccounts/getApprovers`, {params: {}, headers: await sharedService.getAuthHeader()});
  }

  async getNonApproverIssuers() {
    return axios.get<any, APIResponse>(`${this.APIURL}/masterCompanySuperAdmin/approversAndAccounts/getNonApproverIssuers`, {params: {}, headers: await sharedService.getAuthHeader()});
  }

  async removeApprover(req: {approverId: string}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/masterCompanySuperAdmin/approversAndAccounts/removeApprover`, req, {headers: await sharedService.getAuthHeader()});
  }

  async addCryptoAccount(req: {accountName: string, accountAddress: string}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/masterCompanySuperAdmin/approversAndAccounts/addCryptoAccount`, req, {headers: await sharedService.getAuthHeader()});
  }

  async getCryptoAccounts() {
    return axios.get<any, APIResponse>(`${this.APIURL}/masterCompanySuperAdmin/approversAndAccounts/getCryptoAccounts`, {params: {}, headers: await sharedService.getAuthHeader()});
  }




}