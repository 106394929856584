import axios from 'axios';
import { environment } from '../../environments/environment';
import { APIResponse } from '../Shared/interfaces';
import { SharedService } from '../Shared/Shared.service';

const sharedService = new SharedService();


export class MasterCompanyCounselSer {

  private APIURL = environment.APIURL;
  async abc(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/masterCompanyCounsel/reviewDueDiligence`,data,{headers:await sharedService.getAuthHeader()});
  }

  public uploadFile(file: Blob, progressCb?, errorCb?, completeCb?) {
    const req = new FormData();
    req.append('doc', file);

    sharedService.uploadDocument(
      `${this.APIURL}/masterCompanyCounsel/uploadDocument`,
      req,
      progressCb,
      errorCb,
      completeCb
    );
  }

  async submitAllDocuments(form: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/masterCompanyCounsel/submitAllDocuments`,
      form,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getAllDocuments(userId:any) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/masterCompanyCounsel/getAllDocuments/${userId}`,
      { headers: await sharedService.getAuthHeader()}
    );
  }
}
