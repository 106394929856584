import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Row,
  Card,
  Col,
  Form,
  message,
  notification,
  Progress,
  Spin
} from "antd";
import { MasterCompanyCounselSer } from './../MasterCompanyCounsel.service';
import { SharedService } from '../../Shared/Shared.service';
import {AuthService} from "../../Shared/Auth.service";

const masterCompanyCounselService = new MasterCompanyCounselSer();
const sharedService = new SharedService();
const useUserContext = () => new AuthService().useUserContext();

export default () => {
  const [submitting, setSubmitting] = useState(true);
  const [form] = Form.useForm();
  const [documentSubmitted, setDocumentSubmitted] = useState(false);
  const [counselFiles, setCounselFiles] = useState(
    {
      certificateFormationFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      articleFormationFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      taxIDFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      executedOperatingFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      generalLiabilityFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      generalBusinessFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      realEstateFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      }
    }
  );
  const [uploadFiles, setUploadFiles] = useState();
  const {userInfo} = useUserContext();

  useEffect(() => {
    (async () => {
      setSubmitting(true);
      const response = await masterCompanyCounselService.getAllDocuments(userInfo._id);
      //console.log(userInfo);
      let data = response.data;
      if(data && data.allDocumentSubmitted == true){
        setUploadFiles(response.data);
        setDocumentSubmitted(true);
      }
      setSubmitting(false);
    })();
  }, [documentSubmitted]);

  const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 }
  }
};

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 14,
        offset: 10
      }
    }
  };
  const uploadFile = (e, key) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    const fileType = sharedService.getFileType(file.name);

    setCounselFiles((prev) => ({ [key]: { URL: null, ...prev[key] }, ...prev }));

    masterCompanyCounselService.uploadFile(file, (uploadFilePercent) => {
      setCounselFiles((prev) => {
        console.log(uploadFilePercent);
        return ({ ...prev, [key]: { ...prev[key], uploading: true, uploadFilePercentage: uploadFilePercent } });
      });

    }, (err) => {
      console.error(err);
      setCounselFiles((prev) => ({ ...prev, [key]: { ...prev[key], uploading: false } }));

    }, async (response) => {

      if (response.success) {
        const URL = response.data.url;
        setCounselFiles((prev) => ({ ...prev, [key]: { ...prev[key], URL, uploading: false, name: file.name, type: fileType.toLowerCase() } }));
        form.setFieldsValue({ [key]: file.name });

      } else {
        console.error(response.error);
        setCounselFiles((prev) => ({ ...prev, [key]: { ...prev[key], uploading: false } }));
      }

    });

  }

  const submitUploadDocuments = async (_form) => {
    const form = { ..._form };
    console.log(counselFiles);
    form.certificateFormationFile = {
      name: counselFiles.certificateFormationFile?.name,
      URL: counselFiles.certificateFormationFile?.URL,
      type: counselFiles.certificateFormationFile.type,
    };
    form.articleFormationFile = {
      name: counselFiles.articleFormationFile?.name,
      URL: counselFiles.articleFormationFile?.URL,
      type: counselFiles.articleFormationFile.type,
    };
    form.taxIDFile = {
      name: counselFiles.taxIDFile?.name,
      URL: counselFiles.taxIDFile?.URL,
      type: counselFiles.taxIDFile.type,
    };
    form.executedOperatingFile = {
      name: counselFiles.executedOperatingFile?.name,
      URL: counselFiles.executedOperatingFile?.URL,
      type: counselFiles.executedOperatingFile.type,
    };
    form.generalLiabilityFile = counselFiles.generalLiabilityFile?.URL ? {
      name: counselFiles.generalLiabilityFile?.name,
      URL: counselFiles.generalLiabilityFile?.URL,
      type: counselFiles.generalLiabilityFile.type,
    } : null;
    form.generalBusinessFile = counselFiles.generalBusinessFile?.URL ? {
      name: counselFiles.generalBusinessFile?.name,
      URL: counselFiles.generalBusinessFile?.URL,
      type: counselFiles.generalBusinessFile.type,
    } : null;
    form.realEstateFile = counselFiles.realEstateFile?.URL ? {
      name: counselFiles.realEstateFile?.name,
      URL: counselFiles.realEstateFile?.URL,
      type: counselFiles.realEstateFile.type,
    } : null;

    form.uid = userInfo._id;

    console.log(form);
    setSubmitting(true);

    const response = await masterCompanyCounselService.submitAllDocuments({
      ...form,
      creationTS: new Date().getTime()
    });

    if (response.success) {
      const response = await masterCompanyCounselService.getAllDocuments(userInfo._id);
      let data = response.data;
      if(data && data.allDocumentSubmitted == true){
        setUploadFiles(response.data);
        setDocumentSubmitted(true);
      }
    } else {
      message.error(response.error.message);
      notification.open({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
    }

    setSubmitting(false);

  }
return(
  <>
  {submitting &&
        <div style={{textAlign:'center'}}>
          <br/><br/>
          <Spin size='large'/>
        </div>
      }
  {!documentSubmitted && !submitting && (<Row justify="center">
    <Col span={16}>
      <h2 style={{ color: 'darkblue', fontSize: '20px', marginTop: '20px', fontWeight: 'bold' }}>Offering Document</h2>
      <Form {...formItemLayout} labelAlign="left" form={form} initialValues={{ boardResolution: { option: 'upload' }, additionInfo: null }} onFinish={submitUploadDocuments}>
          <div>
            <Card>
              <Form.Item
                label="Certificate of Formation"
                name='certificateFormationFile'
                rules={[
                  {
                    required: true,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'certificateFormationFile') }} />
                  {counselFiles.certificateFormationFile.uploadFilePercentage > 0 &&
                    <Progress percent={counselFiles.certificateFormationFile.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Article of Formation"
                name='articleFormationFile'
                rules={[
                  {
                    required: true,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'articleFormationFile') }} />
                  {counselFiles.articleFormationFile.uploadFilePercentage > 0 &&
                    <Progress percent={counselFiles.articleFormationFile.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="EIN Tax ID Documents"
                name='taxIDFile'
                rules={[
                  {
                    required: true,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'taxIDFile') }} />
                  {counselFiles.taxIDFile.uploadFilePercentage > 0 &&
                    <Progress percent={counselFiles.taxIDFile.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Executed Operating Aggreement"
                name='executedOperatingFile'
                rules={[
                  {
                    required: true,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'executedOperatingFile') }} />
                  {counselFiles.executedOperatingFile.uploadFilePercentage > 0 &&
                    <Progress percent={counselFiles.executedOperatingFile.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="General Liability Insurance"
                name='generalLiabilityFile'
                rules={[
                  {
                    required: false,
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'generalLiabilityFile') }} />
                  {counselFiles.generalLiabilityFile.uploadFilePercentage > 0 &&
                    <Progress percent={counselFiles.generalLiabilityFile.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="General Business Lisenses"
                name='generalBusinessFile'
                rules={[
                  {
                    required: false,
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'generalBusinessFile') }} />
                  {counselFiles.generalBusinessFile.uploadFilePercentage > 0 &&
                    <Progress percent={counselFiles.generalBusinessFile.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Real Estate Agent Lisense"
                name='realEstateFile'
                rules={[
                  {
                    required: false,
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'realEstateFile') }} />
                  {counselFiles.realEstateFile.uploadFilePercentage > 0 &&
                    <Progress percent={counselFiles.realEstateFile.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
            </Card>
          </div>

          <br /><br />
          <Form.Item {...tailFormItemLayout}>
            <Button size='large' style={{ marginLeft: '10px' }} danger htmlType="button">Cancel</Button>
            <Button size='large' style={{ marginLeft: '10px' }} type='primary' htmlType="submit" loading={submitting}>Submit</Button>
          </Form.Item>
          <br /><br />

      </Form>
    </Col>
  </Row>)}
  {documentSubmitted && !submitting && 
        <div>
          <Row justify="center">
            <Col span={20}>
              <Card>
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Certificate of Formation :</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}><a href={uploadFiles.certificateFormationFile?.URL} download={uploadFiles.certificateFormationFile?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.certificateFormationFile?.name}</a></sub>
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Article of Formation :</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}><a href={uploadFiles.articleFormationFile?.URL} download={uploadFiles.articleFormationFile?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.articleFormationFile?.name}</a></sub>
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>EIN Tax ID Documents: </sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}><a href={uploadFiles.taxIDFile?.URL} download={uploadFiles.taxIDFile?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.taxIDFile?.name}</a></sub>
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Executed Operating Aggreement: </sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}><a href={uploadFiles.executedOperatingFile?.URL} download={uploadFiles.executedOperatingFile?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.executedOperatingFile?.name}</a></sub>
                    </div>
                  </Col>
                </Row>
                <br/>
                {uploadFiles.generalLiabilityFile && (<Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>General Liability Insurance: </sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}><a href={uploadFiles.generalLiabilityFile?.URL} download={uploadFiles.generalLiabilityFile?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.generalLiabilityFile?.name}</a></sub>
                    </div>
                  </Col>
                </Row>)}
                <br/>
                {uploadFiles.generalBusinessFile &&(<Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>General Business Lisenses: </sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}><a href={uploadFiles.generalBusinessFile?.URL} download={uploadFiles.generalBusinessFile?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.generalBusinessFile?.name}</a></sub>
                    </div>
                  </Col>
                </Row>)}
                <br/>
                {uploadFiles.realEstateFile && (<Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Real Estate Agent Lisense: </sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}><a href={uploadFiles.realEstateFile?.URL} download={uploadFiles.realEstateFile?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.realEstateFile?.name}</a></sub>
                    </div>
                  </Col>
                </Row>)}
                <br/>
              </Card>              
            </Col>
          </Row>
        </div>
      }
  </>
)
};
