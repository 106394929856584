import React from 'react'
import loc from '../../assets/images/marketplace/Group 22401@2x.png'

import './MarketplacePropertyCard.scss'
import { environment } from '../../environments/environment'
import moment from 'moment';
import { SharedService } from '../../components/Shared/Shared.service';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import vector from "../../../src/assets/Group 22391.png"

const sharedService = new SharedService();

const formatAsDollar = (amount: number): string => {
    return `$${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

// Example usage:
const minimumInvestment = 1000000
 // Output: "$1,000,000"

const MarketplacePropertyCard = ({ propertyData, callFrom = '' }) => {


    return (
        <>
            <div className={`col-12 ${callFrom != 'createToken' ? 'col-xl-4 col-xxl-4 ' : ''} c mb-3 marketpropertycards`}
                onClick={() => { window.location.href = `/property-details?propertyId=${propertyData['_id']}` }}>
                <div className="card realestateCardWrapper p-0" style={{ borderRadius: "15px" }}>

                    <div className="card-body p-0 mb-3  d-flex flex-column justify-content-around">




                        <div
                            className="propertytypestrip"
                            style={
                                propertyData.projectedFundingDeadline
                                    ? new Date(propertyData.projectedFundingDeadline) > new Date()
                                        ? { position: "absolute", top: "0px", right: "29px", zIndex: 10 }
                                        : { position: "absolute", top: "0px", right: "29px", zIndex: 10 }
                                    : { position: "absolute", top: "0px", right: "29px", zIndex: 10 }
                            }
                        >
                            {
                                propertyData.projectedFundingDeadline
                                    ? new Date(propertyData.projectedFundingDeadline) > new Date()
                                        ? (
                                            <div style={{ position: "relative", width: "100%", height: "100%", top: "-7px" }}>
                                                <img src={vector} alt="Offering" style={{ width: "100%", height: "100%" }} />
                                                <span style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "55%",
                                                    transform: "translate(-50%, -50%)",
                                                    color: "#fff",
                                                    fontWeight: "bold",
                                                    fontSize: "12px"
                                                }}>
                                                    OFFERING
                                                </span>
                                            </div>
                                        )
                                        : (
                                            <div style={{ position: "relative", width: "100%",height: "100%", top: "-7px" }}>

                                                <img src={vector} alt="Fully Funded" style={{ width: "100%", height: "100%" }} />
                                                <span style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "55%",
                                                    transform: "translate(-50%, -50%)",
                                                    color: "#fff",
                                                    fontWeight: "bold",fontSize:"12px"

                                                }}>
                                                    FULLY FUNDED
                                                </span>
                                            </div>
                                        )
                                    : (
                                        <div style={{ position: "relative", width: "100%", height: "100%", top: "-7px" }}>

                                            < img src={vector} alt="Fully Funded" style={{ width: "100%", height: "100%" }} />
                                            <span style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "55%",
                                                transform: "translate(-50%, -50%)",
                                                color: "#fff",

                                                fontWeight: "bold",fontSize:"12px"

                                            }}>
                                                FULLY FUNDED
                                            </span>
                                        </div>
                                    )
                            }
                        </div>


                        <div className="businesscoverimage">
                            {/* <img src={propertyData['propertyImages'][0]?.URL} alt={propertyData['propertyImages'][0]?.name}></img> */}

                            <LazyLoadImage
                                alt={propertyData['propertyImages'][0]?.name}
                                effect="blur"
                                src={propertyData['propertyImages'][0]?.URL}

                            // use normal <img> attributes as props
                            />


                        </div>

                        <div className="header relative property-location-details">
                            <div className="businessname text-white">{propertyData?.propertyTitle}</div>
                            <div className="businesslocation">
                                <img src={loc} alt=""></img>
                                {`${propertyData?.propertyCity},  ${propertyData?.propertyAddress}`}</div>
                            {/* <div className="tag" style={{ right: '18px', top: '0px', backgroundColor: 'gray' }}>1 BHK
                                <div className="triangle"></div>
                            </div> */}
                        </div>
                        <div className='property-businessinfo'><div className="businessinfo">
                            <ul>
                                {console.log(propertyData)}
                                {propertyData.propertyType && <li>
                                    <span className="infolabel">Asset Type </span>
                                    <span className="infovalue">: {propertyData.propertyType[0]?.toUpperCase() + propertyData.propertyType?.slice(1)} {propertyData.commercialOption ? ' - ' + propertyData.commercialOption[0]?.toUpperCase() + propertyData.commercialOption?.slice(1) : ""} </span>
                                </li>
                                }
                                {propertyData.pricePerSqFT && <li>
                                    <span className="infolabel">Price Per(Sq.ft) </span>
                                    <span className="infovalue">: {environment.currencySign}{sharedService.formatIndianCurrency(propertyData.pricePerSqFT)}</span>
                                </li>
                                }
                                {propertyData.carpetArea && <li>
                                    <span className="infolabel">Carpet Area(Sq.ft)</span>
                                    <span className="infovalue">: {propertyData.carpetArea}</span>
                                </li>
                                }
                                {propertyData.builtUpArea && <li>
                                    <span className="infolabel">Built-up Area(Sq.ft)</span>
                                    <span className="infovalue">: {propertyData.builtUpArea}</span>
                                </li>
                                }

                                {
                                    <>
                                        {/* {propertyData.currentOccupancy && <li>
                                            <span className="infolabel">Current Occupancy</span>
                                            <span className="infovalue">{propertyData.currentOccupancy} %</span>
                                        </li>
                                        }
                                    {propertyData.marketOccupancy && <li>
                                            <span className="infolabel">Market Occupancy</span>
                                            <span className="infovalue">{propertyData.marketOccupancy} %</span>
                                        </li>
                                        } */}
                                        {propertyData.purchasedPrice && (
                                            <li>
                                                <span className="infolabel">Purchase Price</span>
                                                <span className="infovalue">: {formatAsDollar(propertyData.purchasedPrice)}</span>
                                            </li>
                                        )}
                                        {/* {propertyData.landmark && <li>
                                            <span className="infolabel">Landmark</span>
                                            <span className="infovalue">{propertyData.landmark}</span>
                                        </li>
                                        }
                                    {propertyData.propertyAge && <li>
                                            <span className="infolabel">Property Age</span>
                                            <span className="infovalue">{propertyData.propertyAge}</span>
                                        </li>
                                        } */}
                                    </>
                                }

                                {propertyData.minimumInvestment && (
                                    <li>
                                        <span className="infolabel">Minimum Investment</span>
                                        <span className="infovalue">: {formatAsDollar(propertyData.minimumInvestment)}</span>
                                    </li>
                                )}

                                {propertyData.targetedInvestorIRR && <li>
                                    <span className="infolabel">IRR</span>
                                    <span className="infovalue">: {propertyData.targetedInvestorIRR}%</span>
                                </li>}
                                {propertyData.pricePerToken&&
                                    <li>
                                        <span className="infolabel">Price Per Token</span>
                                        <span className="infovalue">:{sharedService.formatUSDCurrency(propertyData?.pricePerToken) || "NA"}</span>
                                    </li>
                                }

                                {/* {propertyData.targetedEquityMultiple && <li>
                                    <span className="infolabel">Equity Multiple</span>
                                    <span className="infovalue">{propertyData.targetedEquityMultiple}x</span>
                                </li>}
                                {/* {propertyData.yearOneCashOnCash &&<li>
                                    <span className="infolabel">Year 1 Cash on Cash</span>
                                    <span className="infovalue">{propertyData.yearOneCashOnCash}%</span>
                                </li>} l*/}
                                {/* {propertyData.stabilizedCashOnCash && <li>
                                    <span className="infolabel">Stabilized Cash on Cash</span>
                                    <span className="infovalue">{propertyData.stabilizedCashOnCash}%</span>
                                </li>}  */}

                                {/* {propertyData.assetProfile &&<li>
                                    <span className="infolabel">Asset Profile</span>
                                    <span className="infovalue">{propertyData.assetProfile}</span>
                                </li>} */}

                            {propertyData.projectedFundingDeadline && (
    <li>
        <span className="infolabel">Funding Deadline</span>
        <span className="infovalue">
            : {new Date(propertyData.projectedFundingDeadline) > new Date()
                ? moment(propertyData.projectedFundingDeadline).format('MMMM DD, YYYY')
                : "Expired"}
        </span>
    </li>
)}


                            </ul>
                            <button
                                className="btn btn-primary"
                                style={propertyData.projectedFundingDeadline
                                    ? new Date(propertyData.projectedFundingDeadline) > new Date()
                                        ? { background: "linear-gradient(90deg, #07AFA5 0%, #215374 100%)", width: "100%" }
                                        : {background: "linear-gradient(90deg, #07AFA5 0%, #215374 100%)", width: "100%" }
                                    : {background: "linear-gradient(90deg, #07AFA5 0%, #215374 100%)", width: "100%" }

                                }
                                onClick={() => { window.location.href = `/property-details?propertyId=${propertyData['_id']}` }}
                            >
                                View Property
                            </button>

                        </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default MarketplacePropertyCard