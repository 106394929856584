import React from 'react';
import { Spin } from 'antd';

  const Loader = () => (
    <div style={{
      top: 0,
      left: 0,
      width: '100vw',
      position: 'fixed',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      height: '100vh',
      zIndex: 9999,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
        <Spin size="large" />
    </div>
);

export default Loader