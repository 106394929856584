import axios from 'axios';
import { environment } from '../../environments/environment';
import { APIResponse } from '../Shared/interfaces';
import { SharedService } from '../Shared/Shared.service';

const sharedService = new SharedService();

export class AmendmentsService {
  private APIURL = environment.APIURL;


  async createAmendment(prop: {
    number: number;
    text: string;
    comments?: string
  },) {
    return axios.post<any, APIResponse>(`${this.APIURL}/amendments/createAmendment`, prop, {headers: await sharedService.getAuthHeader()});
  }

  async getAmendments() {
    return axios.get<any, APIResponse>(`${this.APIURL}/amendments/getAmendments`, {headers: await sharedService.getAuthHeader()});
  }

  async getSmart() {
    return axios.get<any, APIResponse>(`${this.APIURL}/amendments/getSmart`, {headers: await sharedService.getAuthHeader()});
  }

}