import React, { useState, useEffect } from 'react';
import { Tabs, Card, Row, Col, Typography, message, Button } from 'antd';
import { PlaySquareOutlined, ProjectOutlined } from '@ant-design/icons';
import { PlatformIssuerAdminService } from '../PlatformIssuerAdmin.service';
import { IssuerBasicInfoVerificationRequest } from '../../Shared/interfaces';
import './IssuerBasicInfoVerifRequests.scss';
import ReqTable from './ReqTable';
import Details from './Details';

const { TabPane } = Tabs;
const {Title} = Typography;


const platformIssuerAdminService = new PlatformIssuerAdminService();



export default () => {
  const [filter, setFilter] = useState({limit: 10, searchPage: 1, statuses: ['pending']});
  const [issuerBasicInfoVerifReqs, setIssuerBasicInfoVerifReqs] = useState<IssuerBasicInfoVerificationRequest[] | null>();
  const [selectedIssuerBasicInfoVerifReqs, setSelectedIssuerBasicInfoVerifReqs] = useState<IssuerBasicInfoVerificationRequest | null>();
  const [loadingMoreReqs, setLoadingMoreReqs] = useState<boolean>();
  const [canLoadMoreReqs, setCanLoadMoreReqs] = useState(true);

  useEffect(() => {
    (async () => {
      // await getIssuerBasicInfoVerifReqs(['pending']);

      const statuses = ['pending'];

      setIssuerBasicInfoVerifReqs(null);
    
      const searchPage = 1;
      setFilter((prev: any) => ({...prev, statuses, searchPage}));

      const response = await platformIssuerAdminService.getIssuerBasicInfoVerifReqs(statuses.toString(), filter.limit, 1);

      const reqs: IssuerBasicInfoVerificationRequest[] = response.data;


      setFilter((prev: any) => ({...prev, statuses, searchPage: searchPage + 1}));
      setIssuerBasicInfoVerifReqs([...reqs]);
      setCanLoadMoreReqs(reqs.length === filter.limit);
      
    })();
  }, [filter.limit]);


  const getIssuerBasicInfoVerifReqs = async(statuses: string[]) => {
    setIssuerBasicInfoVerifReqs(null);
    
    const searchPage = 1;
    setFilter((prev: any) => ({...prev, statuses, searchPage}));

    const response = await platformIssuerAdminService.getIssuerBasicInfoVerifReqs(statuses.toString(), filter.limit, searchPage);

    const reqs: IssuerBasicInfoVerificationRequest[] = response.data;


    setFilter((prev: any) => ({...prev, statuses, searchPage: searchPage + 1}));
    setIssuerBasicInfoVerifReqs([...reqs]);
    setCanLoadMoreReqs(reqs.length === filter.limit);
  }


  const loadMoreReqs = async() => {
    if(loadingMoreReqs || !issuerBasicInfoVerifReqs) return;

    setLoadingMoreReqs(true);

    const response = await platformIssuerAdminService.getIssuerBasicInfoVerifReqs(filter.statuses.toString(), filter.limit, filter.searchPage);

    const reqs: IssuerBasicInfoVerificationRequest[] = response.data;

    setFilter((prev: any) => ({...prev, searchPage: prev.searchPage + 1}));
    setIssuerBasicInfoVerifReqs(prev => [...prev as any, ...reqs]);
    setLoadingMoreReqs(false);
    setCanLoadMoreReqs(reqs.length === filter.limit);
  }


  const reviewBasicInfoVerifReq = async(status: 'verified' | 'rejected', comments: string) => {

    setSelectedIssuerBasicInfoVerifReqs((prev:any) => ({...prev, [status === 'rejected'? 'rejecting' : 'verifiying']: true}));

    const response = await platformIssuerAdminService.reviewBasicInfoVerifReq({requestId: selectedIssuerBasicInfoVerifReqs?._id as string,
      companyId: selectedIssuerBasicInfoVerifReqs?.companyId as string, status, comments});

    if (response.success) {
      setIssuerBasicInfoVerifReqs(prev => (prev?.filter(req => req._id !== selectedIssuerBasicInfoVerifReqs?._id)));
      hideDetails();
      message.success(status === 'verified'? 'Request verified successfuly' : 'Request rejected successfully');
      
    } else {
      message.error(response.error.message || response.error);
      // notification.error({
      //   message: 'Error',
      //   description: 'wewe',
      //   duration: 0,
      // });
      setSelectedIssuerBasicInfoVerifReqs((prev:any) => ({...prev, [status === 'rejected'? 'rejecting' : 'verifiying']: false}));
    }
  };


  const showDetails = async(issuerBasicInfoVerifReq: IssuerBasicInfoVerificationRequest) => {
    setSelectedIssuerBasicInfoVerifReqs({...issuerBasicInfoVerifReq});    
  }

  const hideDetails = () => {
    setSelectedIssuerBasicInfoVerifReqs(null);
  }


  return (
    <>
      <br/>
      <Row justify="center">
        <Col span={22} style={{textAlign:'center'}}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: 'left',
                color: '#1890ff',
                // fontSize: '30px',
                fontWeight: 'bold'
              }}
            >
            Issuer Basic Info Verification Requests
          </Title>

          <Tabs defaultActiveKey='1' style={{textAlign:'center'}} onChange = {(key) => getIssuerBasicInfoVerifReqs(key==='1'? ['pending'] :['verified', 'rejected'] )}>
          <TabPane
              tab={
                <span>
                  <PlaySquareOutlined/>
                  Pending Issuer Basic Info Verification Requests
                </span>
              }
              key='1'
            >
              <ReqTable data={issuerBasicInfoVerifReqs} loading={!issuerBasicInfoVerifReqs} statuses={filter.statuses} showDetails={showDetails}/>
              {issuerBasicInfoVerifReqs && canLoadMoreReqs && 
                  <Button loading={loadingMoreReqs} onClick={loadMoreReqs}>
                    Load more
                  </Button>
                }
            </TabPane>

            <TabPane
              tab={
                <span>
                  <ProjectOutlined/>
                  Approved/Rejected Issuer Basic Info Verification Requests
                </span>
              }
              key='2'
            >
              <ReqTable data={issuerBasicInfoVerifReqs} loading={!issuerBasicInfoVerifReqs} statuses={filter.statuses} showDetails={showDetails}/>
            </TabPane>

          </Tabs>
          </Card>

          <Details 
            record={selectedIssuerBasicInfoVerifReqs} 
            visible={!!selectedIssuerBasicInfoVerifReqs}
            reviewBasicInfoVerifReq={reviewBasicInfoVerifReq}
            onCancel={hideDetails}/>
        </Col>
      </Row>
    </>
  );
}