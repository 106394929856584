import React, { useState, useEffect } from 'react';
import CardHOC from "../../CardHOC";
import {AuthService} from "../../Shared/Auth.service";
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import {Button, Form, Input, message, Modal, notification, Spin, Table} from "antd";
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const issuerSuperAdminService = new IssuerSuperAdminService();
const useUserContext = () => new AuthService().useUserContext();

export default function() {
  const {userInfo} = useUserContext();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [invites, setInvites] = useState([]);
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [inviteNo, setInviteNo] = useState(0);
  const [modalForm] = Form.useForm();
  const [series, setSeries] = useState();
  const [seriesId, setSeriesId] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fetchInvites = async (page = 1, size = 10) => {
    if (!userInfo) return;

    setLoading(true);
    const response = await issuerSuperAdminService.getInvites(userInfo._id, page.toString(), size.toString());
    if (response.data) {
      const sortedInvites = response.data.invites.sort((a, b) => b.creationTs - a.creationTs);
      const invitesWithSerial = sortedInvites.map((invite, index) => ({
        ...invite,
        serialNo: (currentPage - 1) * pageSize + index + 1,
      }));
      setInvites(invitesWithSerial);
      setInviteNo(invitesWithSerial.length + 1);
      setSeries(response.data.seriesName);
      setSeriesId(response.data.seriesId);
      setTotalCount(response.data.totalCount);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInvites(currentPage, pageSize);
  }, [userInfo, isInviteModalVisible, currentPage, pageSize]);

  const openInviteModal = () => {
    modalForm.resetFields();
    setIsInviteModalVisible(true);
  }

  const inviteInvestors = async(formValue) => {
    if (!userInfo?._id) {
      message.error('User information is not available. Please try again.');
      return;
    }
    setLoading(true);

    const response = await issuerSuperAdminService.inviteInvestor({
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      email: formValue.email,
      inviteNo: inviteNo,
      seriesId: seriesId,
      userId: userInfo?._id
    });

    if(response.success) {
      notification.success({
        message: 'Success',
        description: 'Invite sent to Investor Successfully'
      });
      setIsInviteModalVisible(false);
      const updatedResponse = await issuerSuperAdminService.getInvites(userInfo._id);
      if (updatedResponse.data) {
        const sortedInvites = updatedResponse.data.invites.sort((a, b) => b.creationTs - a.creationTs);
        const invitesWithSerial = sortedInvites.map((invite, index) => ({
          ...invite,
          serialNo: (currentPage - 1) * pageSize + index + 1,
        }));
        setInvites(invitesWithSerial);
        setInviteNo(invitesWithSerial.length + 1);
      }
    } else {
      console.error(response.error);
      message.error(response.error.message);
    }

    setLoading(false);
  }

  const seriesName = (value) => `Crypto Asset Rating ${value}`;

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const columns = [
    {
      title: 'Invites Number',
      dataIndex: 'serialNo',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      render: (value)=>value
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      render: (value)=>value
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      render: (value)=>value
    },
    {
      title: 'CREATED ON',
      dataIndex: 'creationTs',
      render: (value: number) => moment(value).format('lll')
    }
  ];

  return (
    <CardHOC
      heading={'Invites List'}
      component={<>

        {loading &&
          <div style={{textAlign:'center'}}>
            <br/><br/>
            <Spin size='large'/>
          </div>
        }

        {!loading &&
          <>
            <div style={{textAlign:'right', marginBottom: '10px'}}>
              <Button type="primary" size='large' onClick={() => openInviteModal()}>
                Invite Investors
              </Button>
            </div>

            <Table
              columns={columns}
              dataSource={invites}
              rowKey='_id'
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalCount,
                showSizeChanger: true,
              }}
              onChange={handleTableChange}
            />
          </>
        }

        <Modal
          title={`Invite Investor to ${series}`}
          okText='SUBMIT'
          cancelText='CANCEL'
          visible={isInviteModalVisible}
          onOk={()=> modalForm.submit()}
          onCancel={()=>{
            setIsInviteModalVisible(false);
          }}
          okButtonProps={{
            loading: loading,
          }}
        >

        <Form
          form={modalForm}
          autoComplete={'off'}
          onFinish={inviteInvestors}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          labelAlign="left"
        >
          <Form.Item
            name={'firstName'}
            label={'First Name'}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input placeholder={'First Name'}/>
          </Form.Item>

          <Form.Item
            name={'lastName'}
            label={'Last Name'}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input placeholder={'Last Name'}/>
          </Form.Item>
          <Form.Item
            name={'email'}
            label={'Email'}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input placeholder={'Email'}/>
          </Form.Item>
        </Form>

        </Modal>
      </>}
    />
  );
}