import React from 'react'
import OuterHeader from '../header/OuterHeader'
import mpStyle from './MarketplaceHeader.module.scss'
import MarketPlaceHeaderMenu from './MarketPlaceHeaderMenu'

const MarketplaceHeader = (props:any) => {

  return (
    <>
    {/* className={mpStyle.uMpHeader} */}
      <div  style={{backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), ${props.img}`}}>  
            <OuterHeader />
        <div className={mpStyle.marketplaceIntro}>
            <h1 style={{fontSize:"115px",textAlign:"center",color:'#FFE1BC',textShadow:' 5px 7px 31px #000'}}>{props.header}</h1>
            <p style={{fontSize:"54px",width:"65%",textAlign:"center",lineHeight:'66px',textShadow:' 5px 7px 31px #000'}}>{props.subtitle}</p>
        </div>
        {/* commenting for now  on request from soha */}
        {/* {props.view == 'list' && <MarketPlaceHeaderMenu/>} */}
    </div>
    </>
  )
}

export default MarketplaceHeader