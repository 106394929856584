import React, { useState, useEffect } from 'react';
import { SecurityTokenRegistryService } from '../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service'
import { AuthService } from '../../Shared/Auth.service';
import { InvestorService } from '../Investor.service';
import {
  Divider,
  Card,
  Modal,
  Table,
  Button,
  Input, Spin, Typography, Form, notification,
} from 'antd';
import {MetamaskService} from "../../Shared/Metamask.service";
import MainFacet from '../../Shared/SecurityToken/Facets/MainFacet';
import WhitelistFacet from "../../Shared/SecurityToken/Facets/WhitelistFacet";
import {RegLaunched, WhitelistedWallet} from "../../Shared/interfaces";
import BigNumber from "bignumber.js";
import {SharedService} from "../../Shared/Shared.service";
import TransactionModal from "../../Shared/TransactionModal/TransactionModal";
import ERC1410Facet from "../../Shared/SecurityToken/Facets/ERC1410Facet";
import ERC20Facet from "../../Shared/SecurityToken/Facets/ERC20Facet";
import moment from "moment";
import { useHistory } from "react-router-dom";

const { Title } = Typography;

const authService = new AuthService();
const investorService = new InvestorService();
const useUserContext = () => authService.useUserContext()
const securityTokenRegistryService = new SecurityTokenRegistryService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();
const sharedService = new SharedService();

const mainFacet = new MainFacet();
const whitelistFacet = new WhitelistFacet();
// const generalTransferManagerFacet = new GeneralTransferManagerFacet();
const erc1410Facet = new ERC1410Facet();
const erc20Facet = new ERC20Facet();

export default function TokenTransfer() {

  const { userInfo } = useUserContext();
  const { selectedWallet, networkId } = useSelectedWalletContext();
  const [isAddTokenModalVisible, setIsAddTokenModalVisible] = useState(false);
  const [isBalanceModalVisible, setIsBalanceModalVisible] = useState(false);
  const [isTokenTransferVisible, setIsTokenTransferVisible] =
    useState<boolean>();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [userRegisteredTokens, setUserRegisteredTokens] = useState<any[]>([]);
  const [balance, setBalance] = useState<string>('');
  const [whitelistedWallet, setWhitelistedWallet] = useState<WhitelistedWallet>();
  const [loadingWallet, setLoadingWallet] = useState<boolean>(true);
  const [selectedUserRegisteredToken, setSelectedUserRegisteredToken] = useState<{tokenSymbol: string, contractAddress: string, name: string, decimals: number}>();

  const [form] = Form.useForm();

  const [partitions, setPartitions] = useState<string[]>();
  const [partitionBalances, setPartitionBalances] = useState<string[]>();
  const [partitionInfos, setPartitionInfos] = useState<RegLaunched[]>();
  const [selectedPartition, setSelectedPartition] = useState<string>();
  const metamaskService = new MetamaskService();
  const history = useHistory();
  const [loadingCanTransfer, setLoadingCanTransfer] = useState<boolean>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transactions, setTransactions] = useState<
    { submitting?: boolean; receipt?: any; details: string }[]
    >([]);

  useEffect(() => {
    (async () => {
      if (!userInfo || !selectedWallet || !selectedUserRegisteredToken) return;

      setLoadingWallet(true);

      const [
        _balance,
        _whitelistedWallet,
        _partitions
      ] = await Promise.all([
        erc20Facet.balanceOf(selectedUserRegisteredToken.contractAddress, selectedWallet),
        whitelistFacet.getWhitelistedWallet(
          selectedUserRegisteredToken.contractAddress,
          selectedWallet
        ),
        erc1410Facet.partitionsOf(
          selectedUserRegisteredToken.contractAddress,
          selectedWallet
        )
      ]);

      const [_partitionBalances, _partitionInfos] = await Promise.all([
        Promise.all(
          _partitions.map(_partition => erc1410Facet.balanceOfByPartition(
            selectedUserRegisteredToken.contractAddress,
            _partition,
            selectedWallet
          ))
        ),
        Promise.all(
          _partitions.map(_partition => mainFacet.getPartitionInfo(
            selectedUserRegisteredToken.contractAddress,
            _partition,
          ))
        )
      ]);

      setBalance(_balance);
      setWhitelistedWallet(_whitelistedWallet);

      setPartitions(_partitions);
      setPartitionBalances(_partitionBalances);
      setPartitionInfos(_partitionInfos);


      setLoadingWallet(false);

    })();
  }, [userInfo, selectedWallet, selectedUserRegisteredToken]);



    const userRegisteredTokensColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Symbol',
      dataIndex: 'tokenSymbol',
      key: 'tokenSymbol',
    },
    {
      title: 'Address',
      dataIndex: 'contractAddress',
      key: 'contractAddress',
      render: (value) =>  <a
        style={{ textDecoration: 'underline' }}
        href={`${
          sharedService.etherscanURL[networkId as string]
        }/address/${value}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {value}
      </a>
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => <Button onClick={() => openBalanceModal(record)}> View </Button>
    }
  ];

  const balanceColumns = [
    {
      title: 'Partition',
      dataIndex: 'partition',
      render: (value: string) => sharedService.bytes32ToString(value),
    },

    {
      title: 'Regulation',
      dataIndex: 'regulation',
      render: (value: string) => sharedService.regOptions.find(
        (opt) => opt.shortValue === value
      )?.name,
    },
    {
      title: 'Launched on',
      dataIndex: 'dateLaunched',
      render: (value: string) => moment(+value * 1000).format('LLL'),
    },
    {
      title: 'Type of Security',
      dataIndex: 'typeOfSecurity',
      render: (value: string) => `${sharedService.typeOfSecurityName(value)}`,
    },
    {
      title: `Amount (${selectedUserRegisteredToken?.tokenSymbol})`,
      dataIndex: 'amount',
      render: (value: string) => `${displayTokenAmount({amount: value, decimals: selectedUserRegisteredToken?.decimals as number})}`,
    },
    {
      title: 'Action',
      render: (value, row) => (
        <Button onClick={() => openTokenTransferModal(row)} type="primary">
          TRANSFER
        </Button>
      ),
    },
  ];

  const openTokenTransferModal = async (row: {partition: string}) => {
    setSelectedPartition(row.partition);
    setIsTokenTransferVisible(true);
  };


  const displayTokenAmount = (prop: {amount: string, decimals: number}) => {
    return new BigNumber(prop.amount)
      .times(
        new BigNumber(10).pow(
          -(prop.decimals)
        )
      )
      .toFixed();
  };

  const openAddTokenModal = () => {
    setError('');
    setTokenSymbol('');
    setIsAddTokenModalVisible(true);
  };

  const addUserRegisteredToken = async () => {
    setLoading(true)

    if (tokenSymbol.length === 0) {
      setError('Token symbol cannot be empty')
      setLoading(false)
      return;
    }

    const result = await securityTokenRegistryService.getSymbolDetailsAndSTData(tokenSymbol);

    if (result.securityTokenData.contractAddress === "0x0000000000000000000000000000000000000000") {
      setError('Token do not exist')
      setLoading(false)
      return;
    }

    for (let i = 0; i < userRegisteredTokens.length; i++) {
      if (userRegisteredTokens[i].tokenSymbol === tokenSymbol) {
        setError('Token already exist')
        setLoading(false)
        return;
      }
    }

    const response = await investorService.addUserRegisteredToken({
      tokenSymbol,
      contractAddress: result.securityTokenData.contractAddress,
      name: result.securityTokenData.name,
      isDeployed: result.symbolDetails.isDeployed,
      isReserved: result.symbolDetails.isReserved,
      expiryDate: result.symbolDetails.expiryDate,
      registrationDate: result.symbolDetails.registrationDate,
      userId: userInfo?._id,
      creationTS: userInfo?.creationTS,
      decimals:  +result.securityTokenData.decimals
    })

    setUserRegisteredTokens(response.data);
    setLoading(false);
    setIsAddTokenModalVisible(false);
  };

  const closeAddTokenModal = () => {
    if (loading) return;
    setError('');
    setTokenSymbol('');
    setIsAddTokenModalVisible(false);
  };


  // Implement Transfer Here
  const openBalanceModal = (record) => {
    form.resetFields();
    setSelectedUserRegisteredToken(record);
    setIsBalanceModalVisible(true);
  };

  const closeBalanceModal = () => {
    setIsBalanceModalVisible(false);
  }

  const updateTokenSymbol = (e) => {
    if (e.target.value.length !== 0) {
      setError('');
    }
    setTokenSymbol(e.target.value)
  }

  useEffect(() => {
    (
      async () => {
        if(!metamaskService.isMetamaskInstalled) {
          handleConnectWallet();
        }
        setLoading(true);
        const response = await investorService.getUserRegisteredTokens({
          userId: userInfo?._id,
        });
        setUserRegisteredTokens(response.data);
        setLoading(false);
      }
    )();
  }, [userInfo?._id]);

  const handleConnectWallet = () => {
    history.push(
        "/connect-wallet"
    );
  };
  const transferByPartition = async (formValue) => {
    const _to = formValue.to;
    const _value = new BigNumber(formValue.amount)
      .times(new BigNumber(10).pow(selectedUserRegisteredToken?.decimals as number))
      .decimalPlaces(0)
      .toFixed();

    setLoadingCanTransfer(true);

    const canTransfer = await erc1410Facet.canTransferByPartition(
      selectedUserRegisteredToken?.contractAddress as string,
      selectedWallet as string,
      _to,
      selectedPartition as string,
      _value,
    );

    console.log('canTransfer:', canTransfer);

    setLoadingCanTransfer(false);

    if (canTransfer[0] !== '0x51') {
      notification.error({
        message: 'Token Transfer error',
        description: sharedService.bytes32ToString(canTransfer[1]),
      });
      return;
    }

    setIsModalVisible(true);
    setIsBalanceModalVisible(false);

    setTransactions([{ details: 'Sending funds', submitting: true }]);

    try {
      const receipt = await erc1410Facet.transferByPartition(
        selectedUserRegisteredToken?.contractAddress as string,
        selectedWallet as string,
        selectedPartition as string,
        _to,
        _value,
      );


      if (receipt.status) {
        let [
          _balance,
          _partitions
        ] = await Promise.all([
          erc20Facet.balanceOf(
            selectedUserRegisteredToken?.contractAddress as string,
            selectedWallet as string
          ),
          erc1410Facet.partitionsOf(
            selectedUserRegisteredToken?.contractAddress as string,
            selectedWallet as string
          )
        ]);

        const [_partitionBalances, _partitionInfos] = await Promise.all([
          Promise.all(
            _partitions.map(_partition => erc1410Facet.balanceOfByPartition(
              selectedUserRegisteredToken?.contractAddress as string,
              _partition,
              selectedWallet as string
            ))
          ),
          Promise.all(
            _partitions.map(_partition => mainFacet.getPartitionInfo(
              selectedUserRegisteredToken?.contractAddress as string,
              _partition as string,
            ))
          )
        ]);

        setBalance(_balance);
        setPartitions(_partitions);
        setPartitionBalances(_partitionBalances);
        setPartitionInfos(_partitionInfos);

      }


      setTransactions((prev) => {
        const _prev = [...prev].map((obj) => ({ ...obj }));
        _prev[0].receipt = receipt;
        return _prev;
      });

    } catch (err) {
      console.error(err);
    }

    setTransactions((prev) => {
      const _prev = [...prev].map((obj) => ({ ...obj }));
      _prev[0].submitting = false;
      return _prev;
    });
  };


  return (
    <>
      <Card bordered={true} title={
        <Divider
          orientation="left"
          style={
            {
              textAlign: 'left',
              color: '#1890ff',
              fontSize: '15px',
              fontWeight: 'bold',
            }
          }>
          Registered Tokens
        </Divider>
      }>
        <Button style={
          {
            float: 'right',
            marginBottom: '15px'
          }
        }
          onClick={openAddTokenModal}
          type="primary">
          Add Token
        </Button>
        <Table
          loading={loading}
          dataSource={userRegisteredTokens}
          columns={userRegisteredTokensColumns}
          pagination={false}
        />
      </Card>

      <Modal
        title="Add Token"
        visible={isAddTokenModalVisible}
        onCancel={closeAddTokenModal}
        onOk={addUserRegisteredToken}
        footer={
          [
            <Button key="cancelSubmit" onClick={closeAddTokenModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={addUserRegisteredToken}>
              Add
            </Button>
          ]
        }
      >
        <h4>
          <b>
            Enter token symbol to add
          </b>
        </h4>
        <Input onChange={updateTokenSymbol} size="large" placeholder="Example : JUN24" />
        <label style={
          {
            color: 'red'
          }
        }>
          {error}
        </label>
      </Modal>


      <Modal
        title={
          <>
            Transfer Token: {' '}
            <span style={{fontWeight: 'bold'}}>
              {selectedUserRegisteredToken?.name} ({selectedUserRegisteredToken?.tokenSymbol})
            </span>
          </>
        }
        // okText="TRANSFER"
        cancelText="CANCEL"
        // onOk={() => form.submit()}
        okButtonProps={{
          // loading: loadingCanTransfer,
          style: {display: 'none'}
        }}
        visible={isBalanceModalVisible}
        onCancel={closeBalanceModal}
        width={'fit-content'}
      >

        {loadingWallet && (
          <div style={{ textAlign: 'center' }}>
            <br />
            <br />
            <Spin size="large" />
          </div>
        )}

        {!loadingWallet &&
          <>
            <div style={{textAlign: 'center'}}>
              <Title level={4}>
                Balance: {' '}
                {displayTokenAmount({amount: balance, decimals: selectedUserRegisteredToken?.decimals as number})} {' '}
                {selectedUserRegisteredToken?.tokenSymbol}
              </Title>

              {whitelistedWallet?.isWhiteListed &&
                <>
                  <p style={{color: 'green', fontWeight: 'bold'}}>
                    Selected Metamask Wallet is Whitelisted
                  </p>

                  {/*{regLaunched &&*/}
                  {/*  <p>*/}
                  {/*    Whitelisted for: {' '}*/}
                  {/*    {sharedService.assetName(regLaunched)}*/}
                  {/*  </p>*/}
                  {/*}*/}
                </>
              }

              {!whitelistedWallet?.isWhiteListed &&
                <p style={{color: 'red', fontWeight: 'bold'}}>
                  Selected Metamask Wallet is not Whitelisted
                </p>
              }

              <Table
                columns={balanceColumns}
                rowKey="partition"
                dataSource={partitions?.map((partition, index) => ({
                  partition,
                  regulation: partitionInfos?.[index].regulation,
                  dateLaunched: partitionInfos?.[index].dateLaunched,
                  typeOfSecurity: partitionInfos?.[index].typeOfSecurity,
                  amount: partitionBalances?.[index],
                }))}
                scroll={{x: true}}
                pagination={false}
                summary={() => {
                  return (
                    <>
                      <tr>
                        <th> </th>
                        <th> </th>
                        <th> </th>
                        <th> </th>
                        <th>
                          Total = {' '}
                          {displayTokenAmount({amount: balance, decimals: selectedUserRegisteredToken?.decimals as number})} {selectedUserRegisteredToken?.tokenSymbol}
                          {/*<br />*/}
                        </th>
                      </tr>
                    </>
                  );
                }}
              />


            </div>
          </>
        }
      </Modal>


      <Modal
        title={`
        ${
          selectedPartition &&
          `Transfer of Regulation with Partition ${sharedService.bytes32ToString(selectedPartition)}`
        }
        
        `}
        okText="TRANSFER"
        cancelText="Cancel"
        visible={isTokenTransferVisible}
        onOk={() => form.submit()}
        okButtonProps={{
          loading: loadingCanTransfer,
        }}
        onCancel={() => {
          setIsTokenTransferVisible(false);
          form.resetFields();
        }}
      >

          <Form
            form={form}
            autoComplete={'off'}
            onFinish={transferByPartition}
          >
            <Form.Item
              wrapperCol={{ span: 18, offset: 3 }}
              name="to"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                  whitespace: true,
                },
                {
                  validator: (rule, value) => {
                    if (value && !sharedService.isEthereumAddress(value))
                      return Promise.reject('Enter a valid Address');
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder="Receiver" />
            </Form.Item>

            <Form.Item
              wrapperCol={{ span: 18, offset: 3 }}
              name="amount"
              normalize={(value: any, prevValue: any) => {
                if (new BigNumber(value).isGreaterThan(0)) return value;
                if (!value) return '';
                return prevValue || '';
              }}
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="Amount" />
            </Form.Item>

            <br />
          </Form>
      </Modal>


      <TransactionModal
        title={
          transactions[0]?.details === 'Updating Price'
            ? 'Price update'
            : 'Token Transfer'
        }
        transactions={transactions}
        isModalVisible={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
      />

    </>
  );
}
