import React, { useState, useEffect, useRef } from "react";
import {
  Result,
  Card,
  Form,
  Col,
  Radio,
  Input,
  Button,
  Spin,
  notification,
} from "antd";
import { AuthService } from "../../Shared/Auth.service";
import { InvestorService } from "../Investor.service";
import eversign from "./Eversign";
import "./nda.css";

const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const investorService = new InvestorService();

export default () => {
  const { userInfo, setUserInfo } = useUserContext();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [ndaSigned, setNdaSigned] = useState(false);
  const [ndaDetails, setNdaDetails] = useState(false);
  const [openEversign, setOpenEversign] = useState(false);
  const [signURL, setSignURL] = useState(null);
  const [accInvestor, setAccInvestor] = useState("false");
  const [repaint, setRepaint] = useState(false);
  const [isMandatory, setIsMandatory] = useState(false);
  const ndaRef = useRef(null);
  const [showNDA, setShowNDA] = useState(false);
  const [isDisable,setIsDisable] = useState(false);
  const [disableFinancialFields, setDisableFinancialFields] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    const data = {
      // netWorth: userInfo?.netWorth,
      // annualIncome: userInfo?.annualIncome,
      accreditedInvestor: userInfo?.accreditedInvestor,
    };
    console.log(data,"data")
    if(data && data.accreditedInvestor){
      setIsDisable(true)
    }

    form.setFieldsValue(data);

    if (userInfo?.status?.ndaSigned === true) {
      setNdaSigned(true);
      return;
    }
    
    if (userInfo?.questionnaire === true || userInfo?.accreditedInvestorUser === false) {
        setNdaDetails(true);
        setShowNDA(true);
      }
      
      
      (async () => {
        if (userInfo?.accreditedInvestor === "true"){ 
          setAccInvestor("true");
          setDisableFinancialFields(false);
        };
        const response = await investorService.getNDASignURL(userInfo);
        await setSignURL(response.data);
      })();
    }, [repaint,userInfo]);

    const disableButton = () => {
      setIsDisable(false);
      setDisableFinancialFields(false);
    }

  const onSign = () => {
    acceptTandC();
    setNdaSigned(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };

  useEffect(() => {
    if (signURL && !ndaSigned && ndaDetails) {
      setOpenEversign(true);
      setShowNDA(true);
      const embedSignDivision = document.getElementById("embedSignDivision");

      // Ensure the element exists before calling eversign.open
      if (embedSignDivision) {
        eversign.open({
          url: signURL,
          containerID: "embedSignDivision",
          width: 750,
          height: 800,
          events: {
            loaded: () => console.log("loading successful"),
            signed: onSign,
            error: () => console.log("EVERSIGN ERROR"),
            declined: () => console.log("declined"),
          },
        });
      } else {
        console.log("embedSignDivision not found");
      }
    }
  }, [signURL, ndaSigned, ndaDetails]);

  const register = async (formValue) => {
    try {
      console.log(formValue);
      const req = { ...formValue };
      req.userId = userInfo._id;
      req.ndaDetails = true;
      req.accreditedInvestor = accInvestor;
      req.questionnaire = true;
      setIsDisable(true);
      setSubmitting(true);
      let res = await investorService.submitNdaDetails(req);
      if(res.success){
        setNdaDetails(true);
        setIsSubmitted(true);
       setShowNDA(true);
       setRepaint((p) => !p);

       form.setFieldsValue({
        // annualIncome: res.data.annualIncome,
        // netWorth: res.data.netWorth,
        accreditedInvestor: res.data.accreditedInvestor,
      });

       setTimeout(() => {
        if (ndaRef.current) {
          const yOffset = -200; // Adjust this value to stop 100px before the element
          const yPosition = ndaRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: yPosition, behavior: 'smooth' });
        }
      }, 300);
      notification.success({
        message: "Success",
        description: "Questionnaire Saved Successfully!",
      });
      setTimeout(() => {
        window.location.reload();
      }, 300);
      
      }
      setSubmitting(false);

      // Redirect to NDA using useRef
    } catch (error) {
      notification.error("Something went wrong try again!");
      console.log(error);
    }
  };

  // ACCEPT T AND C STARTS
  const acceptTandC = async () => {
    try {
      const response = await investorService.setNDASigned({
        email: userInfo.email,
      });
      if (response.success && response.data) {
        userInfo.status.ndaSigned = true;
        setNdaSigned(true)
        notification.success({
          message: "Success",
          description: "NDA Submitted Successfully!",
        });
      }
      window.location.reload();
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Something went wrong!",
      });
      console.log(error);
    }
  };
  // ACCEPT T AND C ENDS

  return (
    <>
      {submitting && (
        <div style={{ textAlign: "center" }}>
          <br />
          <Spin size="large" />
        </div>
      )}

      {!submitting && ndaSigned && (
        <Card style={{ margin: "20px 50px", padding: "10px 40px" }}>
          <div className="alert alert-success p-5" role="alert">
            <span style={{ fontSize: "17px", fontWeight: "bold" }}>
              You have successfully signed Investor NDA Agreement. You can
              continue to investment <a href={"dashboard"}>here</a>!
            </span>
          </div>
        </Card>
      )}

      {!submitting && !isSubmitted && (
        <Card style={{ margin: "20px 50px", padding: "20px 40px" }} >
        <div hidden={userInfo?.accreditedInvestor === "true" || userInfo?.accreditedInvestorUser === false }>
          <h1 style={{ textAlign: "center" }}>Investor Questionnaire</h1>
          <h4
            style={{
              fontStyle: "oblique",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            This information is needed for us to comply with SEC and stable
            securities regulations. We ask the following questions to determine
            if the amount you may invest is limited by law.
          </h4>

          <Form
            form={form}
            onFinish={register}
            style={{ margin: "2% 10% 0 10%", width: "80%" }}
          >
            <h4 style={{ fontWeight: "bold", textAlign: "justify" }}>
            An “Accredited Investor” is, 
            <br/>
            <br/>
            - an individual with a net worth or joint net worth with a spouse or spousal equivalent of at least $1 million, not including the value of his or her primary residence
            <br/>
            <br/>
            - an individual with income exceeding $200,000 in each of the two most recent calendar years or joint income with a spouse or spousal equivalent exceeding $300,000 for those years and a reasonable expectation of the same income level in the current year
            </h4>
            <Form.Item
              name="accreditedInvestor"
              rules={[
                {
                  message:
                    // "Please input whether you are an Accredited Investor or not!",
                    "Please select Agree to proceed!",
                  whitespace: true,
                  required: true,
                },
              ]}
            >
              <Col style={{ textAlign: "center" }}>
                <Radio.Group  value={accInvestor} 
                   onChange={(e) => {
                      setAccInvestor("true");
                      setIsMandatory(true);
                      setDisableFinancialFields(false);
                   }}>
                  <Radio
                    key="true"
                    value="true"
                    style={{ fontSize: "18px" }}
                  >
                    Agree
                  </Radio>
                  {/* <Radio
                    key="false"
                    value="false"
                    disabled={isDisable}
                    style={{ fontSize: "18px" }}
                    onClick={() => {
                      setAccInvestor("false");
                      setIsMandatory(false);
                      setDisableFinancialFields(true);
                      // Resetting both annualIncome and netWorth fields
                      form.resetFields(['annualIncome', 'netWorth']);
                    }}
                  >
                    No
                  </Radio> */}
                </Radio.Group>
              </Col>
            </Form.Item>

            {/* <h4
              style={{
                fontStyle: "oblique",
                fontWeight: "bold",
                textAlign: "justify",
              }}
            >
              The law limits the total amount you can invest based on your
              annual income and your net worth. Please provide these so that we
              may determine if the amount you wish to invest is within these
              limitations.
            </h4>

            <Form.Item
              label="Annual Income"
              name="annualIncome"
              rules={[
                {
                  required: isMandatory,
                  message: "Please input your Annual Income!",
                  whitespace: true,
                },
              ]}
              style={{ fontSize: "18px" }}
              
            >
              <Input
                type="number"
                addonAfter="USD"
                style={{ fontSize: "18px" }}
                defaultValue={userInfo.annualIncome}
                disabled={isDisable || disableFinancialFields}
              />
            </Form.Item>

            <Form.Item
              label="Net Worth"
              name="netWorth"
              rules={[
                {
                  required: isMandatory,
                  message: "Please input your Net Worth!",
                  whitespace: true,
                },
              ]}
              style={{ fontSize: "18px" }}
            >
              <Input
                type="number"
                addonAfter="USD"
                style={{ fontSize: "18px" }}
                defaultValue={userInfo.netWorth}
                disabled={isDisable || disableFinancialFields}
              />
            </Form.Item> */}
            <br />
            {/* {isDisable && ( */}
              <Button
                type="primary"
                htmlType="submit"
                loading={submitting}
                size="large"
                style={{ marginLeft: "350px" }}
              >
                Submit
              </Button>
            {/* )} */}
          {/* {isDisable && (
              <Button
                type="primary"
                onClick={() => disableButton()}
                size="large"
                style={{ marginLeft: "350px" }}
              >
                Edit
              </Button>
            )} */}
          </Form>
          </div>
          <br />
          {/* NDA View Starts Here */}
          {showNDA && (
            <div ref={ndaRef} id="embedSignDivision" hidden={userInfo?.status?.ndaSigned === true}>
              {!ndaSigned && (
                <h2 style={{ color: "blue", fontSize: "24px" }}>NDA</h2>
              )}
            </div>
          )}
          {/* NDA View ends here */}
        </Card>
      )}
    </>
  );
};
