import React, { useState, useEffect } from 'react';
import CardHOC from "../../CardHOC";
import {Form, Input, InputNumber, Button, Spin, notification} from 'antd';
import {AuthService} from "../../Shared/Auth.service";
import { useHistory } from 'react-router-dom';
import {AmendmentsService} from "../Amendments.service";


const amendmentsService = new AmendmentsService();


const useUserContext = () => new AuthService().useUserContext();


export default function AmendmentNew() {
  const {userInfo} = useUserContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>();
  const history = useHistory();

  const [form] = Form.useForm();


  useEffect(() => {
    (async () => {
      if(!userInfo) return;

      setLoading(false);

    })();
  }, [userInfo]);


  const createAmendment = async(formValue) => {
    setSubmitting(true);

    const response = await amendmentsService.createAmendment(formValue);

    if(response.success) {
      notification.success({
        message: 'Success',
        description: 'Amendment created successfully',
      });
      history.push(`/amendments`);
    }

    setSubmitting(false);
  }

  return (
    <CardHOC
      heading={'Create Amendment'}
      component={<>

        {loading &&
        <div style={{textAlign:'center'}}>
          <br/><br/>
          <Spin size='large'/>
        </div>
        }

        {!loading &&
        <>
          <Form
            form={form}
            autoComplete={'off'}
            labelAlign={'left'}

            onFinish={createAmendment}>

            <Form.Item
              name={'number'}
              label={'Amendment Number'}
              labelCol={{span: 4}}
              wrapperCol={{span: 10, pull: 2}}
            >
              <InputNumber/>
            </Form.Item>

            <Form.Item
              name={'text'}
              label={'Amendment Text'}
              labelCol={{span: 4}}
              wrapperCol={{span: 10, push: 2}}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input.TextArea/>
            </Form.Item>

            <Form.Item
              name={'comments'}
              label={'Comments'}
              labelCol={{span: 4}}
              wrapperCol={{span: 10, push: 2}}
            >
              <Input.TextArea/>
            </Form.Item>

            <div style={{ textAlign: 'left' }}>
              <Button
                style={{marginRight: '10px'}}
                type="primary"
                htmlType={'submit'}
                loading={submitting}
              >
                CREATE
              </Button>
              <Button
                style={{marginRight: '10px'}}
                onClick={() => history.push(`/amendments`)}
              >
                CANCEL
              </Button>
            </div>

          </Form>

        </>

        }


      </>}
    />
  );

}