import React, { useState, useEffect } from 'react';
import { User } from '../../Shared/interfaces';
import { Card, Row, Col, Typography, Button } from 'antd';
// import { PlatformIssuerAdminService } from '../PlatformIssuerAdmin.service';
// import { SharedService } from '../../Shared/Shared.service';
import IssuerTable from './IssuerTable';
import { PlatformIssuerAdminService } from '../PlatformIssuerAdmin.service';

const {Title} = Typography


const platformIssuerAdminService = new PlatformIssuerAdminService();
// const sharedService = new SharedService();



export default () => {
  const [filter, setFilter] = useState({limit: 10, searchPage: 1});
  const [issuerSuperAdmins, setIssuerSuperAdmins] = useState<User[] | null>();
  const [loadingMoreIssuers, setLoadingMoreIssuers] = useState<boolean>();
  const [canLoadMoreIssuers, setCanLoadMoreIssuers] = useState(true);

  useEffect(() => {
    (async () => {
      const response = await platformIssuerAdminService.getUsersByRole('issuer super admin', filter.limit, 1);
      const users: User[] = response.data;
      
      setIssuerSuperAdmins(users);
      setFilter((prev: any) => ({...prev, searchPage: prev.searchPage + 1}));
      setCanLoadMoreIssuers(users.length === filter.limit);
    })();
  }, [filter.limit]);

  const loadMoreIssuers = async() => {
    if(loadingMoreIssuers || !issuerSuperAdmins) return;

    setLoadingMoreIssuers(true);

    const response = await platformIssuerAdminService.getUsersByRole('issuer super admin', filter.limit, filter.searchPage);

    const moreUsers: User[] = response.data;

    setIssuerSuperAdmins(prev => [...prev as any, ...moreUsers]);
    setLoadingMoreIssuers(false);
    setFilter((prev: any) => ({...prev, searchPage: prev.searchPage + 1}));
    setCanLoadMoreIssuers(moreUsers.length === filter.limit);
  }

  
  return (
    <>
     <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            List of all Property Company Managers
          </Title>

          <IssuerTable data={issuerSuperAdmins} loading={!issuerSuperAdmins}/>
          <br/>
          {issuerSuperAdmins && canLoadMoreIssuers && 
            <Button loading={loadingMoreIssuers} onClick={loadMoreIssuers}>
              Load more
            </Button>
          }
        </Card>
      </Col>
     </Row>
    </>
  )
}