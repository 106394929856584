import { Button, Card, Col, Form, Radio, Row, Select, Spin, Table, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { TeamDepartment, TeamRole } from '../../../../Shared/interfaces';
import { IssuerSuperAdminService } from '../../../IssuerSuperAdmin.service';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const {Title} = Typography;
const { Option } = Select;

const issuerSuperAdminService = new IssuerSuperAdminService();


export default function ListRoleManagment () {

  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [teamDepartments, setTeamDepartments] = useState<TeamDepartment[]>();
  const [teamRoles, setTeamRoles] = useState<TeamRole[]>();
  const [loadingTeamRoles, setLoadingTeamRoles] = useState(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {

      const response = await issuerSuperAdminService.getTeamDepartments();
      const _teamDepartments: TeamDepartment[] = response.data;
      setTeamDepartments(_teamDepartments);
      setLoading(false);

    })();
  }, []);


  const columns = [
    {
      title: 'ROLE',
      dataIndex: 'name'
    },
    {
      title: 'CREATED ON',
      dataIndex: 'creationTS',
      render: (value: TeamRole['creationTS']) => moment(value).format('LLL'),
    },
    {
      title: 'ACTION',
      render: (value, record: TeamRole) => {
        return (
          <Button type="primary" onClick={() => history.push(`/issuer-super-admin/admin/role-managment/edit-role/${record._id}`)}>
            Edit
          </Button>
        );
      }
    }
  ];

  const selectTeamDepartments = async (departmentId: string) => {
    setTeamRoles(undefined);
    setLoadingTeamRoles(true);

    const response = await issuerSuperAdminService.getTeamRoles(departmentId);
    const _teamRoles: TeamRole[] = response.data;
    setTeamRoles(_teamRoles);

    setLoadingTeamRoles(false);
  }



  return (
  <>
    <br/><br/>
    <Row justify="center">
      <Col span={20}>
        {loading && 
          <div style={{textAlign:'center'}}>
            <Spin size='large'/>
          </div>
        }
        {!loading && 
          <Card>
             <Title level={1} style={{textAlign:'center'}}>Role Managment</Title>

             <Form labelAlign="left" initialValues={{team: 'employee'}} form={form}>

                <Form.Item
                  labelCol={{xs: { span: 24 }, sm: { span: 6, offset:4 }}}
                  wrapperCol={{xs: { span: 24 }, sm: { span: 10 }}}
                  label="Select The team"
                  name='team'
                  rules={[
                    {
                      required: true,
                      message: 'This field is required'
                    }
                  ]}>
                  <Radio.Group onChange={() => {form.resetFields(['department', 'role', 'departmentName']); setTeamRoles(undefined)}}>
                    <Radio key='employee' value='employee'>
                      Employee
                    </Radio>
                    <Radio key='contractor_consultant_advisor' value='contractor_consultant_advisor'>
                      Contractor/Consultant/Advisor
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item 
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.team !== currentValues.team}
                      >
                  {({ getFieldValue }) => (
                    <Form.Item 
                      style={{marginBottom:0}}
                      labelCol={{xs: { span: 24 }, sm: { span: 6, offset:4 }}}
                      wrapperCol={{xs: { span: 24 }, sm: { span: 10 }}}
                      label='Department name'
                      name="department"
                      validateStatus={teamDepartments? '' : 'validating'}
                      hasFeedback
                      help= {teamDepartments? '' : "Loading Departments..."}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        }
                      ]}>
                      <Select showSearch placeholder="Select Department" onChange={e => selectTeamDepartments(e as string)}>
                        {teamDepartments?.filter(teamDepartment => teamDepartment.team === getFieldValue('team')).map(teamDepartment => 
                          <Option key={teamDepartment._id} value={teamDepartment._id}>{teamDepartment.name}</Option>
                        )}
                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>
             </Form>
              
             <Table 
              scroll = {{x: true}}
              columns={columns} 
              dataSource={teamRoles}
              loading={!!loadingTeamRoles}
              rowKey='_id'
              pagination={false} 
              />
              <div style={{textAlign:'right'}}>
                <br/>
                <Button style={{marginRight:'5px'}} size='large' onClick={() => history.push(`/issuer-super-admin/admin/role-managment/add-role`)}>
                  ADD ROLE
                </Button>
              </div>
          </Card>
        }
      </Col>
    </Row>

  </>
  );
}