import React, {useState, useEffect} from "react"
import {Row, Col, message, Spin, Card, Select} from "antd";
import { TokenConfigurationService } from "../../TokenConfigurations/TokenConfiguration.service"
import whitepaper from "../../../assets/Documents/CAR Reg S Whitepaper.pdf"
import ppm from "../../../assets/Documents/CAR - PPM.pdf"
import { AuthService } from "../../Shared/Auth.service";

const tokenConfigurationService = new TokenConfigurationService()
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const { Option } = Select;

export default () => {

    const [tokenDocs, setTokenDocs] = useState()
    const [loading, setLoading] = useState();
    const [series, setSeries] = useState([]);
    const [response, setResponse] = useState([]);
    const { userInfo } = useUserContext();
    const [seriesId, setSeriesId] = useState(userInfo.seriesId);

    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                let res = await tokenConfigurationService.getTokenDocuments(userInfo.seriesId)
                const {error, data, success} = res
                if (success) {
                    setTokenDocs(data)
                } else {
                    message.error(error.message)
                }
            } catch (e) {
                console.log(e)
                message.error("Something Went Wrong While Fetching Token Documents")
            }
        })();
    }, [seriesId])

    useEffect(() => {
        (async () => {
	    setLoading(true)
            try {
                let res = await tokenConfigurationService.getAllTokenSeries();
                setResponse(res.data);
                let arr = [];
                res.data.forEach(elem => arr.push(elem));
                setSeries(arr);
            } catch (e) {
                console.log(e)
                message.error("Something Went Wrong While Fetching Token Documents")
            }
            setLoading(false)
        })()
    }, [])

    return (
        <Card>
        <h3><b>Token Documents</b></h3>
        {loading || !tokenDocs ? <Spin /> :
        <>
        <Select onSelect={(val)=>setSeriesId(val)} value={seriesId} style={{margin: '30px'}}>
            {series.length>0 && response.map((res)=>(
                <Option key={res.seriesId} value={res.seriesId}>{res.tokenSymbol}</Option>
            ))}
        </Select>
        <Row>
            <Col span={8} style={{ textAlign: "center" }}>
            	<b>Nature Of Business Document</b>
            </Col>
            <Col>
				<a target="#" href={tokenDocs.natureOfBusinessFile?.URL}>{tokenDocs.natureOfBusinessFile?.name}</a>
            </Col>
        </Row>
        <Row>
            <Col span={8} style={{ textAlign: "center" }}>
            	<b>Financial Statements</b>
            </Col>
            <Col>
				<a target="#" href={tokenDocs.financialStatementsFile?.URL}>{tokenDocs.financialStatementsFile?.name}</a>
            </Col>
        </Row>
        <Row>
            <Col span={8} style={{ textAlign: "center" }}>
            	<b>Identity Of Officers and Directors</b>
            </Col>
				<a target="#" href={tokenDocs.idOfOfficersAndDirectorsFile?.URL}>{tokenDocs.idOfOfficersAndDirectorsFile?.name}</a>
            <Col>
            </Col>
        </Row>
        <Row>
            <Col span={8} style={{ textAlign: "center" }}>
            	<b>Legend</b>
            </Col>
				<a target="#" href={tokenDocs.legendCreationFile?.URL}>{tokenDocs.legendCreationFile?.name}</a>
            <Col>
            </Col>
        </Row>
        <Row>
            <Col span={8} style={{ textAlign: "center" }}>
            	<b>Opinion Letter</b>
            </Col>
            <Col>
				<a target="#" href={tokenDocs.opinionLetterFile?.URL}>{tokenDocs.opinionLetterFile?.name}</a>
            </Col>
        </Row>
        <Row>
            <Col span={8} style={{ textAlign: "center" }}>
            	<b>PPM</b>
            </Col>
            <Col>
				<a target="#" href={ppm}>PPM</a>
            </Col>
        </Row>
        <Row>
            <Col span={8} style={{ textAlign: "center" }}>
            	<b>Whitepaper</b>
            </Col>
            <Col>
				<a target="#" href={whitepaper}>Whitepaper</a>
            </Col>
        </Row>
        </>
        }
        </Card>
    )
}
