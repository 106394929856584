import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  message,
  notification,
  Spin,
  Select,
  Table,
  Modal,
  Card,
} from "antd";
import { useHistory } from "react-router-dom";

import { InvestorService } from "../Investor/Investor.service";
import { AuthService } from "../Shared/Auth.service";
import WhitelistFacet from "../Shared/SecurityToken/Facets/WhitelistFacet";
import { SharedService } from "../Shared/Shared.service";
import { SecurityTokenRegistryService } from "../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";
import { MetamaskService } from "../Shared/Metamask.service";
import {
  SymbolDetailsAndSTData,
  WalletWhitelistRequest,
} from "../Shared/interfaces";
// import CardHOC from "../CardHOC";
import "./WhitelistedWalletRequests.css";
const { Option } = Select;

const investorService = new InvestorService();
const sharedService = new SharedService();
const securityTokenRegistryService = new SecurityTokenRegistryService();
const whitelistFacet = new WhitelistFacet();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

export default function WhitelistedWalletRequests() {
  const { userInfo } = useUserContext();
  const { selectedWallet, networkId } = useSelectedWalletContext();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenSymbol = queryParams.get("tokenSymbol");
  const [selectedToken, setSelectedToken] =
    useState<SymbolDetailsAndSTData["securityTokenData"]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState(false);
  const [tokens, setTokens] =
    useState<SymbolDetailsAndSTData["securityTokenData"][]>();
  const [isRequestModalVisible, setIsRequestModalVisible] = useState(false);
  const [selectedTokenSymbol, setSelectedTokenSymbol] = useState<
    string | undefined
  >(tokenSymbol || undefined);
  const [walletWhitelistRequests, setWalletWhitelistRequests] =
    useState<WalletWhitelistRequest[]>();
  const tokensRef = useRef(tokens);
  const [form] = Form.useForm();
  const [isAlignedLeft, setIsAlignedLeft] = useState(false);
  const metamaskService = new MetamaskService();
  const history = useHistory();

  // const [regulationsLaunched, setRegulationsLaunched] = useState<RegLaunched[]>();
  // const [loadingRegulationsLaunched, setLoadingRegulationsLaunched] = useState(true);

  useEffect(() => {
    (async () => {
      if (!userInfo || !selectedWallet) return;

      const _tokens =
        await securityTokenRegistryService.filterTokenSymbolsFromContract();
      setTokens(_tokens);

      if (tokenSymbol) {
        const foundToken = _tokens.find(
          (token) => token.symbol === tokenSymbol
        );
        if (foundToken) {
          setSelectedTokenSymbol(foundToken.symbol);
          await selectToken(foundToken.symbol);
        } else {
          message.error(`Token with symbol ${tokenSymbol} not found`);
          setSelectedTokenSymbol(undefined);
        }
      }

      setLoading(false);
    })();
  }, [userInfo, selectedWallet, tokenSymbol]);

  useEffect(() => {
    if (!selectedWallet) return;

    form.setFieldsValue({ wallet: selectedWallet });
  }, [selectedWallet]);

  useEffect(() => {
    tokensRef.current = tokens;
  }, [tokens]);

  const selectToken = async (tokenSymbol: string) => {
    // setLoadingRegulationsLaunched(true);
    const _selectedToken = tokensRef.current?.find(
      (token) => token.symbol === tokenSymbol
    );

    if (!_selectedToken) {
      message.error(`Token with symbol ${tokenSymbol} not found`);
      return;
    }

    const [reqRes] = await Promise.all([
      // mainFacet.getRegulationsLaunched(_selectedToken?.contractAddress as string),
      investorService.getWalletWhitelistRequests({
        tokenSymbol: _selectedToken?.symbol as string,
      }),
    ]);
    const _walletWhitelistRequests: WalletWhitelistRequest[] = reqRes.data;

    const whitelistedWallets = await Promise.all(
      _walletWhitelistRequests.map((req) =>
        whitelistFacet.getWhitelistedWallet(
          _selectedToken?.contractAddress as string,
          req.wallet
        )
      )
    );

    _walletWhitelistRequests.forEach((req) => {
      req["isWhiteListed"] = whitelistedWallets.find(
        (whitelistedWallet) =>
          whitelistedWallet.wallet.toLowerCase() === req.wallet.toLowerCase()
      )?.isWhiteListed;
    });

    setWalletWhitelistRequests(_walletWhitelistRequests);
    setSelectedToken(_selectedToken);
    // setRegulationsLaunched(_regulationsLaunched);
    // setLoadingRegulationsLaunched(false);
    setSelectedTokenSymbol(tokenSymbol);
  };

  const openRequestModal = async () => {
    setIsRequestModalVisible(true);
    form.setFieldsValue({ wallet: selectedWallet });
  };

  const requestWalletWhitelist = async (formValue) => {
    if (
      walletWhitelistRequests?.find(
        (req) => req.wallet === selectedWallet?.toLowerCase()
      )
    ) {
      message.error("Whitelist already requested");
      return;
    }

    setSubmitting(true);

    const response = await investorService.requestWalletWhitelist({
      ...formValue,
      tokenSymbol: selectedToken?.symbol as string,
      wallet: selectedWallet?.toLowerCase(),
      country: userInfo?.country,
      isAccredetedInvestor: (userInfo?.aiVerifiedCompany?.length as number) > 0,
    });

    if (response.success) {
      setWalletWhitelistRequests(response.data);
      setIsRequestModalVisible(false);

      notification.success({
        message: "Wallet saved successfully",
        description: "wait until it will be whitelisted",
      });
    } else {
      message.error("Something went wrong.Please try again");
    }

    setSubmitting(false);
  };

  const columns = [
    {
      title: "Wallet",
      dataIndex: "wallet",
      render: value => (
        <div className={isAlignedLeft ? "align-left" : "align-center"}>
          <a href={`https://etherscan.io/address/${value}`} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        </div>
      ),
    },
    // {
    //   title: 'Regulation',
    //   dataIndex: 'regulation',
    //   render: (value, record: WalletWhitelistRequest) => {
    //     const regLaunched = regulationsLaunched?.find(opt => opt.creationTS === record.assetCreationTS.toString());
    //
    //     return sharedService.regOptions.find(
    //       (regOp) =>
    //         regOp.shortValue === regLaunched?.regulation
    //     )?.name as string
    //   }
    // },
    // {
    //   title: 'Type Of Security',
    //   dataIndex: 'typeOfSecurity',
    //   render: (value, record: WalletWhitelistRequest) => {
    //     const regLaunched = regulationsLaunched?.find(opt => opt.creationTS === record.assetCreationTS.toString());
    //
    //     return sharedService.typeOfSecurityOptions.find(
    //       opt => opt.value === regLaunched?.typeOfSecurity
    //     )?.name
    //   }
    // },
    // {
    //   title: 'Common Stock',
    //   dataIndex: 'commonStock',
    //   render: (value, record: WalletWhitelistRequest) => {
    //     const regLaunched = regulationsLaunched?.find(opt => opt.creationTS === record.assetCreationTS.toString());
    //
    //     return sharedService.commonStockOptions.find(
    //       opt => opt.value === regLaunched?.commonStock
    //     )?.name
    //   }
    // },
    {
      title: "Whitelisted",
      dataIndex: "isWhiteListed",
      render: value => (
        <div className={isAlignedLeft ? "align-left" : "align-center"}>
          {value ? <span style={{ color: "green" }}>YES</span> : <span style={{ color: "orange" }}>NO</span>}
        </div>
      ),
    },
  ];
  const handleConnectWallet = () => {
    history.push(
        "/connect-wallet"
    );
  };

  // Simulate alignment change after component mounts
  useEffect(() => {
    if(!userInfo ) return;
    if(!metamaskService.isMetamaskInstalled) {
      handleConnectWallet();
    }
    // Change alignment to left after 2 seconds (for demonstration)
    setTimeout(() => {
      setIsAlignedLeft(true);
    }, 2000);
  }, []);

  return (
    <>
      <h3
        style={{
          color: "#000",
          fontSize: "32px",
          fontWeight: 700,
          margin: "19px 75px",
        }}
      >
        Whitelisted Wallets
      </h3>
      <Card style={{ width: "90%",height:"490px",marginLeft: "60px" }}>
        {loading && (
          <div style={{ textAlign: "center" }}>
            <br />
            <br />
            <Spin size="large" />
          </div>
        )}
        {!loading && (
          <>
          <div style={{display:"flex",alignItems:"center"}}>
          <div>
            <h3 className="whitelist_wallet_card_heading">Whitelisted Wallets</h3>
          </div>
            <div >
              <Select
                size="large"
                showSearch
                placeholder="Select Token"
                onChange={(e) => selectToken(e as string)}
                value={selectedTokenSymbol}
                style={{
                  borderColor: "#B6B6B6",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderRadius: "8px",
                  width: "235px",
                  marginLeft: "26px"
                }}
              >
                {tokens?.map((token) => (
                  <Option key={token.symbol} value={token.symbol}>
                    {token.name} ({token.symbol})
                  </Option>
                ))}
              </Select>
                </div>
                </div>

              {selectedToken && (
                <>
                  {!walletWhitelistRequests && (
                    <div style={{ textAlign: "center" }}>
                      <br />
                      <br />
                      <Spin size="large" />
                    </div>
                  )}

                  {walletWhitelistRequests && (
                    <>
                      <Table
                        columns={columns}
                        dataSource={walletWhitelistRequests}
                        pagination={false}
                        scroll={{ x: true }}
                        rowKey="_id"
                        className="walletWhitelistReq_table"
                      />
                      <div style={{ textAlign: "left" }}>
                        <Button
                          size="large"
                          type="primary"
                          htmlType="submit"
                          onClick={openRequestModal}
                          className="whitelist-wallet-button"
                        >
                          REQUEST WHITELIST
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
          </>
        )}

        <Modal
          title={"Request Whitelist"}
          visible={isRequestModalVisible}
          width={1000}
          cancelText="CANCEL"
          onCancel={() => {
            setIsRequestModalVisible(false);
            form.resetFields();
          }}
          okText={"SUBMIT"}
          onOk={() => form.submit()}
          okButtonProps={{
            loading: submitting,
          }}
        >
          {/*{loadingRegulationsLaunched &&*/}
          {/*  <div style={{textAlign:'center'}}>*/}
          {/*    <br/><br/>*/}
          {/*    <Spin size='large'/>*/}
          {/*  </div>*/}
          {/*}*/}

          {
            // !loadingRegulationsLaunched && regulationsLaunched &&
            <Form
              form={form}
              autoComplete={"off"}
              onFinish={requestWalletWhitelist}
            >
              <>
                <Form.Item
                  name={"wallet"}
                  label={"Select Wallet on Metamask"}
                  wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input
                    placeholder={"Select Wallet"}
                    disabled
                    style={{ color: "blue", fontWeight: "bold" }}
                    // @ts-ignore
                    // labelCol={{span: 24}}
                  />
                </Form.Item>

                {/*<Form.Item*/}
                {/*  name={'assetCreationTS'}*/}
                {/*  label={'Select Asset Type Launched'}*/}
                {/*  rules={[*/}
                {/*    {*/}
                {/*      required: true,*/}
                {/*      message: "This field is required",*/}
                {/*    },*/}
                {/*  ]}*/}
                {/*>*/}
                {/*  <Radio.Group>*/}
                {/*    {regulationsLaunched.map((regulationLaunched, index) => (*/}
                {/*      <Radio value={+regulationLaunched.creationTS} key={index}>*/}
                {/*        <span style={{ fontWeight: 'bold' }}>*/}
                {/*          {*/}
                {/*            sharedService.assetName(regulationLaunched)*/}
                {/*          }*/}
                {/*        </span>*/}
                {/*          {' - '}*/}
                {/*          Launched on: {moment(+regulationLaunched.dateLaunched * 1000).format('LL')}*/}
                {/*      </Radio>*/}
                {/*    ))}*/}
                {/*  </Radio.Group>*/}
                {/*</Form.Item>*/}
              </>
            </Form>
          }
        </Modal>
      </Card>
    </>
  );
}
