import React, { useEffect, useState } from 'react';
import { PropertyAdminService } from '../PropertyDetails/PropertyAdmin.service';
import './Disclaimer.css';
import MarketplacePropertyCard from '../../layout/marketplacePropertyCard/MarketplacePropertyCard';
import MarketPlaceSkelton from './MarketPlaceSkelton';

const propertyAdminService = new PropertyAdminService();

const MarketPlace = ({ searchTerm, minInvestment, eligibility, propertyType }) => {
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  
  useEffect(() => {
    window.scrollTo({ top: 0 });
    
    (async function loadProperties() {
      setLoading(true);
      
      try {
        const propertiesResponse = await propertyAdminService.getAllProperties();
        
        if (propertiesResponse && propertiesResponse.data?.length > 0) {
          setProperties(propertiesResponse.data);
         }
        
      } catch (error) {
        console.error('Error fetching properties:', error); // Log any error during the fetch
      }
      
      setLoading(false);
    })();
  }, []);

  // Filter logic
  const filteredProperties = properties.filter(property => {
    const meetsSearchTerm = searchTerm && (property?.propertyTitle || property?.location || property?.propertyCity)
      ? (
          property.propertyTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          property.location?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          property.propertyCity?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : true;
  
    const meetsMinInvestment = minInvestment && property?.minimumInvestment
      ? property.minimumInvestment >= parseInt(minInvestment)
      : true;
  
    // Use primaryPropertyType for the filter
    const meetsPropertyType = propertyType 
      ? (property?.primaryPropertyType || '').trim().toLowerCase() === propertyType.trim().toLowerCase() 
      : true;
  
    return meetsSearchTerm && meetsMinInvestment && meetsPropertyType;
  });
  
  return (
    <div className="wd_scroll_wrap margin-bt-0">
      {loading && <MarketPlaceSkelton />}
      
      {!loading && (
        <div className="container" style={{ marginTop: '150px', marginBottom: '30px' }}>
          <div className="row row-cols-auto">
            {filteredProperties.map(mp => (
              <MarketplacePropertyCard key={mp._id} propertyData={mp} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MarketPlace;
