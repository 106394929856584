import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Form, Input, Progress, Button, message, Spin, Result } from 'antd';
import { SharedService } from '../../Shared/Shared.service';
import { LinkOutlined } from '@ant-design/icons';
import { APIResponse, TokenConfigurationProcess, SymbolDetailsAndSTData, Rule144Config } from '../../Shared/interfaces';
import { IssuerCounselService } from '../IssuerCounsel.service';
import { SecurityTokenRegistryService } from '../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import { TokenConfigurationService } from '../../TokenConfigurations/TokenConfiguration.service';
import { useParams } from 'react-router-dom';

// import { SecurityTokenService } from '../../Shared/SecurityToken/SecurityToken.service';
import MainFacet from '../../Shared/SecurityToken/Facets/MainFacet';
import ERC1410Facet from "../../Shared/SecurityToken/Facets/ERC1410Facet";
import { userInfo } from 'os';

const {Title} = Typography;
const issuerCounselService = new IssuerCounselService();
const sharedService = new SharedService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();

// const securityTokenService = new SecurityTokenService();
const mainFacet = new MainFacet();
const erc1410Facet = new ERC1410Facet();
const tokenConfigurationService = new TokenConfigurationService();

export default () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [tokenConfigurationProcess, setTokenConfigurationProcess] = useState<TokenConfigurationProcess>();
  const [loading, setLoading] = useState<boolean>(true);
  const [legendRemoved, setLegendRemoved] = useState<boolean>();
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [monthUnit, setMonthUnit] = useState('');
  const [rule144Config, setRule144Config] = useState<Rule144Config>();

  const {tokenConfigurationProcessId} = useParams<{tokenConfigurationProcessId: string}>();

  const [filesObj, setFilesObj] = useState(
    {
      opinionLetterFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
    }
  );

  useEffect(() => {
    (async () => {
      const response = await tokenConfigurationService.getTokenConfigurationProcessById({tokenConfigurationProcessId});
      // const response = await tokenConfigurationService.getLastTokenConfigurationProcess();
      const _tokenConfigurationProcess: TokenConfigurationProcess = response.data;
      if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);
      const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);
      setTokenConfigurationProcess(_tokenConfigurationProcess);

      // if(_symbolDetailsAndSTData?.symbolDetails.isDeployed) {
      //   const [_legendRemoved, _monthUnit, _rule144ConfigRes] = await Promise.all([
      //     securityTokenService.legendRemoved(_symbolDetailsAndSTData.securityTokenData.contractAddress, _tokenConfigurationProcess.creationTS),
      //     securityTokenService.monthUnit(_symbolDetailsAndSTData.securityTokenData.contractAddress),
      //     tokenConfigurationService.getRule144Config(_tokenConfigurationProcess?.rule144ConfigId as string)
      //   ]);
      //   setLegendRemoved(_legendRemoved);
      //   setMonthUnit(_monthUnit);
      //   setRule144Config(_rule144ConfigRes.data);
      // }

      if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);

      const [_totalPartitions] = await Promise.all([
        erc1410Facet.totalPartitions(
          _symbolDetailsAndSTData.securityTokenData.contractAddress,
        )
      ]);

      const _partitionInfos = await Promise.all(
        _totalPartitions.map(_partition => mainFacet.getPartitionInfo(
          _symbolDetailsAndSTData.securityTokenData.contractAddress,
          _partition,
        ))
      );

      let _partition = '';

      for(let i = 0; i < _partitionInfos.length; i++) {
        // _partitionInfos[i]['partition'] = _totalPartitions[i];
        if (_partitionInfos[i].creationTS === _tokenConfigurationProcess.creationTS.toString()) _partition = _totalPartitions[i];
      }

      const [_legendRemoved, _monthUnit, _rule144ConfigRes] = await Promise.all([
        mainFacet.legendRemoved(_symbolDetailsAndSTData.securityTokenData.contractAddress, _partition),
        mainFacet.monthUnit(_symbolDetailsAndSTData.securityTokenData.contractAddress),
        tokenConfigurationService.getRule144Config(_tokenConfigurationProcess?.rule144ConfigId as string, "123454")
      ]);
      setLegendRemoved(_legendRemoved);
      setMonthUnit(_monthUnit);
      setRule144Config(_rule144ConfigRes.data);

      setLoading(false);
    })();
  }, []);



  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if(!e.target.files) return;

    const file =  e.target.files[0];
    console.log(file);

    if(!file) return;

    const fileType = sharedService.getFileType(file.name);

    setFilesObj(prev => {
      const current = sharedService.clone(prev);
      current[key].URL = null;
      return current;
    });

    issuerCounselService.uploadFile(file, (uploadFilePercent: number) => {
      setFilesObj(prev => {
        const current = sharedService.clone(prev);
        current[key].uploading = true;
        current[key].uploadFilePercentage = uploadFilePercent;
        return current;
      });

    }, (err) => {
      console.error(err);
      setFilesObj(prev => {
        const current = sharedService.clone(prev);
        current[key].uploading = false;
        return current;
      });

    }, async(response: APIResponse) => {

      if (response.success) {
        const URL = response.data.url;
        setFilesObj(prev => {
          const current = sharedService.clone(prev);
          current[key].URL = URL;
          current[key].uploading = false;
          current[key].name = file.name;
          current[key].type = fileType.toLowerCase()
          return current;
        });
        form.setFieldsValue({[key]: file.name});

      } else {
        console.error(response.error);
        setFilesObj(prev => {
          const current = sharedService.clone(prev);
          current[key].uploading = false;
          return current;
        });
      }

    });
  }

  const uploadOpinionLetter = async(formValue) => {
    const req = sharedService.clone(formValue);
    req.opinionLetterFile = {
      name: filesObj.opinionLetterFile?.name,
      URL: filesObj.opinionLetterFile?.URL,
      type: filesObj.opinionLetterFile.type,
    };

    req.tokenConfigurationProcessId = tokenConfigurationProcessId;

    setSubmitting(true);
    const response = await issuerCounselService.uploadOpinionLetter(req);

    if (response.success) {
      message.success('Opinion Letter uploaded successfully');

      setTokenConfigurationProcess(prev => {
        const current = sharedService.clone(prev) as TokenConfigurationProcess;
        current.opinionLetterFile = req.opinionLetterFile;
        return current;
      });

    } else {
      console.error(response.error);
      message.error(response.error);
    }

    setSubmitting(false);
  }



  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {loading &&
            <div style={{textAlign:'center'}}>
              <br/>
              <Spin size='large'/>
            </div>
          }

          {!loading &&
            <Card>
              <Title level={1} style={{textAlign:'center'}}>Remove Legend from Securities</Title>

              {!symbolDetailsAndSTData?.symbolDetails.isDeployed &&
                <Result
                  title={`Security Token not deployed`}
                />
              }

              {symbolDetailsAndSTData?.symbolDetails.isDeployed &&
                <Card bordered={false} className='card-shadow'>
                  <br/>
                  {
                    !tokenConfigurationProcess?.opinionLetterFile &&
                    (
                      !tokenConfigurationProcess?.isTokenSaleClosed //&&
                      // !(Date.now() - (tokenConfigurationProcess?.dateTokenSaleClosed as number) >= 12*(+monthUnit)*1000) ||
                      //(Date.now() - new Date(rule144Config?.filesFinancialDate as number).getTime() >= 12*(+monthUnit)*1000)
                    ) &&
                    <Result
                      title={`Property Company Token Admin has to close the Token Sale and upload latest documents in order to Remove the Legend`}
                      // title={`Property Company Token Admin has to close the Token Sale, upload latest documents and wait a 12 month period in order to Remove the Legend`}
                    />
                  }
                  {
                      !tokenConfigurationProcess?.opinionLetterFile &&
                      tokenConfigurationProcess?.isTokenSaleClosed &&
                      // (Date.now() - (tokenConfigurationProcess?.dateTokenSaleClosed as number) >= 12*(+monthUnit)*1000) &&
                    //  (Date.now() - new Date(rule144Config?.filesFinancialDate as number).getTime() < 12*(+monthUnit)*1000) &&
                      <Form labelAlign="left" form={form} onFinish={uploadOpinionLetter}>
                        <Form.Item
                            label="Upload the opinion Letter"
                            name='opinionLetterFile'
                            labelCol={{span: 14}}
                            wrapperCol={{span: 10}}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required'
                              }
                            ]}>
                          <>
                            <Input type='file' onChange={e => { uploadFile(e, 'opinionLetterFile') }} />
                            {filesObj.opinionLetterFile.uploadFilePercentage>0 &&
                                <Progress percent={filesObj.opinionLetterFile.uploadFilePercentage} />
                            }
                          </>
                        </Form.Item>

                        <div style={{textAlign:'right'}}>
                          <Button htmlType='submit' size='large' type='primary' loading={submitting}>
                            SUBMIT
                          </Button>
                        </div>

                      </Form>
                  }

                  {tokenConfigurationProcess?.opinionLetterFile &&
                    <div>
                      <a
                        href={tokenConfigurationProcess.opinionLetterFile?.URL}
                        download={tokenConfigurationProcess.opinionLetterFile?.name}
                        target="_blank"
                        rel="noopener noreferrer">
                        <LinkOutlined /> Opinion Letter uploaded. Click here to view it
                      </a>
                      <br/><br/>
                    </div>
                  }

                  {legendRemoved &&
                    <Result status='success' title={`Legend Removed`} subTitle='Users can now transfer funds'/>
                  }
                </Card>
              }

            </Card>
          }
        </Col>
      </Row>
    </>
);
}
