import React, { useState, useEffect } from "react";
import {
  Input,
  Card,
  Row,
  Col,
  Typography,
  Slider,
  Dropdown,
  Button,
  Space,
  InputNumber,
} from "antd";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import Dashboard from "./Dashboard";
import "./index.css";

const { Title } = Typography;

// PriceRangeSelector Component
const PriceRangeSelector = ({ minPrice, maxPrice, onPriceChange }) => {
  const handleSliderChange = (value) => {
    onPriceChange(value); // Use props to update parent state
    console.log(value);
  };

  const handleMinPriceChange = (value) => {
    if (value <= maxPrice) {
      onPriceChange([value, maxPrice]); // Update min price
    }
  };

  const handleMaxPriceChange = (value) => {
    if (value >= minPrice) {
      onPriceChange([minPrice, value]); // Update max price
    }
  };

  return (
    <Card
      style={{
        padding: "20px",
        border: "1px solid #f0f0f0",
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{display:"flex",margin:"5px"}}>
        <p>Minimum Investment</p>
        
      </div>
      <p
        style={{
          marginBottom: "16px",
          fontSize: "16px",
          fontWeight: 500,
          color: "#595959",
        }}
      >
        Price Range: $ {minPrice.toLocaleString()} to ${" "}
        {maxPrice.toLocaleString()}
      </p>
      <Row gutter={16} align="middle">
        <Col span={8}>
          <Slider
            range
            max={10000000}
            value={[minPrice, maxPrice]}
            onChange={handleSliderChange}
            handleStyle={[
              {
                backgroundColor: "#1890ff",
                borderColor: "#1890ff",
                width: "16px",
                height: "16px",
              },
            ]}
            trackStyle={{ backgroundColor: "#1890ff", height: "4px" }}
            railStyle={{ height: "4px", backgroundColor: "#d9d9d9" }}
          />
        </Col>
        <Col span={8}>
          <InputNumber
            min={0}
            max={10000000}
            value={minPrice}
            onChange={handleMinPriceChange}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{ width: "100%" }}
            placeholder="Min Price"
          />
        </Col>
        <Col span={8}>
          <InputNumber
            min={0}
            max={10000000}
            value={maxPrice}
            onChange={handleMaxPriceChange}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{ width: "100%" }}
            placeholder="Max Price"
          />
        </Col>
      </Row>
    </Card>
  );
};

// Main Index Component
const Index = () => {
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000000);
  const [searchValue, setSearchValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchValue);
  const [debouncedMinPrice, setDebouncedMinPrice] = useState(minPrice);
  const [debouncedMaxPrice, setDebouncedMaxPrice] = useState(maxPrice);
  const [propertyData, setPropertyData] = useState([]);

  // Debounce search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue);
    }, 2000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  // Debounce price range input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedMinPrice(minPrice);
      setDebouncedMaxPrice(maxPrice);
    }, 3000);

    return () => {
      clearTimeout(handler);
    };
  }, [minPrice, maxPrice]);

  // Dropdown menu content
  const priceRangeMenu = (
    <PriceRangeSelector
      minPrice={minPrice}
      maxPrice={maxPrice}
      onPriceChange={(newRange) => {
        setMinPrice(newRange[0]);
        setMaxPrice(newRange[1]);
      }}
    />
  );

  return (
    <>
      <Card style={{ backgroundColor: "#f5f5f5" }}>
        <Row justify="space-between" align="middle">
          <Col>
            <Title level={3} style={{ marginBottom: 0, fontWeight: "bold" }}>
              Marketplace Properties
            </Title>
          </Col>
          <Col>
            <Space size="middle">
              <Input
                placeholder="Search"
                allowClear
                size="middle"
                prefix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
                style={{
                  width: 250,
                  borderRadius: "8px",
                  borderColor: "#d9d9d9",
                  boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
                }}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />
              <Dropdown overlay={priceRangeMenu} trigger={["click"]}>
                <Button className="price-range-button">
                  Price Range <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          </Col>
        </Row>
      </Card>
      <Dashboard
        data={propertyData}
        searchValue={debouncedValue}
        minPrice={debouncedMinPrice}
        maxPrice={debouncedMaxPrice}
      />
    </>
  );
};

export default Index;
