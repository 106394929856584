import React, { useState } from 'react'
import MarketPlace from './MarketPlace'
import MarketplaceHeader from '../../layout/marketplaceHeader/MarketplaceHeader'
import MarketplaceHomeHeader from './MarketPlaceHomeHeader';

const MarketplaceMain = () => {
  // State management for filters
  const [searchTerm, setSearchTerm] = useState('');
  const [minInvestment, setMinInvestment] = useState('');
  const [eligibility, setEligibility] = useState('');
  const [propertyType, setPropertyType] = useState('');
  
  return (
    <div>
      {/* Pass filter states and setters as props */}
      <MarketplaceHomeHeader 
        searchTerm={searchTerm} 
        setSearchTerm={setSearchTerm}
        minInvestment={minInvestment} 
        setMinInvestment={setMinInvestment}
        eligibility={eligibility} 
        setEligibility={setEligibility}
        propertyType={propertyType} 
        setPropertyType={setPropertyType}
      />
      {/* Pass the filters down to MarketPlace for filtering */}
      <MarketPlace
        searchTerm={searchTerm}
        minInvestment={minInvestment}
        eligibility={eligibility}
        propertyType={propertyType}
      />
    </div>
  )
}

export default MarketplaceMain;
