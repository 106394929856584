import React, { useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import SunEditor from "suneditor-react";

const RichText = (props) => {
    const editorRef = useRef(null);
    const {key, richValue, handleEditorChange, field } = props
    // return (
    //     <div key={key} className="u-rich-editor" >
    //         <Editor
    //             disabled={false}
    //             onInit={(evt, editor) => editorRef.current = editor}
    //             apiKey='rmm68dmd9u9uyqq2nebfcowulnf2b21ez5n0kyq4abrhw3us'
    //             initialValue={richValue}//{richTextvalue}
    //             id={"richDescription-"+field}
    //             onEditorChange={(e) => handleEditorChange(e, field)}
    //             init={{
    //                 height: 300,
    //                 // width: 600,
    //                 menubar: false,
    //                 plugins: [
    //                     'advlist autolink lists link image charmap print preview anchor',
    //                     'searchreplace visualblocks code fullscreen',
    //                     'insertdatetime media table paste code help wordcount'
    //                 ],
    //                 toolbar: 'undo redo | formatselect | ' +
    //                     'bold italic backcolor | alignleft aligncenter ' +
    //                     'alignright alignjustify | bullist numlist outdent indent | ' +
    //                     'removeformat | help',
    //                 content_style: 'body { font-family:Poppins,sans-serif; font-size:14px }'
    //             }}
    //         />
    //     </div>
    // )

    return(
        <SunEditor setContents={richValue}  onChange={(e)=>handleEditorChange(e,field)} 
        setOptions={{
          height: 200,
          zindex:0,
          buttonList: [
            ['undo', 'redo', 'font', 'fontSize', 'formatBlock', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'fontColor', 'hiliteColor', 'indent', 'outdent', 'align', 'list', 'lineHeight', 'table', 'image', 'link', 'fullScreen', 'showBlocks'],
          ],
        }}
        />
    )
}

export default RichText