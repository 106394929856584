import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Button, Form, Radio, Input, Select, Checkbox, message } from 'antd';
import { TeamRole, TeamDepartment } from '../../../Shared/interfaces';
import { AuthService } from '../../../Shared/Auth.service';
import { useHistory } from 'react-router-dom';
import { SharedService } from '../../../Shared/Shared.service';
import { MasterCompanySuperAdminSer } from '../../masterCompanySuperAdmin.service';

const {Title} = Typography;
const { Option } = Select;


const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};



// const adminService = new AdminService();
const MasterCompanySuperAdminService = new MasterCompanySuperAdminSer();
const sharedService = new SharedService();

const useUserContext = () => new AuthService().useUserContext();

export default function AdminRoles() {
  const {userInfo} = useUserContext();

  const [form] = Form.useForm();

  const [teamRoles, setTeamRoles] = useState<TeamRole[] | null>();
  const [teamDepartments, setTeamDepartments] = useState<TeamDepartment[]>();

  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    (async () => {
      if(!userInfo) return;

      const response = await MasterCompanySuperAdminService.getTeamDepartments();
      const _teamDepartments: TeamDepartment[] = response.data;
      setTeamDepartments(_teamDepartments);

    })();
  }, [userInfo]);


  const selectTeamDepartments = async (departmentId: string) => {
    setTeamRoles(null);
    const response = await MasterCompanySuperAdminService.getTeamRoles(departmentId);
    const _teamRoles: TeamRole[] = response.data;
    setTeamRoles(_teamRoles);
  }

  const sendInviteToTeamMember = async (formValue) => {
    const req = sharedService.clone(formValue);
    console.log(req);
    setSubmitting(true);
    const response = await MasterCompanySuperAdminService.sendInviteToTeamMember(req);

    if (response.success) {
      message.success('Invite sent successfully');
      history.push(`/master-company-super-admin/admin/admin-roles`)
    } else {
      message.error(response.error.message);
    }
    setSubmitting(false);
  }

  
  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          <Card>
            <Title level={1} style={{textAlign:'center'}}>Add Admin</Title>

            <Form labelAlign="left" initialValues={{team: 'employee'}} form={form} onFinish={sendInviteToTeamMember}>
              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 10, offset: 0 }}}
                wrapperCol={{xs: { span: 24 }, sm: { span: 14 }}}
                label="Choose the Admin role to be created"
                name='adminRole'
                rules={[
                  {
                    required: true,
                    message: 'This field is required'
                  }
                ]}>
                <Radio.Group>
                  {sharedService.adminRolesOptions.map(role => (
                    <Radio style={radioStyle} key={role.value} value={role.value}>
                      {role.name}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>

              <Form.Item 
                labelCol={{xs: { span: 24 }, sm: { span: 10, offset: 0 }}}
                wrapperCol={{xs: { span: 24 }, sm: { span: 10 }}}
                label={`Enter email`}
                name="email"
                validateTrigger={'onBlur'}
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                    whitespace: true,
                  },
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!'
                  },
                ]}>
                <Input/>
              </Form.Item>

              <Form.Item noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.adminRole !== currentValues.adminRole}
              >

                {({ getFieldValue }) => (
                  (getFieldValue('adminRole') !== 'master company counsel' && getFieldValue('adminRole') !== 'Real Estate Marketplace Counsel')?
                    <>
                      <Form.Item
                        labelCol={{xs: { span: 24 }, sm: { span: 10, offset: 0 }}}
                        wrapperCol={{xs: { span: 24 }, sm: { span: 10 }}}
                        label="Select one"
                        name='team'
                        rules={[
                          {
                            required: true,
                            message: 'This field is required'
                          }
                        ]}>
                        <Radio.Group onChange={() => {form.resetFields(['department', 'teamRoleId']); setTeamRoles(null)}}>
                          <Radio key='employee' value='employee'>
                            Employee
                          </Radio>
                          <Radio key='contractor_consultant_advisor' value='contractor_consultant_advisor'>
                          Contractor/Consultant/Advisor
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
        
                      <Form.Item noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.team !== currentValues.team}
                      >
                        {({ getFieldValue }) => (
                          <Form.Item 
                            style={{marginBottom:0}}
                            labelCol={{xs: { span: 24 }, sm: { span: 10, offset: 0 }}}
                            wrapperCol={{xs: { span: 24 }, sm: { span: 10 }}}
                            label='Department name'
                            name="department"
                            validateStatus={teamDepartments? '' : 'validating'}
                            hasFeedback
                            help= {teamDepartments? '' : "Loading Departments..."}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              }
                            ]}>
                            <Select showSearch placeholder="Select Department" onChange={e => selectTeamDepartments(e as string)}>
                              {teamDepartments?.filter(teamDepartment => teamDepartment.team === getFieldValue('team')).map(teamDepartment => 
                                <Option key={teamDepartment._id} value={teamDepartment._id}>{teamDepartment.name}</Option>
                              )}
                            </Select>
                          </Form.Item>
                        )}
                      </Form.Item>
                        
                        <Form.Item noStyle
                          shouldUpdate={(prevValues, currentValues) => prevValues.department !== currentValues.department}
                        >
                          {({ getFieldValue }) => (
                            <Form.Item 
                              style={{marginBottom:0}}
                              labelCol={{xs: { span: 24 }, sm: { span: 10, offset: 0 }}}
                              wrapperCol={{xs: { span: 24 }, sm: { span: 10 }}}
                              label='Role'
                              name='teamRoleId'
                              validateStatus={(getFieldValue('department') && !teamRoles)? 'validating' : ''}
                              hasFeedback
                              help= {(getFieldValue('department') && !teamRoles)? 'Loading Roles...' : ''}
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                }
                              ]}>
                              <Select showSearch placeholder="Select Role">
                                {teamRoles?.filter(teamRole => teamRole.departmentId === getFieldValue('department')).map(teamRole => 
                                  <Option key={teamRole._id} value={teamRole._id}>{teamRole.name}</Option>
                                )}
                              </Select>
                            </Form.Item>
                          )}
                        </Form.Item>
        
                        <Form.Item noStyle
                          shouldUpdate={(prevValues, currentValues) => prevValues.adminRole !== currentValues.adminRole}
                        >
                          {({ getFieldValue }) => (
                            getFieldValue('adminRole') === 'issuer company officer'?
                              <Form.Item
                                labelCol={{xs: { span: 24 }, sm: { span: 10, offset: 0 }}}
                                wrapperCol={{xs: { span: 24 }, sm: { span: 14 }}}
                                label={'Corporate Role if Affiliate'}
                                name="corporateRoles"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  }
                                ]}>
                                  <Checkbox.Group>
                                    <Row>
                                      <Col span={24}>
                                        <Checkbox value="director" style={{ lineHeight: '32px' }}>
                                          Director
                                        </Checkbox>
                                      </Col>
                                      <Col span={24}>
                                        <Checkbox value="large shareholder" style={{ lineHeight: '32px' }}>
                                          Large Shareholder
                                        </Checkbox>
                                      </Col>
                                      <Col span={24}>
                                        <Checkbox value="executive officer" style={{ lineHeight: '32px' }}>
                                          Executive Officer
                                        </Checkbox>
                                      </Col>
                                      <Col span={24}>
                                        <Checkbox value="inital member" style={{ lineHeight: '32px' }}>
                                          Initial Member
                                        </Checkbox>
                                      </Col>
                                    </Row>
                                  </Checkbox.Group>
                              </Form.Item> : null
                          )}
        
                        </Form.Item>


                    </>                      
                    : null
                )}

              </Form.Item>


                <div style={{textAlign:'right'}}>
                  <Button style={{marginRight:'5px'}} htmlType='button' size='large' onClick={()=> history.push(`/master-company-super-admin/admin/admin-roles`)}>
                    BACK
                  </Button>
                  <Button htmlType='submit' size='large' type='primary' loading={submitting}>
                    CREATE ADMIN AND SEND INVITE
                  </Button>
                </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );

}