import { environment } from "../../../environments/environment";
import axios from 'axios';
import { SharedService } from "../Shared.service";
import { APIResponse } from "../interfaces";

const sharedService = new SharedService();

export class BankService {
    private APIURL = environment.APIURL;

     routes = {
        issuercomplianceadmin: 'issuerSuperAdmin/approveOrRejectBankInfoByLLPCAByCompanyId',
        mastercompanycomplianceadmin: 'issuerSuperAdmin/approveOrRejectBankInfoByMCAByCompanyId',
        platformissueradmin:'issuerSuperAdmin/approveOrRejectBankInfoByPSAByCompanyId'
    }

    async approveOrRejectBankInfo(companyId,payload,role) {
        return axios.post<any, APIResponse>(
          `${this.APIURL}/${this.routes[role]}?companyId=${companyId}`,
          payload,
          { headers: await sharedService.getAuthHeader() }
        );
      }

      async getBankInfoByCompanyId(companyId) {
        return axios.get<any, APIResponse>(
          `${this.APIURL}/issuerSuperAdmin/getBankInfo?companyId=${companyId}`,
          { headers: await sharedService.getAuthHeader() }
        );
      }

      async getBankInfoForMCAOrPSA() {
        return axios.get<any, APIResponse>(
          `${this.APIURL}/issuerSuperAdmin/getBankInfoForMCAOrPSA`,
          { headers: await sharedService.getAuthHeader() }
        );
      }
}