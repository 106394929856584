import React, { Component } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { environment } from "../../environments/environment";
import "./index.scss";

function Footer() {
  const history = useHistory();
  const gotToHome = () => {
    history.push("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const gotToAboutUs = () => {
    history.push("/about-us");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const gotToFeatures = () => {
    history.push("/features");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToMarketPlace = () => {
    history.push("/marketplace");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToListProperty = () => {
    history.push("/list-your-property");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToResources = () => {
    history.push("/how-it-works");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const gotToFaq = () => {
    history.push("/faq");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const gotToTermsAndConditions = () => {
    history.push("/terms-and-conditions");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const gotToPrivacyPolicy = () => {
    history.push("/privacy-policy");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const gotToCompanyMangersInterested = () => {
    history.push("/interest-company-managers");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const gotToResources = () => {
    history.push("/interest-company-managers");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const gotToHowItWorks = () => {
    history.push("/how-it-works");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const gotToKnowledge = () => {
    history.push("/knowledge-center");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const gotToCareer = () => {
    history.push("/career");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const gotToContacts = () => {
    history.push("/interest-company-managers");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const appName = environment.headerTitle
  return (
    <div className="Footer">
      <div className="Footer__details">
        {/* <div className="Footer__details__detail">
          <span className="Footer__details__detail__title">COMPANY</span>
          <hr style={{ color: "white" }}></hr>
          <span className="Footer__details__detail__items" onClick={environment.companyName !== 'vastu' && environment.companyName !== 'reet' ? gotToHome : ''}>
            Home
          </span>
          <span
            className="Footer__details__detail__items"
            onClick={environment.companyName !== 'vastu' && environment.companyName !== 'reet' ? gotToAboutUs : ''}
          >
            About
          </span>
          <span className="Footer__details__detail__items"
           onClick={environment.companyName !== 'vastu' && environment.companyName !== 'reet' ? goToMarketPlace: ''}
           >Marketplace</span>
          <span className="Footer__details__detail__items"
          onClick={environment.companyName !== 'vastu' && environment.companyName !== 'reet' ? goToListProperty: ''}
          >List Property</span>
          <span className="Footer__details__detail__items"
          onClick={environment.companyName !== 'vastu' && environment.companyName !== 'reet' ? goToResources: ''}
          >Resources</span>
          <span
            className="Footer__details__detail__items"
            onClick={environment.companyName !== 'vastu' && environment.companyName !== 'reet' ? gotToFeatures: ''}
          >
            Features
          </span>
        </div>
        <div className="Footer__details__detail">
          <span className="Footer__details__detail__title"
           onClick={environment.companyName !== 'vastu' && environment.companyName !== 'reet' ? gotToResources: ''}
          >RESOURCES</span>
          <hr style={{ color: "white" }}></hr>
          <span className="Footer__details__detail__items"
           onClick={environment.companyName !== 'vastu' && environment.companyName !== 'reet' ? gotToHowItWorks: ''}
          >How it works</span>
          <span className="Footer__details__detail__items" onClick={environment.companyName !== 'vastu' && environment.companyName !== 'reet' ? gotToFaq : ''}>
            FAQ
          </span>
          <span className="Footer__details__detail__items"
          onClick={environment.companyName !== 'vastu' && environment.companyName !== 'reet' ? gotToKnowledge: ''}
          >Knowledge Center
          </span>
          <span className="Footer__details__detail__items"
           onClick={environment.companyName !== 'vastu' && environment.companyName !== 'reet' ? gotToCareer: ''}
          >Career</span>
        </div>
        <div className="Footer__details__detail">
          <span className="Footer__details__detail__title">CONTACT</span>
          <hr style={{ color: "white" }}></hr>
          <span
            className="Footer__details__detail__items"
            style={{ fontWeight: "600" }}
          >
            Call us at any time
          </span>
          {environment.companyName !== 'vastu' && environment.companyName !== 'reet' && (
          <span className="Footer__details__detail__items">+91 9823665562</span>)}
          <br></br>
          <span
            className="Footer__details__detail__items"
            style={{ fontWeight: "600" }}
          >
            Stay connected
          </span>
          <span className="Footer__details__detail__items">with an email</span>
          {environment.companyName === 'vruddhix' && (
          <span className="Footer__details__detail__items">
            sales@vruddhix.com
          </span>
          )}
        </div>
        <div className="Footer__details__detail">
          <span className="Footer__details__detail__title">LEGAL</span>
          <hr style={{ color: "white" }}></hr>
          <span className="Footer__details__detail__items" onClick={gotToPrivacyPolicy}>Privacy Policy</span>
          <span className="Footer__details__detail__items" onClick={gotToTermsAndConditions}>
            Terms and Conditions
          </span>
          <span className="Footer__details__detail__items">Contact Us</span>
        </div> */}
      </div>
      <div className="Footer__get-started">
        {/* <div className="Footer__get-started__text" >
          Interested to Register as Property Company Manager?
        </div>
        <span className="Footer__get-started__btn" onClick={gotToCompanyMangersInterested}>Get Started</span> */}
      </div>
      <div className="Footer__description">
        {/* <span>{environment.headerTitle}</span> does not make any representation regarding the suitability of
        the opportunities that appear on the Platform. Nothing on the Platform
        should be construed as investment, business, legal or tax advice or
        constitutes an offer for service or assistance with investment in real
        estate assets. Listing of details relating to the opportunities on the
        Platform or any content contained on the Platform does not constitute an
        offer by <span>{environment.headerTitle}</span> to sell, solicit or make an offer to participate in
        the opportunities. No information or content available on the Platform
        should be construed as a solicitation of an offer to buy or sell any
        property. Any decision to make direct or indirect investments in real
        estate assets involves significant risks including risk of loss of
        capital, and all such decisions should be made on. */}
        Investing in digital securities involves significant risk. Digital securities are speculative investments and may not be suitable for all investors. There is no guarantee that investors will receive a return on their investment, and they may lose all or part of their capital. Investments offered under Regulation D are exempt from registration with the SEC and are available only to accredited investors as defined by the Securities Act of 1933. These investments are illiquid and may not be resold except as permitted under applicable securities laws. Prospective investors should carefully consider their financial situation and consult with a financial advisor before investing. Past performance is not indicative of future results, and there is no assurance that any investment strategy will achieve its objectives.
      </div>
      <div className="Footer__logo">
        <span className="Footer__logo__description">
          © {appName} - copyright © 2024 All rights reserved.
        </span>
        <span className="Footer__logo__logo">
          <hr style={{ width: "100%" }}></hr>
          <span className="Footer__logo__logo__vruddhix">
            <span>{environment.headerTitle}</span>
            {environment.companyName === 'vruddhix' && (
            <span style={{ color: "green" }}>USA</span>)}
          </span>
          <hr style={{ width: "100%" }}></hr>
        </span>
      </div>
    </div>
  );
}

export default Footer;
