import React, { useState, useEffect } from 'react';
import CardHOC from "../../CardHOC";
import {Form, Input, InputNumber, Button, Spin, notification} from 'antd';
import {AuthService} from "../../Shared/Auth.service";
import {SeriesService} from "../Series.service";
import { useHistory } from 'react-router-dom';


const seriesService = new SeriesService();
const useUserContext = () => new AuthService().useUserContext();


export default function SeriesNew() {
  const {userInfo} = useUserContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>();
  const history = useHistory();

  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      if(!userInfo) return;

      const response = await seriesService.getLastSeriesNumber({
        companyId: userInfo.company?.id as string
      });

      form.setFieldsValue({
        number: +response.data.number + 1
      });

      setLoading(false);

    })();
  }, [userInfo]);


  const createSeries = async(formValue) => {
    setSubmitting(true);

    const response = await seriesService.createSeries(formValue);

    if(response.success) {
      notification.success({
        message: 'Success',
        description: 'Series created successfully',
      });
      history.push(`/series`);
    }

    setSubmitting(false);
  }

  return (
    <CardHOC
      heading={'Create Series'}
      component={<>

        {loading &&
          <div style={{textAlign:'center'}}>
            <br/><br/>
            <Spin size='large'/>
          </div>
        }

        {!loading &&
          <>
            <Form
              form={form}
              autoComplete={'off'}
              labelAlign={'left'}

              onFinish={createSeries}>

              <Form.Item
                name={'number'}
                label={'Series Number'}
                wrapperCol={{span: 10, pull: 2}}
                labelCol={{span: 3}}
              >
                <InputNumber disabled/>
              </Form.Item>

              <Form.Item
                name={'name'}
                label={'Series Name'}
                labelCol={{span: 3}}

                wrapperCol={{span: 10, push: 2}}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                name={'comments'}
                label={'Comments'}
                labelCol={{span: 3}}
                wrapperCol={{span: 10, push: 2}}
              >
                <Input.TextArea/>
              </Form.Item>

              <div style={{ textAlign: 'left' }}>
                {/*<Button*/}
                {/*  style={{marginRight: '10px'}}*/}
                {/*  type="primary"*/}
                {/*  onClick={() => history.push(`/series/invite-series-manager`)}*/}
                {/*>*/}
                {/*  INVITE SERIES MANAGER*/}
                {/*</Button>*/}
                <Button
                  style={{marginRight: '10px'}}
                  type="primary"
                  htmlType={'submit'}
                  loading={submitting}
                >
                  CREATE
                </Button>
                <Button
                  style={{marginRight: '10px'}}
                  onClick={() => history.push(`/series`)}
                >
                  CANCEL
                </Button>
              </div>

            </Form>

          </>

        }


      </>}
    />
  );
}