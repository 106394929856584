import React from 'react';

// import { lazy } from '@loadable/component';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SeriesList from "./SeriesList";
import SeriesNew from "./SeriesNew";
// import InviteSeriesManager from "./InviteSeriesManager";

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <SeriesList/>
      </Route>

      <Route path={`${path}/new`}>
        <SeriesNew/>
      </Route>

      {/*<Route path={`${path}/invite-series-manager/:seriesId`}>*/}
      {/*  <InviteSeriesManager/>*/}
      {/*</Route>*/}
    </Switch>
  );
}