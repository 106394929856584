import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Form, Input, Progress, message, Button, Descriptions, Spin, Result } from 'antd';
import { SharedService } from '../../Shared/Shared.service';
import { APIResponse, SymbolDetailsAndSTData, FiledNotice, WhitelistedWallet } from '../../Shared/interfaces';
import { TeamMemberService } from '../TeamMember.service';
import { AuthService } from '../../Shared/Auth.service';
import { MetamaskService } from '../../Shared/Metamask.service';
import { SecurityTokenRegistryService } from '../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import { useHistory } from 'react-router-dom';
import { TokenConfigurationService } from '../../TokenConfigurations/TokenConfiguration.service';
// import BigNumber from 'bignumber.js';
// import moment from 'moment';


// import { SecurityTokenService } from '../../Shared/SecurityToken/SecurityToken.service';
import WhitelistFacet from '../../Shared/SecurityToken/Facets/WhitelistFacet';

const {Title} = Typography;const sharedService = new SharedService();
const teamMemberService = new TeamMemberService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const tokenConfigurationService = new TokenConfigurationService();

// const securityTokenService = new SecurityTokenService();
const whitelistFacet  = new WhitelistFacet();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

export default function FileNotice() {
  const {userInfo} = useUserContext();
  const history=useHistory();
  const {selectedWallet, networkId} = useSelectedWalletContext();

  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [filedNotice, setFiledNotice] = useState<FiledNotice>();
  const [whitelistedWallet, setWhitelistedWallet] = useState<WhitelistedWallet>();


  const [loading, setLoading] = useState<boolean>(true);

  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [KYCPending,setKYCPending]=useState(true)
  const [filesObj, setFilesObj] = useState(
    {
      noticeFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
    }
  );


  useEffect(()=>{
    (async ()=>{
      console.log(userInfo?._id);
      if(userInfo?.role!=="employee" && userInfo?.role!=="contractor_consultant_advisor")
      {
        return;
      }
      const response=await teamMemberService.getKYCDetails({teamMemberId:userInfo?._id});
      if(response.success && response.data)
      {
        if(response.data.length>0)
        {
          setKYCPending(false);
        }
        else
        {
          setKYCPending(true);
          history.push('/team-member/KYCUpload');
        }
      }
    })();
    },[])

  useEffect(() => {
    (async () => {
      if(!userInfo || !selectedWallet) return;

      let _tokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;

      if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);

      // const [_whitelistedWallet, _filedNoticeResponse] = await Promise.all([
      //   securityTokenService.getWhitelistedWallet(_symbolDetailsAndSTData.securityTokenData.contractAddress, selectedWallet),
      //   teamMemberService.getFiledNotice()
      // ]);

      const [_whitelistedWallet, _filedNoticeResponse] = await Promise.all([
        whitelistFacet.getWhitelistedWallet(_symbolDetailsAndSTData.securityTokenData.contractAddress, selectedWallet),
        teamMemberService.getFiledNotice()
      ]);

      const _filedNotice: FiledNotice = _filedNoticeResponse.data;

      if(_filedNotice) {
        form.setFieldsValue({
          noticeFile: _filedNotice.noticeFile?.name,
          idOfOfficersAndDirectorsFile: _filedNotice.noticeFile?.name,
          financialStatementsFile: _filedNotice.noticeFile?.name,
        });
      }

      setWhitelistedWallet(_whitelistedWallet);
      setFiledNotice(_filedNoticeResponse.data);
      setLoading(false);


    })();
  }, [userInfo, selectedWallet, form]);




  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if(!e.target.files) return;

    const file =  e.target.files[0];
    console.log(file);

    if(!file) return;

    const fileType = sharedService.getFileType(file.name);

    setFilesObj(prev => {
      const current = sharedService.clone(prev);
      current[key].URL = null;
      return current;
    });

    teamMemberService.uploadFile(file, (uploadFilePercent: number) => {
      setFilesObj(prev => {
        const current = sharedService.clone(prev);
        current[key].uploading = true;
        current[key].uploadFilePercentage = uploadFilePercent;
        return current;
      });

    }, (err) => {
      console.error(err);
      setFilesObj(prev => {
        const current = sharedService.clone(prev);
        current[key].uploading = false;
        return current;
      });
      
    }, async(response: APIResponse) => {

      if (response.success) {
        const URL = response.data.url;
        form.setFieldsValue({[key]: file.name});

        setFilesObj(prev => {
          const current = sharedService.clone(prev);
          current[key].URL = URL;
          current[key].uploading = false;
          current[key].name = file.name;
          current[key].type = fileType.toLowerCase()
          return current;
        });
        
      } else {
        console.error(response.error);
        setFilesObj(prev => {
          const current = sharedService.clone(prev);
          current[key].uploading = false;
          return current;
        });
      }

    });
  }

  const filedNoticeExists = filedNotice && whitelistedWallet && filedNotice.creationTS > +(whitelistedWallet.dateFiledNoticeRemoved)*1000;


  const fileNotice = async(formValue) => {
    const req = sharedService.clone(formValue);
  
    req.noticeFile = {
      name: filesObj.noticeFile?.name,
      URL: filesObj.noticeFile?.URL,
      type: filesObj.noticeFile.type,
    };

    setSubmitting(true);
    const response = await teamMemberService.fileNotice(req);

    if (response.success) {
      message.success('Request sent successfully');

      const _filedNotice = {
        _id: response.data,
        referenceNumber: formValue.referenceNumber,
        noticeFile: req.noticeFile,
        isRejected: false,
        affiliate: (userInfo?._id as string),
        wallet: selectedWallet,
        searchableWallet: selectedWallet?.toLocaleLowerCase(),
        creationTS: Date.now()
      };

      setFiledNotice(_filedNotice as any);

    } else {
      console.error(response.error);
      message.error(response.error);
    }

    setSubmitting(false);
  }



  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {loading && 
            <div style={{textAlign:'center'}}>
              <br/><br/>
              <Spin size='large'/>
            </div>
          }

          {!loading && 
            <Card>
              <Title level={3} style={{textAlign:'center'}}>File a Notice with SEC on Form 144 to allow the sale greater than the allowed sale</Title>
              
              {!symbolDetailsAndSTData?.symbolDetails.isDeployed &&
                <Result
                  title={`Security Token not deployed`}
                />
              }

              {symbolDetailsAndSTData?.symbolDetails.isDeployed && 
                <>
                  {selectedWallet?.toLowerCase() !== userInfo?.wallet?.toLowerCase() && 
                    <>
                      <Title level={2} style={{textAlign:'center'}}>Wrong selected wallet on metamask</Title>
                      <Result
                        status="error"
                        title = {
                          <p>
                            Select the wallet {' '}
                            <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${userInfo?.wallet}`}>
                              {sharedService.minifyAddress(userInfo?.wallet as string)}
                            </a> 
                            {' '} in order to File a Notice
                          </p>
                        }
                      />
                    </>
                  }

                  {selectedWallet?.toLowerCase() === userInfo?.wallet?.toLowerCase() && 
                    <>
                      {!whitelistedWallet?.isWhiteListed &&
                        <Result
                          title={`Your Wallet is not whitelisted`}
                        />
                      }
                      {whitelistedWallet?.isWhiteListed && 
                        <>
                          {!whitelistedWallet.isAffiliate && 
                            <Title level={2} style={{textAlign:'center'}}>Selected Wallet is not Whitelisted as an Affiliate</Title>
                          }
                          

                          {whitelistedWallet.isAffiliate && 
                            <Card bordered={false} className='card-shadow'>
                              <br/>

                              {whitelistedWallet.isFiledNoticeApproved && 
                                <>
                                  <Descriptions bordered column={2}>
                                    <Descriptions.Item span={2} label="Filed Notice">
                                      <a 
                                        href={filedNotice?.noticeFile?.URL} 
                                        download={filedNotice?.noticeFile?.name} 
                                        target="_blank" rel="noopener noreferrer"> {filedNotice?.noticeFile?.name}
                                      </a>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Reference N°">{filedNotice?.referenceNumber}</Descriptions.Item>
                                  </Descriptions>
                                </>
                              }

                              {!whitelistedWallet.isFiledNoticeApproved && 
                                <>

                                  {/* {Date.now() < +(whitelistedWallet?.dateFiledCanBeNoticedAgain as string)*1000 && 
                                    <Result
                                      title={
                                        <>
                                          You have to wait until {' '}
                                          {moment(new BigNumber(whitelistedWallet?.dateFiledCanBeNoticedAgain as string).times(1000).toNumber()).format('LLL')} {' '}
                                          to File a Notice with SEC on Form 144 to allow the sale greater than the allowed sale
                                        </>
                                      }
                                    />
                                  } */}

                                  {
                                  // Date.now() >= +(whitelistedWallet?.dateFiledCanBeNoticedAgain as string)*1000 && 
                                    <>
                                    {filedNoticeExists && 
                                      <>
                                      {!filedNotice?.isRejected && 
                                        <Result title='Your Request is being Reviewed by the Property Company Counsel'/>
                                      }

                                      {filedNotice?.isRejected && 
                                        <Title style={{color:'red', textAlign:'center'}} level={4}>Request has been rejected. 
                                          {filedNotice.comments && `Reason: ${filedNotice.comments}`}
                                        </Title>
                                      }
                                      </>
                                        
                                    }

                                    {(!filedNoticeExists || filedNotice?.isRejected) && 

                                      <Form labelAlign="left" initialValues={filedNotice?.isRejected? filedNotice : {}} form={form} onFinish={fileNotice}>

                                        <Form.Item
                                          label="Upload the filed notice"
                                          name='noticeFile'
                                          labelCol={{span: 10, push:4}}
                                          wrapperCol={{span: 10}}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'This field is required'
                                            }
                                          ]}>
                                          <>
                                            {filedNotice?.noticeFile?.name && 
                                            <>
                                              <span style={{color:'green', fontWeight:'bold'}}>Saved: </span>
                                              <a 
                                                href={filedNotice?.noticeFile?.URL} 
                                                download={filedNotice?.noticeFile?.name} 
                                                target="_blank" rel="noopener noreferrer"> {filedNotice?.noticeFile?.name}
                                              </a>
                                            </>
                                            }
                                            <Input type='file' onChange={e => { uploadFile(e, 'noticeFile') }} />
                                            {filesObj.noticeFile.uploadFilePercentage>0 && 
                                              <Progress percent={filesObj.noticeFile.uploadFilePercentage} />
                                            }
                                          </>
                                        </Form.Item>

                                        <Form.Item
                                          label="Reference Number"
                                          name='referenceNumber'
                                          labelCol={{span: 10, push:4}}
                                          wrapperCol={{span: 10}}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'This field is required',
                                              whitespace: true
                                            }
                                          ]}>
                                            <Input/>
                                        </Form.Item>

                                        <div style={{textAlign:'right'}}>
                                          <Button htmlType='submit' size='large' type='primary' loading={submitting}>
                                            FILE NOTICE
                                          </Button>
                                        </div>

                                      </Form>

                                    }
                                    
                                    </>
                                  }
                                  </>

                              }

                            </Card>

                          }
                          
                        </>
                      }
                    </>
                  }
                </>
              }

              
            </Card>
          }
        </Col>
      </Row>
    </>
  );
}