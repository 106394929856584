import React, { useState, useEffect } from "react";
import { CheckCircleTwoTone, ClockCircleTwoTone } from "@ant-design/icons";
import { Checkbox, Card, Button, Radio, Input, message } from "antd";
import "./styles.scss";
import dot from "../../../assets/images/logo/dot.svg";
import { AuthService } from "../../Shared/Auth.service";
import { IssuerCounselService } from "./../IssuerCounsel.service";

const Dot = () => <img className="dot" src={dot} />;

const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const issuerCounselService = new IssuerCounselService();

const SafeHarbor = () => {
  const { userInfo, setUserInfo } = useUserContext();
  const [safeHarbor, setSafeHarbor] = useState(false);
  const [status, setStatus] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await issuerCounselService.getCouncelInfo(
        userInfo.company.id
      );
      if (response) {
        console.log(response?.data);
        setStatus(response?.data?.safeHarbor);
        if (response?.data?.safeHarbor === true) {
          setSafeHarbor(true);
          setDisabled(true);
        }
      }
    })();
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    if (safeHarbor) {
      const response = await issuerCounselService.setSafeHarbor({
        company_id: userInfo.company.id,
        safeHarbor: status,
      });
      if (response) {
        if (status) {
          setSubmit(true);
          setDisabled(true);
        }
      } else message.error("Error occured");
    }
    setLoading(false);
  };

  return (
    <div className="ica-dashboard">
      <Card
        className="issue-card"
        title={<span className="dashboard-title">Safe Harbor</span>}
      >
        <div className="topper">
          <Checkbox
            checked={safeHarbor}
            onChange={() => setSafeHarbor(!safeHarbor)}
          />
          <span className="check-point">
            Property Company Counsel explained safe harbor to Issuer Super Admin, Issuer
            Token Admin and Issuer company Officer
          </span>
        </div>
        <div className="uppertext">
          Rule 903 provides a safe harbor from registration for any offer or
          sale of securities by the issuer, a distributor, any of their
          respective affiliates, or any person acting on behalf of the foregoing
          persons, If :
        </div>
        <ul>
          <li className="uppertext">
            The offer or sale is made in an offshore transaction.
          </li>
          <li>
            <Dot />
            No directed selling efforts may be made by the issuer, a
            distributor, any of their respective affiliates, or any person
            acting on any of their behalf.
          </li>
          <li>
            <Dot />
            Additional conditions are met, depending on the category of the
            offering.
          </li>
        </ul>
      </Card>
      <br />
      <div className="buttons-footer">
        <Radio.Group
          value={status}
          onChange={() => setStatus(!status)}
          size="large"
        >
          <Radio.Button value={false} disabled={disabled}>
            <ClockCircleTwoTone /> In-Progress
          </Radio.Button>
          <Radio.Button value={true}>
            <CheckCircleTwoTone twoToneColor="#52c41a" /> Completed
          </Radio.Button>
        </Radio.Group>
        {!submit && (
          <Button
            type="primary"
            loading={loading}
            onClick={onSubmit}
            size="large"
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};

export default SafeHarbor;
