import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Table,
  Button,
  Tabs,
  notification,
  Result,
  Spin
} from 'antd';
import { SharedService } from '../../Shared/Shared.service';
import moment from 'moment';
import axios from 'axios';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../Shared/Auth.service';
import { SymbolDetailsAndSTData, TokenConfigurationProcess } from '../../Shared/interfaces';
import { TokenConfigurationService } from '../../TokenConfigurations/TokenConfiguration.service';
import { MetamaskService } from '../../Shared/Metamask.service';
import { IssuerTokenAdminService } from '../../IssuerTokenAdmin/IssuerTokenAdmin.service';
import { SecurityTokenService } from '../../Shared/SecurityToken/SecurityToken.service';
import { SecurityTokenRegistryService } from '../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import MainFacet from '../../Shared/SecurityToken/Facets/MainFacet/index';
import WrongMetamaskWalletWarning from '../../Shared/WrongMetamaskWalletWarning';
import WhitelistFacet from '../../Shared/SecurityToken/Facets/WhitelistFacet';

const { Title } = Typography;
const { TabPane } = Tabs;
const sharedService = new SharedService();
const issuerTokenAdminService = new IssuerTokenAdminService();
const UserContext = AuthService.UserContext;
const useUserInfo = () => new AuthService().useUserInfo();
const tokenConfigurationService = new TokenConfigurationService();
const metamaskService = new MetamaskService();
const securityTokenRegisteryService = new SecurityTokenRegistryService()
const whitelistFacet = new WhitelistFacet();

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

export default function IssueTokens() {
  const APIURL = environment.APIURL;
  const [globalCompanyId, setGlobalCompanyId] = useState("");
  const [globalInvestorId, setGlobalInvestorId] = useState("");
  const investerIdListArray: any[] = [];
  const [loading, setLoading] = useState(false);
  let filteredInvesterIdListArray: any[] = [];
  const [USDTokenPurchases, setUSDTokenPurchases] = useState<any[]>();
  const [userInfo, setUserInfo] = useUserInfo();
  const [isTokenSaleClosed, setIsTokenSaleClosed] = useState(false);
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [paymentData, setPaymentData] = useState<any>([]);
  const [llpAddress, setllpAddress] = useState("");
  const [tokenAdminWallet, settokenAdminWallet] = useState("");
  const [isAnyPaymentRecordPending, setIsAnyPaymentRecordPending] = useState(false);
  const [tokenPrice, settokenPrice] = useState(0);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);

  const [tokenConfigurationProcesses, setTokenConfigurationProcess] = useState<TokenConfigurationProcess | any>();
  const { selectedWallet, networkId } = useSelectedWalletContext();
  const [issuerTokenAdminWallet, setIssuerTokenAdminWallet] = useState('');
  const [whiteListedWalletAddress,setWhiteWalletAddress] = useState<any>([])


  useEffect(() => {

    (async () => {
      if(userInfo?._id) {
        try {
          console.log(userInfo?.company?.id)
          setLoading(true);

          const tokenDetail = await issuerTokenAdminService.getTokenDetailsByCompanyId(userInfo?.company?.id);
          console.log("token_details ; ",tokenDetail);

          // const llpaddress = await tokenConfigurationService.getLLPAddress(tokenDetail?.data?.companyDetails?.companyId);
          // console.log("LLP Address = ",llpaddress?.data?.deployedSmartContractAddress);
          // setllpAddress(llpaddress?.data?.deployedSmartContractAddress);

          const admins = await tokenConfigurationService.getAllAdminsWallets(tokenDetail?.data?.companyDetails?.companyId);
          console.log("admins : ", admins);
          const tokensadmin = admins?.data?.llpTokenAdminWalletAddress?.split(',');
          if (tokensadmin && tokensadmin.length > 0) {
            settokenAdminWallet(tokensadmin[0]);
          }
          if(tokenDetail.success){
            if(tokenDetail.data)
            tokenDetail['data'].hasOwnProperty('isTokenSaleClosed') && tokenDetail['data'].isTokenSaleClosed ?
            setIsTokenSaleClosed(true) : setIsTokenSaleClosed(false);
            console.log('token detail: ',tokenDetail)
            settokenPrice(tokenDetail.data?.pricePerToken);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          throw error;
        }
      }
    })();

    (async () => {
      try {
        setLoading(true);

        await tokenConfigurationProcess();

        const paymentDetail = await issuerTokenAdminService.getAllPaymentDetailsWithAllInvestorDetails(userInfo?.company?.id);
        console.log("payment_details",paymentDetail);
        if(paymentDetail.success){
          const paymentData = paymentDetail['data']?.map(paymentDetail =>{
            if(paymentDetail.status?.toLowerCase() == 'pending' || paymentDetail.tokenIssued !== true){
              setIsAnyPaymentRecordPending(true);
            }

            return  {
              id: paymentDetail?._id,
              name: paymentDetail?.investorDetails ? paymentDetail?.investorDetails?.firstName +' '+ paymentDetail?.investorDetails?.lastName : '',
              email:paymentDetail?.investorDetails ? paymentDetail?.investorDetails?.email : '',
              walletAddress: paymentDetail?.investorDetails ? paymentDetail?.investorDetails?.walletAddress : '',
              status: paymentDetail?.status,
              creationTS:paymentDetail ? new Date(paymentDetail?.creationTS).toLocaleString() : '',
              tokenCount: paymentDetail?.tokenCount,
              investmentId: paymentDetail?.investmentId,
              investorId: paymentDetail?.investorDetails ? paymentDetail?.investorDetails._id : null,
              tokenIssued: paymentDetail.tokenIssued ? paymentDetail.tokenIssued : false,
              amount: paymentDetail.amountToInvest ? paymentDetail.amountToInvest : 0,
            }
          });
          setPaymentData(paymentData);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        throw error;
      }

    })();

  },[userInfo])

  const tokenConfigurationProcess = async()=>{
    const _tokenConfigurationProcess: TokenConfigurationProcess = (
      await tokenConfigurationService.getLastTokenConfigurationProcess()
    ).data;
    setTokenConfigurationProcess(_tokenConfigurationProcess);
    console.log(_tokenConfigurationProcess);
    if(_tokenConfigurationProcess?.tokenSymbol){
      const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
      console.log('_symbolDetailsAndSTData ',_symbolDetailsAndSTData)
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData)

      const wallet = await (new MainFacet).issuerTokenAdmin(_symbolDetailsAndSTData.securityTokenData.contractAddress)
      console.log('wallet :', wallet)
      setIssuerTokenAdminWallet(wallet);

      const whiteListedwalletlist = await whitelistFacet.getWhitelist(_symbolDetailsAndSTData.securityTokenData.contractAddress)
      setWhiteWalletAddress(whiteListedwalletlist)
      console.log('ww w ',whiteListedwalletlist)
      console.log('white listed : ',whiteListedWalletAddress)
      return
    }
  }

  const ETHColums = [
    {
      title: 'Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Wallet Address',
      dataIndex: 'wallet',
      render: (value: string) => (
        <div style={{ marginTop: '20px' }}>
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${
                sharedService.etherscanURL[networkId as string]
              }/address/${value}`}
            >
              {sharedService.minifyAddress(value)}
            </a>
          </div>
        </div>
      ),
    },
    {
      title: 'Original Invested Amount (MATIC)',
      dataIndex: 'ETHAmount',
    },
    {
      title: 'Equivalent Invested Amount (USD)',
      dataIndex: 'USDamount',
    },
    {
      title: `Tokens to be created (${symbolDetailsAndSTData?.securityTokenData.symbol})`,
      dataIndex: 'amountWithoutBonus',
    },
    {
      title: 'Phase applied',
      dataIndex: 'phaseName',
      render: (value: string) => value || 'None',
    },
    {
      title: 'Bonus',
      dataIndex: 'bonusPercent',
      render: (value: number) => `${value}%`,
    },
    {
      title: `Tokens to be created including Bonus (${symbolDetailsAndSTData?.securityTokenData.symbol})`,
      dataIndex: 'amount',
    },
    {
      title: 'Date of Purchase',
      //dataIndex: 'creationTS',
      dataIndex: 'dateOfAmountReceived',
      render: (value: number) => moment(value).format('LLL'),
    },
  ];

  const investorColums = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Wallet Address',
      dataIndex: 'walletAddress',
      key: 'walletAddress',
    },
    {
      title: 'Created Date',
      dataIndex: 'creationTS',
      key: 'creationTS',
    },
    {
      title: 'Token Count',
      dataIndex: 'tokenCount',
      key: 'tokenCount',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        if (status.toLowerCase() === 'approved' && record.tokenIssued === true) {
          return 'Token Issued';
        } else if(status.toLowerCase() === 'approved' && !record.tokenIssued) {
          return 'Pending';
        }else  {
          return status;
        }
      },
    },
  ];

// const getUsers = async () => {
//   axios
//     .post(
//       `${APIURL}/users/getUser`,
//       {},
//       { headers: await sharedService.getAuthHeader() }
//     )
//     .then(async (res) => {
//       console.log("companyId: ", res);
//       getAllPaymentDetailsWithAllStatusByCompanyId(res.data.company.id);
//       setGlobalCompanyId(res.data.company.id);
//       setGlobalInvestorId(res.data._id);
//     });
// };

const getAllPaymentDetailsWithAllStatusByCompanyId = async (companyId) => {
  return axios
    .get(
      `${APIURL}/issuerSuperAdmin/getAllPaymentDetailsWithAllInvestorDetailsByCompanyId?companyId=${companyId}`,
      { headers: await sharedService.getAuthHeader() }
    )
    .then((response) => {
      console.log("companyId: ", response);
      setUSDTokenPurchases(response.data);
      response.data.forEach((element: any, index: number) => {
        investerIdListArray[index] = element.investorId;
      });
      setGlobalInvestorId(investerIdListArray[0]);
      filteredInvesterIdListArray = investerIdListArray.filter(
        (element, index) => {
          return investerIdListArray.indexOf(element) === index;
        }
      );
      console.log("investerIdListArray: ", investerIdListArray);
      console.log(
        "filteredInvesterIdListArray: ",
        filteredInvesterIdListArray
      );
    });
};

const issueTokensToInvestorForLLPCompanyByCompanyId = async () => {
  axios
    .post(
      `${APIURL}/issuerSuperAdmin/issueTokensToInvestorForLLPCompanyByCompanyId?companyId=${globalCompanyId}`,
      {
        investorId: globalInvestorId,
      },
      { headers: await sharedService.getAuthHeader() }
    )
    .then(async (response: any) => {
      console.log("response: ", response);
      if(response.success == true) {
        alert(response.data);
      }
      if(response.success == false) {
        alert(response.error.message);
      }
    });
};

const issueInvestorTokensAction = async() => {
    try {
      const isValid = await validateIssueTokenProcess();
      console.log(isValid)
      if(!isValid){
        return
      }

      setLoading(true)

      const onchainstatus = await issueTokenOnchain();
      if(!onchainstatus) {
        setLoading(false);
        notification.error({ message: "Error in onchain call" });
        return;
      }

      const res = await Promise.all(
        selectedRecords?.map(async (data: any) => {
          if(data.status?.toLowerCase() == 'approved' && data.tokenIssued !== true){
            return issuerTokenAdminService.issueTokensToInvestorForLLPCompany(
              userInfo?.company?.id,
              {
                investmentId: data.investmentId,
                investorId: data.investorId
              }
            );
          }
        })
      );
      if(res)
        setLoading(false);
        notification.success({ message: "Tokens issued Successfully!" })
        setTimeout(() => {
          window.location.reload();
        }, 500);
    } catch (error) {
        setLoading(false);
        notification.error({ message: "Something went wrong" + error })
    }
  }

  // validates for selected records and whitelisted walletaddresses of investors
  const validateIssueTokenProcess = async()=>{
    console.log(selectedRecords,whiteListedWalletAddress)
    if(selectedRecords.length <= 0){
      notification.info({ message: "Please select record first!" });
      return false;
    }
    
    if(whiteListedWalletAddress.length <= 0){
      notification.info({message: 'Wallets are not whitelisted!'})
      return false;
    }else{
      let whitelisted = true;
      selectedRecords.forEach((investor:any) => {
        const wallet = investor.walletAddress;
        whitelisted = whiteListedWalletAddress.find(entry => {
          //5 index represents walletaddress and 0 as boolean whitelisted
          return entry[5].toLowerCase() === wallet && entry[0]
        });
    
        if(!whitelisted){
          notification.info({message:`Investor ${investor.name} with wallet address ${wallet} is not whitelisted.`});
          return
        }
      });
      return whitelisted
    }
  }

const issueTokenOnchain = async () => {
    try{
      console.log('paymentData', paymentData);
      let finalPayments = selectedRecords.filter((payment: any) => payment?.status === 'approved' && payment?.tokenIssued !== true);

      console.log('finalPayments', finalPayments);
      const walletAddresses = finalPayments.map((item: any) => item?.walletAddress) as string[];
      // const tokenCounts = finalPayments.map((item: any) => (BigInt(item?.amount/tokenPrice) * BigInt(10 ** 18)).toString()) as string[];
      const decimals = +(symbolDetailsAndSTData?.securityTokenData
        .decimals as string);
      const tokenCounts = finalPayments.map((item:any) => {
        // const count = (item?.amount / tokenPrice);
        // return count.toLocaleString('fullwide', {useGrouping:false});
        return ((item.tokenCount * (10 ** decimals)).toLocaleString()).replace(/,/g, '');
    });

    console.log('walletAddresses', walletAddresses);
    console.log('tokenCounts', tokenCounts);

    console.log('values ',paymentData)
    const contractAddress = symbolDetailsAndSTData!.securityTokenData.contractAddress

      const onchainstatus = await (new MainFacet).addInvestorLockedAmountMulti(
        contractAddress,
        selectedWallet as string,
        tokenConfigurationProcesses?.lockPeriod as number,
        walletAddresses,
        tokenCounts
      );
      console.log('onchain',onchainstatus)

      if(onchainstatus.status){
        return true
      }else{
        return false
      }

    }catch(e){
      console.log(e);
      return false;
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRecords(selectedRows);
    },
    getCheckboxProps: (record) => {
      return {
        //if token is not approved yet or token is already issued then disable record
        disabled: record.status === 'pending' ||  record.status === 'rejected' || (record.hasOwnProperty('tokenIssued') && record.tokenIssued)
      };
    }
  };

  return (
    <>
  {loading ? (
    <Row justify="center" align="middle" style={{ minHeight: '50vh' }}>
      <Col>
        <Spin size='large' />
      </Col>
    </Row>
  ) : (
    <Row justify="center">
      <Col span={24}>
        {symbolDetailsAndSTData?.symbolDetails && (
          <>
            {userInfo?.role === 'issuer token admin' &&
            selectedWallet?.toLowerCase() !== symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() ? (
              <Card>
                <Title level={2} style={{ textAlign: 'center' }}>Wrong selected wallet on Metamask</Title>
                <Result
                  status="error"
                  title={
                    <p>
                      Select the wallet{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${sharedService.etherscanURL[networkId as string]}/address/${symbolDetailsAndSTData.symbolDetails.owner}`}
                      >
                        {sharedService.minifyAddress(symbolDetailsAndSTData.symbolDetails.owner.toLowerCase())}
                      </a>{' '}
                      in order to Issue Tokens

                    </p>
                  }
                />
              </Card>
            ) : (
              <Card>
                <Title level={1} style={{ textAlign: 'center' }}>
                  Issue your Security Tokens
                </Title>
                <Tabs defaultActiveKey="1" style={{ textAlign: 'center' }} type="card" size="large">
                  <TabPane tab="USD" key="1">
                    <Table
                      scroll={{ x: true }}
                      columns={investorColums}
                      dataSource={paymentData.filter(
                        (data) => data.status?.toLowerCase() !== 'pending' && data.status?.toLowerCase() !== 'rejected'
                      )}
                      pagination={false}
                      rowKey="id"
                      rowSelection={rowSelection}
                    />
                    <div style={{ textAlign: 'right' }}>
                      <br />
                      {isAnyPaymentRecordPending && (
                        <Button
                          size="large"
                          type="primary"
                          onClick={issueInvestorTokensAction}
                        >
                          ISSUE TOKENS FOR INVESTORS
                        </Button>
                      )}
                    </div>
                  </TabPane>
                </Tabs>
              </Card>
            )}
          </>
        )}
      </Col>
    </Row>
  )}
</>
  );
}
