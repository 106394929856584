import React, { useEffect } from 'react';
import Nav from './Nav';
import { AuthService } from './Shared/Auth.service';
import { SharedService } from './Shared/Shared.service';
import { MetamaskService } from './Shared/Metamask.service';
const metamaskService = new MetamaskService();
const sharedService = new SharedService();

const useUserInfo = () => new AuthService().useUserInfo();

const SelectedWalletContext = MetamaskService.SelectedWalletContext;
const useSelectedWallet = (userId) => new MetamaskService().useSelectedWallet(userId);

const NavHoc = ({ component }) => {
    const [userInfo, setUserInfo] = useUserInfo();
    const [selectedWallet, networkId, isMetamaskAuthenticated, setSelectedWallet, setNetworkId, setIsMetamaskAuthenticated] = useSelectedWallet(userInfo);
    const isNotConnectedToMetamask = !!userInfo && (!metamaskService.isMetamaskInstalled || isMetamaskAuthenticated === false || (networkId && !sharedService.allowedNetworks.includes(networkId)));

    return (
        <div>
            <Nav isNotConnectedToMetamask={isNotConnectedToMetamask && userInfo.role !=='investor'}>
                {component}
            </Nav>
        </div>
    )
}

export default NavHoc;
